import { cx } from '@emotion/css';
import type { FC } from 'react';
import { useContext } from 'react';

import { m } from '../../../motif';
import { dataSetToAttributes } from '../../../utils';
import { Icon } from '../../Icon';
import { PrimitivesContext } from '../../Primitives';
import { IconOrImage } from '../IconOrImage';
import {
  navigatorItemFeaturedLinkCss,
  navigatorItemFeaturedLinkCtaContainerCss,
  navigatorItemFeaturedLinkCtaIconCss,
  navigatorItemFeaturedLinkCtaTextCss,
  navigatorItemFeaturedLinkDescriptionCss,
  navigatorItemFeaturedLinkTitleCss,
} from './NavigatorItemFeaturedLink.styled';
import type { NavigatorItemFeaturedLinkProps } from './types';

export const NavigatorItemFeaturedLink: FC<NavigatorItemFeaturedLinkProps> = ({
  url,
  title,
  dataset,
  description,
  icon,
  media,
  ctaLabel,
  onClick,
}) => {
  const { Anchor } = useContext(PrimitivesContext);

  return (
    <Anchor
      className={cx(navigatorItemFeaturedLinkCss, 'sdsm-nav-featured-link')}
      href={url}
      role="menuitem"
      onClick={onClick}
      {...dataSetToAttributes(dataset)}
    >
      {(icon || media) && (
        <IconOrImage
          icon={icon}
          media={media}
          size={48}
          fill={m('--global-header-menu-featured-item-icon-color')}
        />
      )}
      <span className={navigatorItemFeaturedLinkTitleCss}>{title}</span>
      <span className={navigatorItemFeaturedLinkDescriptionCss}>{description}</span>
      <span className={navigatorItemFeaturedLinkCtaContainerCss}>
        <span className={navigatorItemFeaturedLinkCtaTextCss}>{ctaLabel}</span>
        <Icon
          className={navigatorItemFeaturedLinkCtaIconCss}
          size={24}
          name="arrow-right"
          fill={m('--global-header-menu-featured-item-fg-color')}
        />
      </span>
    </Anchor>
  );
};

NavigatorItemFeaturedLink.displayName = 'NavigatorItemFeaturedLink';
