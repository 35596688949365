import { useContentfulImages } from '@snapchat/mw-contentful-client';
import { Media as MediaSDS } from '@snapchat/snap-design-system-marketing';
import type { FC } from 'react';

import { combineImageSources } from '../../utils/combineImageSources';
import { parseMedia } from '../../utils/media';
import type { ImageProps, ImageQuality } from './types';
import { getImageSourceSettings } from './utils';

export const imageQuality: Record<ImageQuality, number> = {
  Standard: 40,
  High: 65,
  'Very High': 85,
};

// TODO: Rename to Image and the other to ImageShallow
export const Image: FC<ImageProps> = props => {
  const { enableHighDpi, quality, dataset } = props;
  const { imageSource, imageAltText } = parseMedia(props.media);
  const { imageSource: mobileImageSource } = parseMedia(props.mobileMedia);
  const { getImageSources } = useContentfulImages();

  const { desktopSettings, mobileSettings } = getImageSourceSettings({
    desktopHeight: props.media?.height ?? 0,
    mobileHeight: props.mobileMedia?.height ?? 0,
    enableHighDpi,
    quality,
  });

  const imgSrcs = combineImageSources({
    desktop: getImageSources(imageSource, desktopSettings),
    mobile: getImageSources(mobileImageSource ?? imageSource, mobileSettings),
  });

  return (
    <MediaSDS
      altText={imageAltText}
      imgSrcs={imgSrcs}
      wrap={props.wrap ?? undefined}
      dataset={dataset}
    />
  );
};

Image.displayName = 'Image';
