import { gql } from '@apollo/client';
import type { Experiment, PageAsset } from '@snapchat/mw-contentful-schema';

import type { ContentfulTypedSysProps } from '../../types/contentful';
import type { Items } from '../../types/Items';
import { contentfulSysIdFragment } from '../../utils/contentful/contentfulQuery';
import { type BlockDataProps, blockQueryLimit } from '../Block';
import { createExperimentFragmentNoAb } from '../Experiment/experimentQuery';
import { pageAssetFragment } from '../Page/pageAssetQuery';
import type { SubNavigationDataProps } from '../SubNavigation';
import { fragments as heroFragments } from './query';
import type { HeroDataProps } from './types';

// =================================================================================================
// Page Block Hero Fragment
// =================================================================================================

// nice to have: filter for hero only
const pageBlockCollectionsFragment = gql`
  fragment PageBlocksCollection on Page {
    ...ContentfulSysId
    blocksCollection(limit: ${blockQueryLimit}) {
      items {
        ... on BlockHero {
          ...BlockHeroAll
        }
      }
    }
  }
  ${contentfulSysIdFragment}
`;

interface PageBlocksCollectionData extends ContentfulTypedSysProps<'Page'> {
  blocksCollection: Items<BlockDataProps | HeroDataProps | SubNavigationDataProps>;
}

// =================================================================================================
// Latest Post Hero
// =================================================================================================

export const latestPostHeroQuery = gql`
  query LatestPostHero($locale: String!, $preview: Boolean!) {
    slugCollection(
      limit: 1
      order: postedDate_DESC
      where: { AND: [{ slugType_contains: "Blog" }] }
      locale: $locale
      preview: $preview
    ) {
      items {
        ...ContentfulSysId
        slug
        postedDate
        author {
          ...ContentfulSysId
          name
          position
          slug
        }
        page {
          ...PageAssetAll
          ...ExperimentOnPageLoadingPageBlocksCollection
        }
      }
    }
  }
  ${contentfulSysIdFragment}
  ${heroFragments.all}
  ${pageBlockCollectionsFragment}
  ${createExperimentFragmentNoAb('Page', 'PageBlocksCollection', pageBlockCollectionsFragment)}
  ${pageAssetFragment}
`;

export interface LatestPostHeroProps {
  slugCollection: Items<
    ContentfulTypedSysProps<'Slug'> & {
      slug: string;
      page: PageBlocksCollectionData | Experiment | PageAsset;
      author?: {
        name: string;
        position: number;
        slug: string;
      };
    }
  >;
}
