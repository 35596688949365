import { css } from '@emotion/css';

import { nonMobileMediaQuery } from '../../constants';
import { m } from '../../motif/m';

export const textBoldCss = css`
  font-weight: 600;
`;

export const navigationPanelsContainerCss = css`
  color: ${m('--fg-color')};
  background-color: ${m('--bg-color')};
  align-items: center;
  bottom: 0;
  box-shadow: ${m('--box-shadow-xl')};
  display: flex;
  left: 0;
  height: 60px;
  overflow: hidden;
  padding: 10px 17px;
  box-sizing: border-box;

  div {
    margin-left: auto;

    [dir='rtl'] & {
      margin-left: 0;
      margin-right: auto;
    }
  }

  ${nonMobileMediaQuery} {
    height: 104px;
    box-shadow: ${m('--box-shadow-m')};
    padding: 13px 64px;
  }
`;

export const buttonsContainerCss = css`
  display: flex;
  gap: 16px;
  justify-self: end;

  ${nonMobileMediaQuery} {
    gap: 42px;
  }
`;

export const navigationButtonContainerCss = css`
  align-items: center;
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  font-size: 16px;
  gap: 8px;
  justify-content: center;
  line-height: 26px;
  margin: 0;
  padding: 0;
  position: relative;
  color: inherit;

  i {
    align-items: center;
    border: ${m('--border-width-xs')} solid ${m('--block-navigation-buttons-active-border-color')};
    background-color: ${m('--block-navigation-buttons-active-bg-color')};
    border-radius: 50%;
    display: flex;
    justify-content: center;
    min-height: 39px;
    min-width: 39px;

    ${nonMobileMediaQuery} {
      min-height: 63px;
      min-width: 63px;
    }
  }

  svg {
    width: 20px;
    height: 20px;

    path {
      fill: ${m('--block-navigation-buttons-active-color')};
    }

    ${nonMobileMediaQuery} {
      width: 30px;
      height: 30px;
    }
  }

  &:disabled,
  &:hover {
    i {
      border: none;
      min-height: 41px;
      min-width: 41px;

      ${nonMobileMediaQuery} {
        min-height: 65px;
        min-width: 65px;
      }
    }
  }

  &:disabled {
    cursor: initial;

    i {
      border: ${m('--border-width-xs')} solid ${m('--block-navigation-buttons-border-color')};
      background-color: ${m('--block-navigation-buttons-bg-color')};

      svg {
        path {
          fill: ${m('--block-navigation-buttons-color')};
        }
      }
    }
  }
`;
