import { gql } from '@apollo/client';
import type { SitewideConfigurationCollection } from '@snapchat/mw-contentful-schema';

import { ctaFragment } from '../../components/CallToAction';
import {
  customFooterFragment,
  footerCookiesSettingsLinkFragment,
  footerGroupFragment,
  footerItemFragment,
  footerLocaleDropdownFragment,
} from '../../components/FooterWrapper/query';
import {
  featuredHeaderMenuItemFragment,
  headerMenuItemFragment,
  headerMenuItemGroupFragment,
  navigatorFragment,
} from '../../components/Header/headerQuery';
import { navigationCtaFragment } from '../../components/NavigationCta';
import { contentfulSysIdFragment } from '../../utils/contentful/contentfulQuery';

const sitewideValuesFragment = gql`
  fragment SitewideValuesAll on SitewideValues {
    ...ContentfulSysId
    navigationBar {
      ...NavigatorLocal
    }
    customFooter {
      ...CustomFooterAll
    }
    featureFlags {
      ...ContentfulSysId
      featureFlags
    }
  }
  ${contentfulSysIdFragment}
`;

const sitewideConfigurationFragment = gql`
  fragment SitewideConfiguration on SitewideConfiguration {
    ...ContentfulSysId
    reference {
      ... on SitewideValues {
        ...SitewideValuesAll
      }
      ... on SitewideExperiment {
        ...ContentfulSysId
        default {
          ...SitewideValuesAll
        }
        seed
        variantsCollection(limit: 5) {
          items {
            ...ContentfulSysId
            trafficStartRange
            trafficEndRange
            analyticsId
            replacementsCollection(limit: 10) {
              items {
                ...ContentfulSysId
                replacementTarget {
                  ...ContentfulSysId
                }
                replacement {
                  ... on Navigator {
                    ...NavigatorLocal
                  }
                  ... on HeaderMenuItemGroup {
                    ...HeaderMenuItemGroupFieldsOnly
                  }
                  ... on HeaderMenuItem {
                    ...HeaderMenuItemFieldsOnly
                  }
                  ... on FeaturedHeaderMenuItem {
                    ...FeaturedHeaderMenuItemFieldsOnly
                  }
                  ... on NavigationCallToAction {
                    ...NavigationCta
                  }
                  ... on CallToAction {
                    ...CallToActionAll
                  }
                  ... on FeatureFlags {
                    ...ContentfulSysId
                    featureFlags
                  }
                  ... on CustomFooter {
                    ...CustomFooterAll
                  }
                  ... on FooterCookiesSettingsLink {
                    ...FooterCookiesSettingsLinkAll
                  }
                  ... on FooterGroup {
                    ...FooterGroupAll
                  }
                  ... on FooterItem {
                    ...FooterItemAll
                  }
                  ... on FooterLocaleDropdown {
                    ...FooterLocaleDropdownAll
                  }
                }
              }
            }
          }
        }
        analyticsId
      }
    }
  }
  ${contentfulSysIdFragment}
  ${navigatorFragment}
  ${customFooterFragment}
  ${sitewideValuesFragment}
  ${navigationCtaFragment}
  ${headerMenuItemGroupFragment}
  ${headerMenuItemFragment}
  ${featuredHeaderMenuItemFragment}
  ${ctaFragment}
  ${footerGroupFragment}
  ${footerCookiesSettingsLinkFragment}
  ${footerLocaleDropdownFragment}
  ${footerItemFragment}
  ${customFooterFragment}
`;

export const sitewideConfigurationQuery = gql`
  query SitewideConfigurationQuery($preview: Boolean!, $locale: String!) {
    sitewideConfigurationCollection(preview: $preview, locale: $locale, limit: 1) {
      items {
        ...SitewideConfiguration
      }
    }
  }
  ${sitewideConfigurationFragment}
`;

export interface SitewideConfigurationData {
  sitewideConfigurationCollection: SitewideConfigurationCollection;
}
