import type { FC } from 'react';

import type { ContentfulIdVariable } from '../../hooks/useContentfulQuery';
import { useContentfulQuery } from '../../hooks/useContentfulQuery';
import { isContentfulIdProps } from '../../utils/contentful';
import { Hyperlink } from '../Hyperlink';
import { query } from './query';
import type { DownloadableAssetDataHandlerProps, DownloadableAssetProps } from './types';

export const DownloadableAsset: FC<DownloadableAssetProps> = props => {
  const hasData = !!props?.linkText && !!props?.file?.url;
  const { data } = useContentfulQuery<DownloadableAssetDataHandlerProps, ContentfulIdVariable>(
    query,
    {
      variables: { id: isContentfulIdProps(props) ? props.id : props.sys.id },
      skip: hasData,
    }
  );

  const result = hasData ? props : data?.downloadableAsset;
  if (!result) return null;

  return <Hyperlink uri={result?.file?.url}>{result?.linkText}</Hyperlink>;
};

DownloadableAsset.displayName = 'DownloadableAsset';
