import { gql } from '@apollo/client';

import { fragments as mediaFragments } from '../Media';

export const fragments = {
  all: gql`
    fragment ImageAll on Image {
      sys {
        id
      }
      media {
        ...AssetAll
      }
      mobileMedia {
        ...AssetAll
      }
      wrap
      enableHighDpi
      quality
    }
    ${mediaFragments.all}
  `,
};

export const query = gql`
  query ImageQuery($preview: Boolean!, $locale: String!, $id: String!) {
    image(preview: $preview, locale: $locale, id: $id) {
      ...ImageAll
    }
  }
  ${fragments.all}
`;
