import { css } from '@emotion/css';

import { Spacing } from '../../../constants';
import { m } from '../../../motif';

export const cardCss = css`
  padding: 25px;
  background: ${m('--palette-gray-v50')};
  border-radius: 8px;
  width: 350px;
  height: 100px;
  margin: 25px 32px 0 0;
  *[dir='rtl'] & {
    margin: 25px 0 0 32px;
  }
`;

export const cardAmountCss = css`
  font-size: 48px;
  margin: 10px 0;
  font-weight: 600;
`;

export const cardTitleCss = css`
  color: ${m('--palette-gray-v300')};
`;

export const rowCss = css`
  display: flex;
  flex-wrap: wrap;
`;

export const headerRowCss = css`
  ${rowCss}
  justify-content: space-between;
`;

export const mainControlsCss = css`
  ${rowCss}
  flex-direction: row-reverse;
  justify-content: space-between;
  margin-top: 10px;

  .sdsm-button:not(:first-child) {
    margin-left: 5px;
  }
`;

export const partitionSelectorCss = css`
  border: none;
  align-items: center;
  background-color: ${m('--palette-gray-v50')};
  border-radius: ${Spacing['2XS']}px;
  color: ${m('--palette-gray-v300')}px;
  cursor: pointer;
  font-size: 14px;
  padding: ${Spacing.MD}px;
  display: flex;
  justify-content: space-between;
  min-width: 160px;
`;

export const dateSelectorButtonCss = css`
  background-color: ${m('--palette-plain-white')};
  border: ${m('--border-width-xs')} solid ${m('--palette-gray-v200')};
  border-radius: 15px;
  height: 30px;
  width: 30px;
  cursor: pointer;
`;

export const vizTitleCss = css`
  font-weight: 500;
`;

export const vizFilterContainerCss = css`
  ${rowCss};
  align-self: flex-end;
`;

export const vizFilterButtonCss = (active: boolean): ReturnType<typeof css> => css`
  ${dateSelectorButtonCss};
  border-radius: 0;
  border: ${m('--border-width-xs')} solid
    ${active ? m('--palette-yellow-v200') : m('--palette-gray-v200')};
  fill: ${active ? m('--palette-black-v200') : m('--palette-gray-v200')};
`;
