import { css } from '@emotion/css';

import { mobileMediaQuery, nonMobileMediaQuery } from '../../constants';
import { p2Css, p3Css } from '../../motif';
import { m } from '../../motif/m';
import { paragraphCss } from '../Primitives';

export const formCss: string = css`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100% !important;
  max-width: 650px;
  margin: auto;
  /* extra margin for button shadow */
  margin-bottom: ${m('--spacing-m')};
`;

export const formRowCss: string = css`
  display: flex;
  align-items: flex-start;
  column-gap: ${m('--form-grid-gap')};
  width: 100%;

  /* Add extra top spacing if there is a label */
  &:not(:first-child) label {
    margin-top: ${m('--spacing-m')};
  }

  ${mobileMediaQuery} {
    flex-direction: column;
  }
`;

export const inputLabelCss = css`
  margin-bottom: ${m('--spacing-xs')};
`;

export const inputHelpTextCss = css`
  ${p2Css}
  color: ${m('--form-input-placeholder-color')};
  margin-top: ${m('--spacing-xs')};
`;

export const errorMessageCss = css`
  ${inputHelpTextCss}

  color: ${m('--form-input-error-color')};
`;

export const errorDebugSectionCss = css`
  width: 100%;
  text-align: center;
  margin-top: ${m('--spacing-m')};
`;

export const errorCodeCss = css`
  display: block;
  text-align: start;
  border: ${m('--form-input-border-width')} solid ${m('--form-input-border-color')};
  border-radius: ${m('--spacing-m')};
  background-color: ${m('--form-input-bg-color')};
  box-shadow: ${m('--form-input-box-shadow')};
  color: ${m('--form-input-error-color')};
  margin-top: ${m('--spacing-s')};
  padding: ${m('--spacing-m')};
  word-wrap: break-word;
`;

export const submitButtonCss = css`
  justify-content: center;

  margin-top: ${m('--spacing-xl')};
  margin-bottom: ${m('--spacing-xl')};

  ${mobileMediaQuery} {
    width: 100%;
    max-width: 350px;
    margin-top: ${m('--spacing-m')};
    margin-bottom: ${m('--spacing-m')};
  }
`;

export const inputContainerCss = css`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: ${m('--spacing-m')};

  *[dir='rtl'] & > select {
    background-position: left ${m('--spacing-l')} top 50%;
  }
`;

export const inputErrorCss = css`
  border: ${m('--form-input-border-width')} solid ${m('--form-input-error-color')};

  &:hover {
    border: ${m('--form-input-border-width')} solid ${m('--form-input-error-color')};
  }
`;

const readonlyCss = css`
  cursor: not-allowed;
  opacity: 0.8;
`;

export const inputCss: string = css`
  background-color: ${m('--form-input-bg-color')};
  border: ${m('--form-input-border-width')} solid ${m('--form-input-border-color')};
  border-radius: ${m('--form-input-border-radius')};
  box-shadow: ${m('--form-input-box-shadow')};
  box-sizing: border-box;
  color: ${m('--form-input-fg-color')};
  padding: ${m('--form-input-padding')};
  height: 52px;
  width: 100%;

  &[readonly] {
    ${readonlyCss}
  }

  &:active,
  &:focus {
    border-color: ${m('--form-input-active-border-color')};
    box-shadow: ${m('--form-input-active-box-shadow')};
  }

  &:focus-visible {
    outline: ${m('--form-input-border-width')} solid ${m('--form-input-active-border-color')};
    outline-offset: -2px;
  }

  &:hover {
    border-color: ${m('--form-input-hover-border-color')};
    box-shadow: ${m('--form-input-hover-box-shadow')};
  }

  &[type='number']::-webkit-inner-spin-button {
    opacity: 1;
  }

  &[type='date']::-webkit-calendar-picker-indicator {
    filter: invert(50%);
  }

  ${nonMobileMediaQuery} {
    font-size: ${m('--form-input-desktop-font-size')};
    line-height: ${m('--form-input-desktop-font-line-height')};
    font-weight: ${m('--form-input-desktop-font-weight')};
    font-stretch: ${m('--form-input-desktop-font-stretch')};
  }

  ${mobileMediaQuery} {
    font-size: ${m('--form-input-mobile-font-size')};
    line-height: ${m('--form-input-mobile-font-line-height')};
    font-weight: ${m('--form-input-mobile-font-weight')};
    font-stretch: ${m('--form-input-mobile-font-stretch')};
  }
`;

const checkboxCheckPath =
  // eslint-disable-next-line max-len
  'M4.95801 10.8631C4.43001 10.8631 3.91701 10.6511 3.54301 10.2771L0.293006 7.02713C-0.0979941 6.63613 -0.0979941 6.00413 0.293006 5.61313C0.684006 5.22213 1.31601 5.22213 1.70701 5.61313L4.95701 8.86313L12.253 0.655126C12.619 0.242126 13.251 0.205126 13.664 0.572126C14.077 0.940126 14.114 1.57113 13.747 1.98513L6.45201 10.1921C6.08701 10.6021 5.56301 10.8461 5.01601 10.8621C4.99601 10.8631 4.97701 10.8631 4.95801 10.8631Z';

export const checkboxCss: string = css`
  appearance: none;
  background-color: ${m('--form-input-bg-color')};
  border: ${m('--form-input-border-width')} solid ${m('--form-input-border-color')};
  border-radius: ${m('--border-radius-s')};
  display: grid;
  font: inherit;
  height: 20px;
  margin: 0;
  place-content: center;
  width: 20px;

  &::before {
    box-shadow: inset 1em 1em ${m('--palette-plain-white')};
    clip-path: path('${checkboxCheckPath}');
    content: '';
    height: 20px;
    margin: 8px 0px 0px 6px;
    transform: scale(0);
    width: 20px;
  }

  &:checked::before {
    transform: scale(1);
  }

  &:checked {
    background-color: ${m('--form-input-active-border-color')};
    border-width: ${m('--border-width-none')};
    box-shadow: ${m('--form-input-active-box-shadow')};
  }

  &:focus-visible {
    outline: ${m('--form-input-border-width')} solid ${m('--form-input-active-border-color')};
    outline-offset: -2px;
  }

  &:not(:checked):hover {
    border-color: ${m('--form-input-hover-border-color')};
    box-shadow: ${m('--form-input-hover-box-shadow')};
  }

  &[required]:not(:checked)::before {
    border-color: ${m('--form-input-error-color')};
  }

  &[readonly] {
    ${readonlyCss}
  }
`;

export const checkboxWrapperCss: string = css`
  && {
    align-items: center;
    display: flex;
    gap: ${m('--spacing-s')};

    label {
      align-items: center;
      display: flex;
      justify-content: center;
      margin-top: 0;

      a {
        &,
        &:hover,
        &:visited {
          /* TODO: See if this should be hyperlink color or its own */
          color: ${m('--fg-color')};
        }
      }
    }
  }
`;

export const textAreaCss = css`
  appearance: none;
  ${inputCss}
  font-family: inherit;
  height: 133px;
  padding: ${m('--form-input-padding')};
  resize: vertical;
  /* Add custom resizer icon as background */
  background-image: url("data:image/svg+xml,%3Csvg height='9' viewBox='0 0 9 9' width='9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5 8L8 5M1 8L8 1' stroke='rgba(133, 141, 148, 1)'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: bottom 4px right 4px;

  &::-webkit-resizer {
    display: none;
  }

  &[readonly] {
    ${readonlyCss}
  }

  /* Change custom resizer icon position according to RTL */
  *[dir='rtl'] & {
    background-image: url("data:image/svg+xml,%3Csvg height='9' viewBox='0 0 9 9' transform='scale(-1 1)' width='9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5 8L8 5M1 8L8 1' stroke='rgba(133, 141, 148, 1)'/%3E%3C/svg%3E");
    background-position: bottom 4px left 4px;
  }
`;

export const selectCss: string = css`
  ${inputCss}
  appearance: none;
  height: auto;

  &:-ms-expand {
    display: none;
  }

  &:has(option:disabled:checked) {
    color: ${m('--form-input-placeholder-color')};
  }
`;

export const selectWrapperCss = css`
  position: relative;

  i {
    margin-left: 10px;
    width: 10px;
    cursor: pointer;

    svg {
      fill: ${m('--palette-gray-v300')};
    }
  }
`;

// needed to enforce same placeholder color for dropdown input
export const placeholderCss = css`
  input::-webkit-input-placeholder,
  textarea::-webkit-input-placeholder,
  input:-moz-placeholder,
  textarea:-moz-placeholder,
  input::-moz-placeholder,
  textarea::-moz-placeholder,
  input:-ms-input-placeholder,
  textarea:-ms-input-placeholder,
  input::-ms-input-placeholder,
  textarea::-ms-input-placeholder,
  input::placeholder,
  textarea::placeholder,
  ::placeholder {
    color: ${m('--form-input-placeholder-color')};
  }
`;

export const arrowCss = css`
  pointer-events: none;
  position: absolute;
  right: ${m('--spacing-l')};
  top: calc(50% - 5px);
  width: 20px;
  height: 20px;
  margin: -4px;
  fill: ${m('--form-input-fg-color')};

  html[dir='rtl'] & {
    right: unset;
    left: ${m('--spacing-l')};
  }
`;

export const multiSelectDropdownCss = css`
  ${inputCss}
  height: auto;
  padding: 0;

  /** prevents issue with SSR rendering without this style */
  .displayNone {
    display: none;
  }

  /** applies styles to multi select wrapper when displaying options */
  &:has(.displayBlock) {
    border-color: ${m('--form-input-active-border-color')};
    box-shadow: ${m('--form-input-active-box-shadow')};
  }

  .searchWrapper {
    padding: 0 ${m('--spacing-xxl')} 0 ${m('--spacing-xs')};
    border: 0;
    min-height: 50px;
    align-items: center;
    display: flex;
    flex-wrap: wrap;

    input {
      color: inherit;
      font-size: inherit;
      /* input is disabled */
      cursor: default;
      min-width: 0px;
      padding-left: ${m('--spacing-xs')};
      flex: 1 1 0%;
    }

    .chip {
      background: ${m('--form-input-border-color')};
      color: ${m('--form-input-fg-color')};
      font-size: inherit;
      padding: ${m('--spacing-xs')};
      margin: 5px;
      cursor: default;

      svg {
        fill: ${m('--form-input-fg-color')};
      }
    }

    .icon_down_dir {
      right: -60px;
    }
  }

  .optionListContainer {
    background: ${m('--form-input-bg-color')};
    list-style-type: none;
    padding-left: ${m('--spacing-xs')};
    padding-right: ${m('--spacing-xs')};
    z-index: 51;

    .optionContainer {
      border: none;

      &::-webkit-scrollbar {
        /* stylelint-disable-next-line property-no-vendor-prefix */
        -webkit-appearance: none;
        width: 7px;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background-color: rgba(0, 0, 0, 0.5);
        box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
      }
    }

    .option {
      display: flex;
      align-items: center;
      margin-top: ${m('--spacing-xs')};
      margin-bottom: ${m('--spacing-xs')};
      padding-left: ${m('--spacing-m')};
      padding-right: ${m('--spacing-m')};
      border-radius: 5px;
      color: ${m('--form-input-fg-color')};

      &.selected,
      &.highlight,
      &:hover {
        background: ${m('--form-input-border-color')};
        color: ${m('--form-input-fg-color')};
      }

      input[type='checkbox'] {
        height: 17px;
        width: 17px;

        &:checked {
          accent-color: ${m('--form-input-fg-color')};
        }
      }
    }
  }
`;

export const multiSelectIconCss = css`
  margin: -7px;
`;

/** Add asterik at the end of labels to highlight required fields */
export const requiredCss = css`
  & p:last-of-type {
    display: inline-block;
  }

  &::after {
    color: ${m('--form-input-error-color')};
    content: '*';
  }
`;

export const requiredDescriptionCss = css`
  ${paragraphCss}
  ${p3Css}
  color: ${m('--form-description-fg-color')};
  font-style: italic;
  font-weight: ${m('--action-desktop-font-weight')};
  text-align: start;
  width: 100%;
`;
