export type Animation = {
  animationName?: string;
  delay?: number;
  direction?: string;
  duration?: number;
  iterationCount?: string;
  keyframes?: string;
  timingFunction?: string;
  transformOrigin?: string;
};

export type GlobalAnimation = {
  name: string;
  keyframes: string;
};
