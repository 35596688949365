import { css } from '@emotion/css';

import { Transparent } from '../constants';
import { m } from '../motif';

export interface ScrollbarProps {
  backgroundColor: string;
  thumbColor: string;
  trackWidth: string;
}

export const scrollbarCss = ({
  backgroundColor,
  thumbColor,
  trackWidth,
}: ScrollbarProps): string => css`
  /* Firefox. See developer.mozilla.org/en-US/docs/Web/CSS/CSS_Scrollbars */
  scrollbar-width: ${trackWidth};
  scrollbar-color: ${thumbColor} ${backgroundColor};

  /* Edge / Chrome / Safari. See css-tricks.com/custom-scrollbars-in-webkit/ */
  ::-webkit-scrollbar {
    width: ${trackWidth};
    background-color: ${backgroundColor};
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${thumbColor};
    border-radius: calc(${trackWidth} / 2);
    /* Can't control the width, so we add a border to introduce margin. */
    border: ${m('--border-width-s')} solid ${backgroundColor};
  }

  ::-webkit-scrollbar-track {
    background-color: ${Transparent};
  }
`;
