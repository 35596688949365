import type { MotifComponent, MotifComponentProps } from '../../motif';
import { m } from '../../motif/m';

export const sideOverlayYellowBgVars: MotifComponentProps<MotifComponent.SIDE_OVERLAY> = {
  '--side-overlay-bg-color': m('--neutral-v100'),
  '--side-overlay-border-color': m('--neutral-v300'),
  '--side-overlay-fg-color': m('--neutral-v700'),
} as const;

export const sideOverlayBlackBgVars: MotifComponentProps<MotifComponent.SIDE_OVERLAY> = {
  '--side-overlay-bg-color': m('--neutral-v625'),
  '--side-overlay-border-color': m('--neutral-v600'),
  '--side-overlay-fg-color': m('--neutral-v0'),
} as const;

export const sideOverlayWhiteBgVars: MotifComponentProps<MotifComponent.SIDE_OVERLAY> = {
  '--side-overlay-bg-color': m('--neutral-v100'),
  '--side-overlay-border-color': m('--neutral-v200'),
  '--side-overlay-fg-color': m('--neutral-v700'),
} as const;

export const sideOverlayGrayBgVars: MotifComponentProps<MotifComponent.SIDE_OVERLAY> = {
  '--side-overlay-bg-color': m('--neutral-v100'),
  '--side-overlay-border-color': m('--neutral-v300'),
  '--side-overlay-fg-color': m('--neutral-v700'),
} as const;
