import type { MotifComponent, MotifComponentProps } from '../../motif';
import { m } from '../../motif/m';

export const definitionYellowBgVars: MotifComponentProps<MotifComponent.DEFINITION> = {
  '--definition-active-bg-color': m('--neutral-v0'),
  '--definition-active-border-color': m('--neutral-v700'),
  '--definition-bg-color': m('--primary-v150'),
  '--definition-border-color': m('--neutral-v700'),
  '--definition-hover-bg-color': m('--primary-v200'),
  '--definition-hover-border-color': m('--neutral-v700'),
} as const;

export const definitionBlackBgVars: MotifComponentProps<MotifComponent.DEFINITION> = {
  '--definition-active-bg-color': m('--neutral-v500'),
  '--definition-active-border-color': m('--primary-v200'),
  '--definition-bg-color': m('--neutral-v625'),
  '--definition-border-color': m('--primary-v200'),
  '--definition-hover-bg-color': m('--neutral-v600'),
  '--definition-hover-border-color': m('--primary-v200'),
} as const;

export const definitionWhiteBgVars: MotifComponentProps<MotifComponent.DEFINITION> = {
  '--definition-active-bg-color': m('--primary-v150'),
  '--definition-active-border-color': m('--primary-v200'),
  '--definition-bg-color': m('--neutral-v100'),
  '--definition-border-color': m('--primary-v200'),
  '--definition-hover-bg-color': m('--neutral-v250'),
  '--definition-hover-border-color': m('--primary-v200'),
} as const;

export const definitionGrayBgVars: MotifComponentProps<MotifComponent.DEFINITION> = {
  '--definition-active-bg-color': m('--primary-v150'),
  '--definition-active-border-color': m('--primary-v200'),
  '--definition-bg-color': m('--neutral-v100'),
  '--definition-border-color': m('--primary-v200'),
  '--definition-hover-bg-color': m('--neutral-v250'),
  '--definition-hover-border-color': m('--primary-v200'),
} as const;
