import type { MotifComponent, MotifComponentProps } from '../../motif';
import { m } from '../../motif/m';

const defaultVars = {
  '--banner-fg-color': m('--neutral-v700'),
  '--banner-font-size': m('--action-desktop-font-size'),
  '--banner-font-line-height': m('--action-desktop-font-line-height'),
};

export const bannerYellowBgVars: MotifComponentProps<MotifComponent.BANNER> = {
  '--banner-bg-color': m('--primary-v100'),
  ...defaultVars,
};

export const bannerBlackBgVars: MotifComponentProps<MotifComponent.BANNER> = {
  ...defaultVars,
  '--banner-bg-color': m('--neutral-v625'),
  '--banner-fg-color': m('--neutral-v0'),
};

export const bannerWhiteBgVars: MotifComponentProps<MotifComponent.BANNER> = {
  '--banner-bg-color': m('--neutral-v0'),
  ...defaultVars,
};

export const bannerGrayBgVars: MotifComponentProps<MotifComponent.BANNER> = {
  '--banner-bg-color': m('--neutral-v150'),
  ...defaultVars,
};
