import { gql } from '@apollo/client';

import { contentfulSysIdFragment } from '../../utils/contentful/contentfulQuery';

export const fragments = {
  all: gql`
    fragment DownloadableAssetAll on DownloadableAsset {
      ...ContentfulSysId
      linkText
      file {
        url
      }
    }
    ${contentfulSysIdFragment}
  `,
};

export const query = gql`
  query DownloadableAssetQuery($preview: Boolean!, $locale: String!, $id: String!) {
    downloadableAsset(preview: $preview, locale: $locale, id: $id) {
      ...DownloadableAssetAll
    }
  }
  ${fragments.all}
`;
