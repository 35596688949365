import type { MotifComponent, MotifComponentProps } from '../../motif';
import { m } from '../../motif/m';

export const formSpacingVars = {
  '--form-grid-gap': m('--spacing-xs'),
} as const;

export const inputVars = {
  '--form-description-fg-color': m('--neutral-v500'),
  '--form-input-placeholder-color': m('--neutral-v400'),
  '--form-input-fg-color': m('--neutral-v700'),
  '--form-input-error-color': m('--semantic-error-color'),
  '--form-input-bg-color': m('--neutral-v0'),
  '--form-input-border-color': m('--neutral-v250'),
  '--form-input-hover-border-color': m('--neutral-v400'),
  '--form-input-active-border-color': m('--semantic-focus-color'),
  '--form-input-border-width': '2px',
  '--form-input-border-radius': m('--border-radius-l'),
  '--form-input-desktop-font-size': m('--text-desktop-font-size'),
  '--form-input-desktop-font-line-height': 'normal',
  '--form-input-desktop-font-weight': m('--text-desktop-font-weight'),
  '--form-input-mobile-font-size': m('--text-mobile-font-size'),
  '--form-input-mobile-font-line-height': m('--text-mobile-font-line-height'),
  '--form-input-mobile-font-weight': m('--text-mobile-font-weight'),
  '--form-input-box-shadow': 'none',
  '--form-input-hover-box-shadow': m('--box-shadow-xs'),
  '--form-input-active-box-shadow': m('--box-shadow-s'),
  '--form-input-padding': m('--spacing-m'),
  '--form-input-mobile-font-stretch': m('--h6-mobile-font-stretch'),
  '--form-input-desktop-font-stretch': m('--h6-desktop-font-stretch'),
} as const;

export const formVars: MotifComponentProps<MotifComponent.FORM> = {
  ...formSpacingVars,
  ...inputVars,
} as const;

export const contrastBlackFormVars: MotifComponentProps<MotifComponent.FORM> = {
  ...formVars,
  '--form-description-fg-color': m('--neutral-v250'),
  '--form-input-placeholder-color': m('--neutral-v300'),
  '--form-input-fg-color': m('--neutral-v0'),
  '--form-input-bg-color': m('--neutral-v625'),
  '--form-input-error-color': m('--palette-red-v50'),
  '--form-input-border-color': m('--neutral-v500'),
  '--form-input-hover-border-color': m('--neutral-v400'),
} as const;

export const contrastGrayFormVars: MotifComponentProps<MotifComponent.FORM> = {
  ...formVars,
  '--form-input-border-color': m('--neutral-v300'),
} as const;
