import type { ImageSubtopic as ImageSubtopicType } from '@snapchat/mw-contentful-schema';
import { ImageSubtopic as ImageSubtopicSDS } from '@snapchat/snap-design-system-marketing';
import type { FC } from 'react';

import { useContentfulImages } from '../../../../contentful-client/src';
import { combineImageSources } from '../../utils/combineImageSources';
import { type ImageQuality, getImageSourceSettings } from '../Image';

export const ImageSubtopic: FC<ImageSubtopicType> = props => {
  const { getImageSources } = useContentfulImages();

  const { title, image } = props;

  const { desktopSettings, mobileSettings } = getImageSourceSettings({
    desktopHeight: image?.media?.height ?? 0,
    mobileHeight: image?.mobileMedia?.height ?? 0,
    desktopDefaultHeight: 120,
    mobileDefaultHeight: 120,
    enableHighDpi: image?.enableHighDpi,
    quality: image?.quality as ImageQuality | undefined,
  });

  const imgSrcs = combineImageSources({
    desktop: getImageSources(image?.media?.url, desktopSettings),
    mobile: getImageSources(image?.mobileMedia?.url, mobileSettings),
  });

  return <ImageSubtopicSDS title={title} imgSrcs={imgSrcs} />;
};
