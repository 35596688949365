import type { HeaderMenuItemGroup } from '@snapchat/mw-contentful-schema';
import { NavigatorItemDesktop as NavigatorItemDesktopSDS } from '@snapchat/snap-design-system-marketing';
import type { FC } from 'react';

import { shouldFlushLogs } from '../../helpers/getLocalPath';
import { logEvent, logger, SubscribedEventType } from '../../helpers/logging';
import { UserAction } from '../../types/events';
import { getContentfulInspectorProps } from '../../utils/contentful/getContentfulInspectorProps';
import type { NavigatorGroupProps } from '../Header/headerNavGroupUtils';

function logLocalNavDesktopClick(url: string, label?: string) {
  logEvent({
    subscribedEventType: SubscribedEventType.USER_INTERACTION,
    eventCategory: 'NavigatorItem',
    eventAction: UserAction.Click,
    eventLabel: label ?? url,
  });
}

interface LocalNavDesktopProps {
  items?: NavigatorGroupProps[];
}

export const LocalNavDesktop: FC<LocalNavDesktopProps> = ({ items }) => {
  if (!items || !items.length) {
    return null;
  }

  const getItemDataset = (id: string) => {
    const { contentfulDescriptionDataset } = getContentfulInspectorProps<HeaderMenuItemGroup>({
      entryId: id,
      fieldIds: ['contentfulDescription'],
    });

    return contentfulDescriptionDataset;
  };

  const handleClick = (url: string, analyticsId?: string): void => {
    logLocalNavDesktopClick(url, analyticsId);

    if (shouldFlushLogs(url)) {
      void logger.flush();
    }
  };

  return (
    <>
      {items.map(item => {
        const {
          sys,
          url,
          title,
          isSelected,
          analytics,
          column1Label,
          column1Items,
          column2Label,
          column2Items,
          column3Label,
          column3Items,
          featuredMenuItem,
        } = item;
        const id = sys.id;

        return (
          <NavigatorItemDesktopSDS
            key={id}
            dataset={getItemDataset(id)}
            id={id}
            url={url ?? ''}
            isSelected={isSelected}
            title={title ?? ''}
            analytics={analytics?.label ? { label: analytics.label } : undefined}
            col1Label={column1Label}
            col1Items={column1Items}
            col2Label={column2Label}
            col2Items={column2Items}
            col3Label={column3Label}
            col3Items={column3Items}
            featuredItem={featuredMenuItem}
            onClick={handleClick}
          />
        );
      })}
    </>
  );
};
