import { css } from '@emotion/css';
import {
  ContentElementClass,
  m,
  mobileMediaQuery,
  MotifComponent,
  nonMobileMediaQuery,
} from '@snapchat/snap-design-system-marketing';

export const mediaStylesCss = css`
  max-width: 100%;
  max-height: 400px;

  /* only do non mobile for now as we break rendering images that are very wide */
  ${nonMobileMediaQuery} {
    &.strict-height {
      height: var(--mediaHeight);
    }
  }
`;

export const mediaOnlyCss = css`
  margin-bottom: inherit;
  max-height: 600px;

  ${nonMobileMediaQuery} {
    &.strict-height {
      height: var(--mediaHeight);
    }
  }

  ${mobileMediaQuery} {
    max-width: 100%;
    height: inherit;
  }
`;

/**
 * Ensures there is spacing after media embedded in the richtext body (maintains prior behavior from
 * the `EmbeddedMedia` component). Also sets max video height per TDD.
 *
 * TODO: determine whether the `display: block` style is something we can generalize in the SDS-M
 * Picture component or if this will have unexpected repercussions.
 */
export const bodyEmbeddedMediaCss = css`
  .${ContentElementClass.BODY} {
    .${MotifComponent.MEDIA} {
      display: block;
      margin-block-end: ${m('--spacing-m')};
    }
    video {
      max-height: 800px;
    }
  }
`;

export const videoCss = css`
  /* Unlike images, videos will always expand to the width of their container. The benefits are two fold...

   1. We don't have to worry about the poster image being a different size than the video.
   2. We don't have to worry about different video resolutions (but same aspect ratio) rendering as different sizes.

  */
  width: 100%;
`;
