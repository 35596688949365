import type { MotifComponent, MotifComponentProps } from '../../motif';
import { m } from '../../motif/m';

export const footnoteVars: MotifComponentProps<MotifComponent.FOOTNOTE> = {
  '--footnote-fg-color': m('--neutral-v500'),
  '--footnote-bg-color': m('--neutral-v0'),
  '--footnote-border-color': m('--neutral-v300'),
  '--footnote-hover-icon-bg-color': m('--neutral-v200'),
  '--footnote-title-color': m('--neutral-v700'),
};
