import { useContentfulImages } from '@snapchat/mw-contentful-client';
import type { ImageSources } from '@snapchat/snap-design-system-marketing';
import { SplitBlock as SplitBlockSDS } from '@snapchat/snap-design-system-marketing';
import type { FC } from 'react';

import { useParseMediaContainer } from '../../hooks/useParseMediaContainer';
import { combineImageSources } from '../../utils/combineImageSources';
import { renderRichTextWithElements } from '../../utils/renderText/renderRichText';
import { CallToAction } from '../CallToAction';
import { getImageSourceSettings } from '../Image';
import type { SplitBlockDataProps } from './SplitBlock.query';

interface VideoProps {
  videoSource?: string;
  mobileVideoSource?: string;
  mediaContentType?: string;
  mobileMediaContentType?: string;
  posterSource?: string;
}

// biome-ignore lint/complexity/noExcessiveCognitiveComplexity: TODO: Refactor to reduce complexity.
export const SplitBlock: FC<SplitBlockDataProps> = props => {
  const { getImageSources } = useContentfulImages();

  const {
    splitBlockTitle: title,
    splitBlockSubtitle: subtitle,
    splitBlockBody: body,
    media,
    callsToActionCollection,
    splitBlockBody,
    ...restProps
  } = props;

  const {
    media: { imageSource, videoSource, contentType },
    mobileMedia: {
      imageSource: mobileImageSource,
      videoSource: mobileVideoSource,
      contentType: mobileContentType,
    },
    thumbnailSource,
  } = useParseMediaContainer(media);

  const backgroundMediaType = media.__typename;

  let imgSrcs: ImageSources | undefined;
  let videoProps: VideoProps | undefined;

  if (backgroundMediaType === 'Image') {
    const { desktopSettings, mobileSettings } = getImageSourceSettings({
      desktopHeight: media.media?.height ?? 0,
      mobileHeight: media.mobileMedia?.height ?? 0,
      enableHighDpi: media.enableHighDpi,
      quality: media.quality,
    });

    imgSrcs = combineImageSources({
      desktop: getImageSources(imageSource, desktopSettings),
      mobile: getImageSources(mobileImageSource, mobileSettings),
    });
  }

  if (backgroundMediaType === 'Video') {
    videoProps = {
      videoSource,
      mobileVideoSource,
      mediaContentType: contentType,
      mobileMediaContentType: mobileContentType,
      posterSource: thumbnailSource,
    };
  }

  const callsToAction = callsToActionCollection?.items?.map(item => (
    <CallToAction key={item.sys.id} {...item} />
  ));

  return (
    <SplitBlockSDS
      title={title}
      subtitle={subtitle}
      body={renderRichTextWithElements(body)}
      callsToAction={callsToAction?.length ? callsToAction : undefined}
      imageSources={imgSrcs}
      {...videoProps}
      {...restProps}
    />
  );
};
