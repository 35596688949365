import { css } from '@emotion/css';

import { m } from '../../motif/m';

export const togglePanelCss = css`
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  border: ${m('--border-width-xs')} solid;
  border-radius: ${m('--border-radius-xl')};
  background-color: ${m('--toggle-panel-bg-color')};
  color: ${m('--toggle-panel-color')};

  &:last-child {
    padding-right: ${m('--spacing-m')};
  }
`;

export const veritcalDividerCss = css`
  border-left: 1px solid;
  height: ${m('--spacing-xl')};
  padding-right: ${m('--spacing-m')};
`;

export const withSliderCss = css`
  padding-right: ${m('--spacing-m')};
`;

export const labelCss = css`
  white-space: nowrap;
  color: ${m('--toggle-panel-color')};
`;
