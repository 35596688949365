import { gql } from '@apollo/client';

import { contentfulSysIdFragment } from '../../utils/contentful/contentfulQuery';

// TODO: delete after merging animation improvements work https://jira.sc-corp.net/browse/WEBP-9618
export const fragments = {
  contentAll: gql`
    fragment ContentAnimationAll on ContentAnimation {
      keyframes {
        ...ContentfulSysId
        timingFunction
        transformOrigin
      }
      target
    }
    ${contentfulSysIdFragment}
  `,
};

// TODO: delete after merging animation improvements work https://jira.sc-corp.net/browse/WEBP-9618
export const query = gql`
  query AnimationCollectionQuery($preview: Boolean!, $locale: String!) {
    animationKeyframesCollection(
      preview: $preview
      locale: $locale
      limit: 20
      order: sys_publishedAt_ASC
    ) {
      items {
        ...ContentfulSysId
        timingFunction
        transformOrigin
        keyframes
      }
    }
  }
  ${contentfulSysIdFragment}
`;

export const animationKeyframesFragment = gql`
  fragment AnimationKeyframesAll on AnimationKeyframes {
    ...ContentfulSysId
    timingFunction
    transformOrigin
    startingCss
    keyframes
  }
  ${contentfulSysIdFragment}
`;

export const entranceAnimationFragment = gql`
  fragment EntranceAnimationAll on EntranceAnimation {
    ...ContentfulSysId
    delay
    direction
    duration
    iterationCount
    animation
    advancedKeyframes {
      ...ContentfulSysId
      ...AnimationKeyframesAll
    }
  }
  ${contentfulSysIdFragment}
  ${animationKeyframesFragment}
`;
