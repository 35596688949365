import { css } from '@emotion/css';

import {
  Alignment,
  mobileMediaQuery,
  mobilePageContainerQuery,
  nonMobileMediaQuery,
} from '../../constants';
import { h4Css, m, MotifComponent, p2Css } from '../../motif';
import { Layout, MediaDirection } from './types';

export const listIconUrlVariable = '--list-icon-url';

/** Ensures scrolling to the element does not result in the element being hidden by header */
export const contentScrollMarginCss = css`
  scroll-margin-top: var(--total-header-height);
`;

// For universal content css styles
export const contentBaseCss = css`
  background-color: ${m('--bg-color')};
  background-image: ${m('--bg-image')};
  color: ${m('--fg-color')};
  display: grid;
  /* we do only 1 column here because these are the default styles when there is only either Media or Text */
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  width: 100%;
  gap: ${m('--spacing-xl')};
  border-color: ${m('--content-border-color')};
  border-width: ${m('--content-border-width')};
  border-style: solid;
  backdrop-filter: ${m('--content-backdrop-filter')};
  box-shadow: ${m('--content-box-shadow')};

  ${mobileMediaQuery} {
    grid-template-columns: 1fr;
    grid-template-rows: auto;
  }

  /*
  We are keeping the media queries above in addition to the container queries below in case:
  - this component is ever rendered without a wrapping SDS-M Page component
  - the browser does not support container queries (smart TVs, etc.)
  */
  ${mobilePageContainerQuery} {
    grid-template-columns: 1fr;
    grid-template-rows: auto;
  }
`;
/**
 * Use this to override background-color when the content background color is the same as the blocks
 * background color or not set.
 */
export const transparentBackgroundCss = css`
  background-color: transparent;
  backdrop-filter: ${m('--content-backdrop-filter')};
`;

// TODO: when we remove old content and update the enums, remove the partial
/** Special CSS needed for Normal and Dense */
export const contentLayoutCss: Partial<Record<Layout, string>> = {
  [Layout.NORMAL]: css`
    align-items: center;
    justify-items: center;
    justify-content: center;
  `,
  [Layout.DENSE]: css`
    align-items: start;
    justify-items: center;
  `,
};

/** Special CSS needed for each different grid variation at the section level */
export const contentLayoutDirectionCss: { [key in Layout]?: { [key in MediaDirection]?: string } } =
  {
    [Layout.NORMAL]: {
      [MediaDirection.LEFT]: css`
        grid-template-columns: auto minmax(auto, 600px);
      `,
      [MediaDirection.RIGHT]: css`
        grid-template-columns: minmax(auto, 600px) auto;
      `,
      [MediaDirection.TOP]: css`
        gap: ${m('--content-desktop-grid-gap')};
        grid-template-rows: auto 1fr;
        align-items: start;

        ${mobileMediaQuery} {
          gap: ${m('--content-mobile-grid-gap')};
        }
      `,
      [MediaDirection.BOTTOM]: css`
        gap: ${m('--content-desktop-grid-gap')};
        grid-template-rows: 1fr auto;
        align-items: start;

        ${mobileMediaQuery} {
          gap: ${m('--content-mobile-grid-gap')};
        }
      `,
    },
    [Layout.DENSE]: {
      [MediaDirection.LEFT]: css`
        grid-template-columns: auto minmax(auto, 1fr);
      `,
      [MediaDirection.RIGHT]: css`
        grid-template-columns: minmax(auto, 1fr) auto;
      `,
      [MediaDirection.TOP]: '',
      [MediaDirection.BOTTOM]: '',
    },
  };

// this helps reverse the order when necessary
export const mediaOrderCss: Partial<Record<MediaDirection, string>> = {
  [MediaDirection.RIGHT]: css`
    order: 1;

    /* always top to bottom on mobile */
    ${mobileMediaQuery} {
      order: 0;
    }
  `,
  [MediaDirection.BOTTOM]: css`
    order: 1;

    /* always top to bottom on mobile */
    ${mobileMediaQuery} {
      order: 0;
    }
  `,
};

// For padding of content when background color is different than the block
export const contentWrapperStyle: Partial<Record<Layout, string>> = {
  [Layout.NORMAL]: css`
    border-radius: ${m('--border-radius-l')};
    padding: ${m('--spacing-xl')};
    ${mobileMediaQuery} {
      padding-left: ${m('--spacing-m')};
      padding-right: ${m('--spacing-m')};
    }
  `,
  [Layout.DENSE]: css`
    border-radius: ${m('--border-radius-l')};
    padding: ${m('--spacing-l')};
    ${mobileMediaQuery} {
      padding-left: ${m('--spacing-m')};
      padding-right: ${m('--spacing-m')};
    }
  `,
};

// For padding of content when background color is same as the block
export const contentWrapperNoMobilePaddingStyle: Partial<Record<Layout, string>> = {
  [Layout.NORMAL]: css`
    border-radius: ${m('--border-radius-l')};
    padding: ${m('--content-desktop-no-bg-padding')};
    ${mobileMediaQuery} {
      padding: ${m('--content-mobile-no-bg-padding')};
    }
  `,
  [Layout.DENSE]: css`
    border-radius: ${m('--border-radius-l')};
  `,
};

// Styles for the body
export const bodyContainerCss = css`
  /* This is needed to make the PrimitiveTable component not overflow. */
  max-width: 100%;
  /* https://weblog.west-wind.com/posts/2016/feb/15/flexbox-containers-pre-tags-and-managing-overflow */
  /* This is needed to make the Code component not overflow. The above blog post kind of explains it-ish... */
  min-width: 0;
`;

// needed for the body to stretch to the full width of the content
// when media direction is top or bottom. only do this for desktop
// to avoid making mobile buttons stretch full width. Will discuss on what behavior we should keep
export const bodyContainerStretchCss = css`
  ${nonMobileMediaQuery} {
    justify-self: stretch;
  }
`;

export const listIconStyleCss = css`
  & ul:not(:has(ul)) {
    list-style: none;
    /* Remove padding-left from primitive styles */
    padding-inline-start: 0;

    & li {
      position: relative;
      /*
        This is the size of the icon
        https://www.figma.com/design/MuetHWEuhUF7rJap6giyq5/%5BEXT%5D-SDS-M-Refresh-2024?node-id=465-20259&t=NPQoIP8Xk8WG9Sus-11
      */
      padding-inline-start: 24px;

      &::before {
        background-image: var(${listIconUrlVariable});
        background-repeat: no-repeat;
        background-size: cover;
        content: '';
        height: 18px;
        inset-block-start: 4px;
        inset-inline-start: 3px;
        position: absolute;
        width: 18px;
      }
    }
  }

  /** Only top level of list items will have the bullet replaced by icon */
  & ul ul {
    list-style: initial;

    & li {
      padding-inline-start: initial;

      &::before {
        content: none;
      }
    }
  }
`;

export const bodyStyleCss = css`
  ${p2Css}

  display: flex;
  flex-direction: column;

  .${MotifComponent.BUTTON} {
    margin-top: ${m('--spacing-s')};
  }

  & ul {
    margin-block-end: ${m('--spacing-s')};

    /** Add margin bottom only to the top level list */
    & ul {
      margin-block-end: 0;
    }
  }
`;

export const bodyMarginTopCss = css`
  margin-top: ${m('--spacing-m')};
`;

export const ctaMarginTopCss = css`
  margin-top: ${m('--spacing-m')};
`;

// Header styles
export const headerCss = css`
  display: flex;
  flex-direction: column;
`;

export const titleCss = css`
  /**
   * The title is rendered as an <h3> so that there's a hierarchy of Hero Title (H1),
   * Block Title (H2) and Content Title (H3). However, visually we want it to look like
   * like an H4 because users put in very long content titles.
   * So we force an H4 look over an H3 element.
   * Note the double specificity to override default h3 styles.
   * See https://jira.sc-corp.net/browse/WEBP-9117
   */
  && {
    ${h4Css}
  }
  color: ${m('--content-title-color')};
`;

export const subtitleBaseCss = css`
  color: ${m('--content-subtitle-color')};
  margin-top: ${m('--spacing-xs')};
`;

// Text alignment section
// TODO: Consolidate w/ alignmentCss and mobileAlignmentCss
// The only "weird" thing here is the "align-items: stretch"
export const alignmentClassNames: { [key in Alignment]: string } = {
  [Alignment.Left]: css`
    align-items: stretch;
    justify-content: flex-start;
    text-align: start;
  `,
  [Alignment.Start]: css`
    align-items: stretch;
    justify-content: flex-start;
    text-align: start;
  `,
  [Alignment.Center]: css`
    align-items: center;
    justify-content: center;
    text-align: center;
  `,
  [Alignment.End]: css`
    align-items: stretch;
    justify-content: flex-end;
    text-align: end;
  `,
  [Alignment.Right]: css`
    align-items: stretch;
    justify-content: flex-end;
    text-align: end;
  `,
};

// Text alignment section
export const textAlignmentBaseCss = css`
  width: 100%;
`;

export const textAlignmentDesktopStyle: { [key in Alignment]: string } = {
  [Alignment.Left]: css`
    ${nonMobileMediaQuery} {
      ${alignmentClassNames[Alignment.Left]}
    }
  `,
  [Alignment.Start]: css`
    ${nonMobileMediaQuery} {
      ${alignmentClassNames[Alignment.Start]}
    }
  `,
  [Alignment.Center]: css`
    ${nonMobileMediaQuery} {
      ${alignmentClassNames[Alignment.Center]}
    }
  `,
  [Alignment.End]: css`
    ${nonMobileMediaQuery} {
      ${alignmentClassNames[Alignment.End]}
    }
  `,
  [Alignment.Right]: css`
    ${nonMobileMediaQuery} {
      ${alignmentClassNames[Alignment.Right]}
    }
  `,
};

export const textAlignmentMobileStyle: { [key in Alignment]: string } = {
  [Alignment.Left]: css`
    ${mobileMediaQuery} {
      ${alignmentClassNames[Alignment.Left]}
    }
  `,
  [Alignment.Start]: css`
    ${mobileMediaQuery} {
      ${alignmentClassNames[Alignment.Start]}
    }
  `,
  [Alignment.Center]: css`
    ${mobileMediaQuery} {
      ${alignmentClassNames[Alignment.Center]}
    }
  `,
  [Alignment.End]: css`
    ${mobileMediaQuery} {
      ${alignmentClassNames[Alignment.End]}
    }
  `,
  [Alignment.Right]: css`
    ${mobileMediaQuery} {
      ${alignmentClassNames[Alignment.Right]}
    }
  `,
};

export const ctaContainerCss = css`
  margin-bottom: ${m('--spacing-m')};
`;

export const ctaInnerCss = css`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: ${m('--spacing-m')};
`;

export const displayNoneCss = css`
  display: none;
`;
