import { type SrcSetSettings, getDprSrcSetSettingsByHeight } from '@snapchat/mw-contentful-client';

import type { ContentfulSysProps } from '../../types/contentful';
import { imageQuality } from './Image';
import type { ImageDataProps, ImageQuality } from './types';

export const isImageDataProps = (props: ContentfulSysProps): props is ImageDataProps => {
  return props.__typename === 'Image';
};

export const getImageSourceSettings = ({
  desktopHeight,
  mobileHeight,
  desktopDefaultHeight = 800,
  mobileDefaultHeight = 800,
  enableHighDpi = false,
  quality: qualityKey = 'Standard',
}: {
  desktopHeight: number;
  mobileHeight: number;
  desktopDefaultHeight?: number;
  mobileDefaultHeight?: number;
  enableHighDpi?: boolean;
  quality?: ImageQuality;
}): {
  desktopSettings: SrcSetSettings;
  mobileSettings: SrcSetSettings;
} => {
  // Use the quality value if provided, otherwise default to standard quality
  const quality = imageQuality[qualityKey];

  // Settings to use if high DPI has not been enabled
  const desktopStandardSettings: SrcSetSettings = {
    image: { height: desktopDefaultHeight, quality },
  };
  const mobileStandardSettings: SrcSetSettings = {
    image: { height: mobileDefaultHeight, quality },
  };

  // Generate high DPI settings for desktop, if enabled
  const desktopSettings = enableHighDpi
    ? getDprSrcSetSettingsByHeight(desktopDefaultHeight, desktopHeight, quality)
    : desktopStandardSettings;

  // Generate high DPI settings for mobile, if enabled
  const mobileSettings = enableHighDpi
    ? getDprSrcSetSettingsByHeight(mobileDefaultHeight, mobileHeight, quality)
    : mobileStandardSettings;

  return {
    desktopSettings: { ...desktopSettings, quality },
    mobileSettings: { ...mobileSettings, quality },
  };
};
