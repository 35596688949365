import { cx } from '@emotion/css';
import debounce from 'lodash-es/debounce';
import type { FC, KeyboardEventHandler, MouseEventHandler } from 'react';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';

import { Alignment, maxWidth } from '../../../constants';
import { MotifComponent, useMotifStyles } from '../../../motif';
import { dataSetToAttributes } from '../../../utils';
import { defaultIsUrlCurrent as getDefaultIsUrlCurrent } from '../../GlobalHeader/GlobalHeader.utils';
import { GlobalHeaderContext } from '../../GlobalHeader/GlobalHeaderContext';
import type { GlobalHeaderProps } from '../../GlobalHeader/types';
import { Icon } from '../../Icon';
import { PrimitivesContext } from '../../Primitives';
import { NavigatorItemFeaturedLink } from '../NavigatorItemFeaturedLink';
import { NavigatorItemLink } from '../NavigatorItemLink';
import { getColsWithDivider } from '../utils';
import {
  navigatorItemDesktopClickableCss,
  navigatorItemDesktopCss,
  navigatorItemDesktopIconCss,
  navigatorItemDesktopLeftAlignedCss,
  navigatorItemDesktopMenu4ColCss,
  navigatorItemDesktopMenuColCss,
  navigatorItemDesktopMenuColLabelCss,
  navigatorItemDesktopMenuColWithDividerCss,
  navigatorItemDesktopMenuCss,
  navigatorItemDesktopMenuExpandedCss,
  navigatorItemDesktopMenuGridCss,
  navigatorItemDesktopRightAlignedCss,
} from './NavigatorItemDesktop.styled';
import type { NavigatorItemDesktopProps } from './types';

type NavMenuAlignment = Extract<Alignment, 'Left' | 'Center' | 'Right'>;

/**
 * Implementation of `isUrlCurrent` if not specified by caller. Primarily for standalone script
 * usage. This implementation is a wrapper around the existing `defaultIsUrlCurrent` which can be
 * rewritten once we remove the deprecated `navigationBreadcrumbs` and prior Nav Menu
 * implementation.
 */
const defaultIsUrlCurrent: GlobalHeaderProps['isUrlCurrent'] = url => {
  const currentLocation = typeof window === 'undefined' ? undefined : window?.location;
  const isUrlCurrent = getDefaultIsUrlCurrent(currentLocation);
  return isUrlCurrent(url);
};

export const NavigatorItemDesktop: FC<NavigatorItemDesktopProps> = ({
  url,
  onClick,
  isSelected,
  title,
  dataset,
  analytics,
  col1Label,
  col1Items = [],
  col2Label,
  col2Items = [],
  col3Label,
  col3Items = [],
  featuredItem,
}) => {
  useMotifStyles(MotifComponent.HEADER);
  const { Anchor } = useContext(PrimitivesContext);
  const { isUrlCurrent } = useContext(GlobalHeaderContext);
  const [shouldSelect, setShouldSelect] = useState(isSelected ?? false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [navAlignment, setNavAlignment] = useState<NavMenuAlignment | undefined>();

  useEffect(() => {
    // If isSelected prop has a value, use it.
    if (isSelected !== undefined) {
      setShouldSelect(isSelected);
      return;
    }

    // otherwise use the `isUrlCurrent` function from context to determine selected state
    const urlsToCheck = [
      url,
      ...col1Items.map(item => item.url),
      ...col2Items.map(item => item.url),
      ...col3Items.map(item => item.url),
      featuredItem?.url,
    ];

    const isRelevantUrl = urlsToCheck.some(url => {
      if (!url) return false;

      // use caller specified `isUrlCurrent` function if provided
      if (isUrlCurrent) return isUrlCurrent(url);

      // use default implementation as fallback (primarily for standalone script usage)
      return defaultIsUrlCurrent(url);
    });

    setShouldSelect(isRelevantUrl);
  }, [isSelected, url, col1Items, col2Items, col3Items, featuredItem?.url, isUrlCurrent]);

  const parentRef = useRef<HTMLElement>(null);
  const menuRef = useRef<HTMLDivElement>(null);
  const iconName = isExpanded ? 'chevron-up' : 'chevron-down';

  /**
   * OnResize listener to control positioning of the Navigation Menu for the following cases:
   *
   * - Normal case: enforce consistent UX by centering the menu in device window
   * - Edge case: parent nav item is positioned to far left/right (e.g. ultra-wide screen), position
   *   menu below the parent so the user's mouse doesn't traverse a long distance to access the
   *   menu.
   */
  const positionNavMenu = useCallback(() => {
    if (typeof window === 'undefined') return;
    if (!parentRef.current || !menuRef.current) return;

    const deviceWidth = window.innerWidth;
    const parentLeft = parentRef.current.offsetLeft;
    const parentWidth = parentRef.current.offsetWidth;
    const parentRight = parentLeft + parentWidth;
    const menuWidth = menuRef.current.offsetWidth;

    // We will center align menu by default, using these calculations to determine if parent element overlaps center aligned menu.
    const centerAlignedMenuLeft = deviceWidth / 2 - menuWidth / 2;
    const centerAlignedMenuRight = deviceWidth / 2 + menuWidth / 2;

    // Only left/right align to parent if necessary
    const shouldRightAlign = deviceWidth > maxWidth && parentRight > centerAlignedMenuRight;
    const shouldLeftAlign = deviceWidth > maxWidth && parentLeft < centerAlignedMenuLeft;

    if (shouldRightAlign) {
      setNavAlignment(Alignment.Right);
    } else if (shouldLeftAlign) {
      setNavAlignment(Alignment.Left);
    } else {
      setNavAlignment(Alignment.Center);
    }
  }, []);

  /** Adds a onResize listener to reposition the Navigation Menu when necessary */
  useEffect(() => {
    const onResize = debounce(positionNavMenu, 500);

    window.addEventListener('resize', onResize);

    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, [positionNavMenu]);

  const clickableItemClassName = onClick || url ? navigatorItemDesktopClickableCss : null;
  const titleClassName = cx({ selected: shouldSelect });
  const linkOrText = <span className={titleClassName}>{title}</span>;

  const handleTriggerKeyPress: KeyboardEventHandler = e => {
    e.stopPropagation(); // Only move dropdown nav up/down

    if (e.key === ' ') {
      e.preventDefault(); // Prevent page down action when opening by keyboard
    }

    const focusableElements = menuRef.current?.querySelectorAll(
      'a, button, input, textarea, select, details, [tabindex]:not([tabindex="-1"])'
    );
    const firstElement = focusableElements?.[0] as HTMLElement;
    const lastElement = focusableElements?.[focusableElements.length - 1] as HTMLElement;

    // defer setting nav menu positioning till user interacts with the parent nav item
    if (!navAlignment) {
      positionNavMenu();
    }

    if (e.key === 'Enter' || e.key === ' ' || e.key === 'ArrowDown') {
      setIsExpanded(true);
      setTimeout(() => firstElement?.focus(), 250); // Wait for transition
    } else if (e.key === 'ArrowUp') {
      setIsExpanded(true);
      setTimeout(() => lastElement?.focus(), 250); // Wait for transition
    }
  };

  const handleMenuKeyPress: KeyboardEventHandler = e => {
    if (e.key === 'Tab') {
      const focusableElements = menuRef.current?.querySelectorAll(
        'a, button, input, textarea, select, details, [tabindex]:not([tabindex="-1"])'
      );
      const firstElement = focusableElements?.[0] as HTMLElement;
      const lastElement = focusableElements?.[focusableElements.length - 1] as HTMLElement;

      if (e.shiftKey) {
        // If tabbing forward, close menu if tabbing off of the last focussable item
        if (e.target === firstElement) {
          setIsExpanded(false);
        }
      } else {
        // If tabbing backward, close menu if tabbing off of the first focussable item
        if (e.target === lastElement) {
          setIsExpanded(false);
        }
      }
    }
  };

  const handleMouseEnter: MouseEventHandler = () => {
    // defer setting nav menu positioning till user interacts with the parent nav item
    if (!navAlignment) {
      positionNavMenu();
    }

    setIsExpanded(true);
  };

  const handleMouseLeave: MouseEventHandler = () => setIsExpanded(false);

  // Find the number of columns that have content
  const numOfColsWithItems = [
    col1Items?.length,
    col2Items?.length,
    col3Items?.length,
    featuredItem,
  ].filter(c => !!c).length;
  // fill remaining columns with empty space elements
  const numOfSpacers = 3 - numOfColsWithItems;

  // Only use trigger link (no dropdown menu) if there are no menu items
  if (!numOfColsWithItems) {
    return (
      <Anchor
        href={url}
        className={cx(MotifComponent.HEADER, navigatorItemDesktopCss, clickableItemClassName)}
        onClick={() => onClick?.(url, analytics?.label)}
        tabIndex={0}
        {...dataSetToAttributes(dataset)}
      >
        {linkOrText}
      </Anchor>
    );
  }

  // Check if any of the items, in any column, have a description
  const hasCol1Descriptions = col1Items?.some(item => !!item.description);
  const hasCol2Descriptions = col1Items?.some(item => !!item.description);
  const hasCol3Descriptions = col1Items?.some(item => !!item.description);

  const colsWithDivider = getColsWithDivider({
    col1Label,
    col1Items,
    col2Label,
    col2Items,
    col3Label,
    col3Items,
  });

  // Determine if a column has any icons, otherwise the space can be collapsed
  const showCol1Icons = col1Items?.some(item => item?.icon || item?.media);
  const showCol2Icons = col2Items?.some(item => item?.icon || item?.media);
  const showCol3Icons = col3Items?.some(item => item?.icon || item?.media);

  const handleItemLinkClick = (url: string, label?: string) => {
    setIsExpanded(false);
    onClick?.(url, label);
  };

  return (
    <section
      ref={parentRef}
      className={cx(
        {
          [navigatorItemDesktopRightAlignedCss]: navAlignment === Alignment.Right,
          [navigatorItemDesktopLeftAlignedCss]: navAlignment === Alignment.Left,
        },
        'sdsm-nav-item-desktop'
      )}
    >
      {/* Trigger element - no link if has dropdown items */}
      <Anchor
        className={cx(navigatorItemDesktopCss, clickableItemClassName)}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onKeyDown={handleTriggerKeyPress}
        tabIndex={0}
        {...dataSetToAttributes(dataset)}
      >
        {linkOrText}
        <Icon size={24} className={navigatorItemDesktopIconCss} name={iconName} />
      </Anchor>

      {/* Dropdown menu */}
      <div
        ref={menuRef}
        className={cx(
          navigatorItemDesktopMenuCss,
          {
            [navigatorItemDesktopMenuExpandedCss]: isExpanded,
            [navigatorItemDesktopMenu4ColCss]: numOfColsWithItems === 4,
          },
          'sdsm-nav-item-menu'
        )}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onKeyDown={handleMenuKeyPress}
        role="menu"
        aria-label="Menu"
      >
        <div className={navigatorItemDesktopMenuGridCss}>
          {/* Column 1 */}
          {!!col1Items?.length && (
            <div>
              <div
                className={cx(navigatorItemDesktopMenuColCss, {
                  [navigatorItemDesktopMenuColWithDividerCss]: colsWithDivider.includes(1),
                })}
              >
                {!!col1Label && (
                  <span className={navigatorItemDesktopMenuColLabelCss}>{col1Label}</span>
                )}
                {col1Items?.map(
                  ({ url, title, description, icon, media, analytics, dataset }, i) => (
                    <NavigatorItemLink
                      key={i}
                      url={url}
                      title={title}
                      description={description}
                      icon={showCol1Icons ? icon : undefined}
                      media={showCol1Icons ? media : undefined}
                      showSpacer={hasCol1Descriptions}
                      onClick={() => handleItemLinkClick(url, analytics?.label)}
                      dataset={isExpanded ? dataset : undefined}
                    />
                  )
                )}
              </div>
            </div>
          )}

          {/* Column 2 */}
          {!!col2Items?.length && (
            <div>
              <div
                className={cx(navigatorItemDesktopMenuColCss, {
                  [navigatorItemDesktopMenuColWithDividerCss]: colsWithDivider.includes(2),
                })}
              >
                {!!col2Label && (
                  <span className={navigatorItemDesktopMenuColLabelCss}>{col2Label}</span>
                )}
                {col2Items?.map(
                  ({ url, title, description, icon, media, analytics, dataset }, i) => (
                    <NavigatorItemLink
                      key={i}
                      url={url}
                      title={title}
                      description={description}
                      icon={showCol2Icons ? icon : undefined}
                      media={showCol2Icons ? media : undefined}
                      showSpacer={hasCol2Descriptions}
                      onClick={() => handleItemLinkClick(url, analytics?.label)}
                      dataset={isExpanded ? dataset : undefined}
                    />
                  )
                )}
              </div>
            </div>
          )}

          {/* Column 3 */}
          {!!col3Items?.length && (
            <div>
              <div
                className={cx(navigatorItemDesktopMenuColCss, {
                  [navigatorItemDesktopMenuColWithDividerCss]: colsWithDivider.includes(3),
                })}
              >
                {!!col3Label && (
                  <span className={navigatorItemDesktopMenuColLabelCss}>{col3Label}</span>
                )}
                {col3Items?.map(
                  ({ url, title, description, icon, media, analytics, dataset }, i) => (
                    <NavigatorItemLink
                      key={i}
                      url={url}
                      title={title}
                      description={description}
                      icon={showCol3Icons ? icon : undefined}
                      media={showCol3Icons ? media : undefined}
                      showSpacer={hasCol3Descriptions}
                      onClick={() => handleItemLinkClick(url, analytics?.label)}
                      dataset={isExpanded ? dataset : undefined}
                    />
                  )
                )}
              </div>
            </div>
          )}

          {/* Spacer columns */}
          {Array.from({ length: numOfSpacers }).map((_, i) => (
            <div key={i} />
          ))}

          {/* Featured item column */}
          {!!featuredItem && (
            <div>
              <div className={navigatorItemDesktopMenuColCss}>
                <NavigatorItemFeaturedLink
                  url={featuredItem.url}
                  title={featuredItem.title}
                  description={featuredItem.description}
                  icon={featuredItem.icon}
                  media={featuredItem.media}
                  ctaLabel={featuredItem.ctaLabel}
                  onClick={() =>
                    handleItemLinkClick(featuredItem.url, featuredItem.analytics?.label)
                  }
                  analytics={featuredItem.analytics}
                  dataset={isExpanded ? featuredItem.dataset : undefined}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

NavigatorItemDesktop.displayName = 'NavigatorItemDesktop';
