import type { FC, PropsWithChildren } from 'react';
import { memo, useContext } from 'react';
import { createPortal } from 'react-dom';

import { AppContext } from './AppContext';

// eslint-disable-next-line import/no-unused-modules
export const appPortalId = 'mwp-app-portal';

/**
 * Portal for rendering content into the Root of the App (right above the <Footer />).
 *
 * Use as return React.createPortal(<element>, appPortalId)
 */
export const AppPortal: FC<{ className?: string }> = memo(props => {
  const { appPortalRef } = useContext(AppContext);

  return <aside className={props.className} data-testid={appPortalId} ref={appPortalRef} />;
});

/**
 * Easy insertion point into the AppPortal.
 *
 * Use as <IntoAppPortal>{.. your stuff ..}</IntoAppPortal>
 *
 * NOTE: The contently only gets inserted on the client. Nothing displays on the server.
 */
export const IntoAppPortal: FC<PropsWithChildren> = ({ children }) => {
  const { appPortalRef } = useContext(AppContext);

  return appPortalRef?.current ? createPortal(children, appPortalRef!.current) : null;
};
