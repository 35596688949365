import { css } from '@emotion/css';

import { androidFastOutSlowInCurve } from '../../../constants/animation';
import { m } from '../../../motif/m';
import { MotifComponent } from '../../../motif/motifConstants';
import { globalHeaderAnimationDuration } from '../GlobalHeader.constants';

export const highlightTextClassName = 'highlight-text';
export const highlightTextTitleClassName = 'highlight-text-title';
export const highlightTextBodyClassName = 'highlight-text-body';
export const highlightMediaMobileClassName = 'highlight-media-mobile';
export const highlightMediaDesktopClassName = 'highlight-media-desktop';
export const highlightCtaClassName = 'cta';

const highlightMediaCss = (className: string) => css`
  box-sizing: border-box;

  /* Make the image/video cover the media space. These selectors are deep to account for any wrapper elements. */
  & > .${className} * {
    width: 100%;
    height: 100%;
  }
  & > .${className} img,
  & > .${className} video {
    object-fit: cover;
    border-radius: ${m('--border-radius-l')};
  }

  & > .${highlightMediaMobileClassName} img {
    width: 90%;
    margin: 0;
  }
`;

export const highlightDesktopCss = css`
  position: relative; /* For positioning the card */
  filter: drop-shadow(0px 0px 32px rgba(0, 0, 0, 0.12)); /* Faint highlight shadow */

  & > .${highlightMediaDesktopClassName} {
    margin: 0;
    width: 100%;
    height: 100%;
  }

  ${highlightMediaCss(highlightMediaDesktopClassName)}
`;

/**
 * Css for clamping multiple lines (counted in the vertical direction).
 *
 * Properties below work on Webkit AND Gecko. 96% browser support as of 2020-04. Note that vendor
 * auto-prefixing does not work here, so '-webkit-' is required.
 */
const lineClampCss = (lines: number): string => css`
  /* stylelint-disable-next-line */
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: ${lines};
`;

const highlightCardCss = css`
  box-sizing: border-box;
  color: ${m('--fg-color')};
  background-color: ${m('--bg-color')};

  padding: 0;
  border-radius: ${m('--border-radius-m')};
  box-shadow: ${m('--box-shadow-m')};
  border: ${m('--border-width-xs')} solid rgb(233, 234, 235);

  & > .${highlightTextClassName} {
    & > .${highlightTextTitleClassName} {
      color: ${m('--palette-black-v150')};

      font-weight: 500;
      font-size: 18px;
      line-height: 20px;
      white-space: nowrap;

      display: inline-block;

      max-width: 100%;

      overflow: hidden;
      text-overflow: ellipsis;

      /* The line height here is greater than in body, so we move it slightly so the top margin
         looks proportional to the card border. */
      position: relative;
      bottom: 1px;
    }

    & > .${highlightTextBodyClassName} {
      /* Default <p> sets margin to 1em which is larger than what we want. */
      margin-block-start: 2px;
      margin-block-end: 2px;

      font-size: 12px;
      line-height: 16px;
      font-weight: 400;

      color: ${m('--palette-black-v50')};

      ${lineClampCss(3)}
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
`;

const highlightCardOuterMargin = 65;
const highlightCtaMargin = 16;

const highlightCardWideDesktopCss = css`
  width: 35%;
  min-width: 500px;
  padding: 0 16px;

  *[dir='rtl'] & {
    right: unset;
    left: ${highlightCardOuterMargin}px;
  }

  .${highlightCtaClassName} {
    margin-left: 0;
  }

  *[dir='rtl'] & .${highlightCtaClassName} {
    margin-left: ${highlightCtaMargin}px;
    margin-right: 0;
  }
`;

const highlightCardNarrowDesktopCss = css`
  width: calc(100% - ${highlightCardOuterMargin * 2}px);

  &,
  & > .${highlightCtaClassName}, & > .${highlightCtaClassName} .${MotifComponent.BUTTON} {
    display: flex;
    flex-direction: column;
  }

  & > .${highlightCtaClassName} {
    margin-top: 0;
  }
`;

const highlightCardBreakDesktopWidth = 1040;
export const highlightCardDesktopCss = css`
  ${highlightCardCss}

  display: flex;
  align-items: center;

  & > .${highlightCtaClassName} {
    flex-grow: 0;
  }

  & > .${highlightTextClassName} {
    flex-grow: 1;
  }

  & > .${highlightTextClassName}, & > .${highlightCtaClassName} {
    margin: 16px;
  }

  position: absolute;
  right: ${highlightCardOuterMargin}px;
  bottom: 30px;

  @media screen and (min-width: ${highlightCardBreakDesktopWidth}px) {
    ${highlightCardWideDesktopCss}
  }

  @media screen and (max-width: ${highlightCardBreakDesktopWidth}px) {
    ${highlightCardNarrowDesktopCss}
  }
`;

export const highlightCardMobileCss = css`
  width: 100%;
  cursor: pointer;
  margin-top: 15px;
  margin-bottom: 50px;

  /**
   * NOTE: These values are important!
   * This first value is the height of the highlight.
   * The second is the height of the highlight description. The content is too tall
   * So we clip it after this size (1-line roughly)
   */
  display: grid;
  grid-template-rows: 40vw 20px;

  & > * {
    user-select: none;
  }

  & > .${highlightTextClassName} {
    font-weight: 400;
    font-size: 14px;
    line-height: 40px;
    margin: 0 12px;
  }

  & > .${highlightMediaMobileClassName} {
    border-radius: ${m('--border-radius-m')};
    margin: 0 20px 0 12px;
  }

  [dir='rtl'] & > .${highlightMediaMobileClassName} {
    margin: 0 12px 0 20px;
  }

  ${highlightMediaCss(highlightMediaMobileClassName)}
`;
/** This is the amount by which other slides pertrude, and half the margin between slides. */
const highlightSpace = 10;
export const reelDesktopCss = css`
  transition: transform ${globalHeaderAnimationDuration}ms ${androidFastOutSlowInCurve};
  transform: translateY(
    calc(((-100% + ${highlightSpace * 5}px) * var(--selected-index) + ${highlightSpace * 3}px))
  );
  height: 100%;

  margin: 0 32px 0 20px;

  *[dir='rtl'] & {
    margin: 0 20px 0 32px;
  }

  & > * {
    margin-top: 0;
    margin-bottom: ${highlightSpace * 2}px;
    height: calc(100% - ${highlightSpace * 7}px);
  }
`;
