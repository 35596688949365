import {
  type Motif,
  defaultMotif,
  FontFamily,
  Green,
  m,
  mergeMotifs,
  MotifComponent,
  MotifScheme,
} from '@snapchat/snap-design-system-marketing';

/** Generated by figmaTokensImport on 2023-10-30T22:41:44.824Z. */
export const customGreenMotif: Motif = mergeMotifs(defaultMotif, {
  name: 'Green',
  fontFamily: FontFamily.GRAPHIK,
  [MotifScheme.DEFAULT]: {
    name: 'Imported Deafult',
    legacyName: 'Yellow',
    [MotifComponent.ROOT]: {
      '--border-radius-l': '12px',
      '--border-radius-xl': '16px',
      '--bg-color': Green.V100,
      '--fg-color': '#042500',
    },
    [MotifComponent.BUTTON]: {
      '--button-primary-bg-color': '#042500',
      '--button-primary-hover-bg-color': '#042500',
      '--button-primary-border-color': '#042500',
      '--button-primary-hover-border-color': '#042500',
      '--button-primary-fg-color': '#f0fff0',
      '--button-secondary-bg-color': '#f0fff0',
      '--button-secondary-hover-bg-color': '#f0fff0',
      '--button-secondary-border-color': '#f0fff0',
      '--button-secondary-hover-border-color': '#f0fff0',
      '--button-secondary-fg-color': '#042500',
      '--button-flat-fg-color': `${m('--fg-color')}`,
      '--button-border-radius': `${m('--border-radius-m')}`,
      '--button-hover-shadow': `${m('--box-shadow-l')}`,
      '--button-regular-padding': 'var(--spacing-s) var(--spacing-xl)',
      '--button-compact-padding': 'var(--spacing-xs) var(--spacing-m)',
    },
    [MotifComponent.HEADER]: {},
    [MotifComponent.BLOCK]: {
      '--block-eyebrow-color': '#042500',
      '--block-title-color': '#042500',
      '--block-subtitle-color': '#042500',
    },
    [MotifComponent.BREAK]: {},
    [MotifComponent.ACCORDION]: {
      '--accordion-header-color': `${m('--fg-color')}`,
    },
    [MotifComponent.QUOTE]: {
      '--quote-bg-color': '#f0fff0',
      '--quote-fg-color': '#042500',
    },
    [MotifComponent.FOOTER]: {
      '--footer-bg-color': `${m('--bg-color')}`,
      '--footer-bar-bg-color': `${m('--bg-color')}`,
    },
    [MotifComponent.FORM]: {
      '--form-grid-gap': `${m('--spacing-m')}`,
    },
    [MotifComponent.FOOTNOTE]: {},
    [MotifComponent.DROPDOWN_MENU]: {
      '--dropdown-menu-bg-color': '#f0fff0',
      '--dropdown-item-fg-color': m('--neutral-v500'),
      '--dropdown-item-fg-active-color': '#042500',
      '--dropdown-item-bg-hover-color': '#c6dfc6',
    },
    [MotifComponent.BANNER]: {
      '--banner-bg-color': `${m('--bg-color')}`,
      '--banner-fg-color': `${m('--fg-color')}`,
    },
    [MotifComponent.HYPERLINK]: {
      '--hyperlink-color': m('--neutral-v600'),
    },
    [MotifComponent.MOSAIC]: {
      '--mosaic-border-radius': '24px',
      '--mosaic-grid-gap': '32px',
      '--mosaic-highlight-color': Green.V100,
      '--mosaic-title-color': '#f0fff0',
    },
    [MotifComponent.SEARCH]: {
      '--search-no-results-bg-color': '#c6dfc6',
    },
    [MotifComponent.PAGINATION]: {
      '--pagination-text-active-color': '#042500',
    },
    [MotifComponent.SPINNER]: {
      '--spinner-fg-color': `${m('--button-primary-fg-color')}`,
    },
    [MotifComponent.MODAL]: {
      '--modal-bg-color': 'rgba(0,0,0,0.7)',
      '--modal-close-bg-color': '#042500',
      '--modal-close-fg-color': '#f0fff0',
    },
    [MotifComponent.ICON_BUTTON]: {
      '--icon-button-bg-color': m('--neutral-v700'),
      '--icon-button-hover-bg-color': m('--neutral-v600'),
      '--icon-button-disabled-bg-color': 'rgba(0,0,0,0)',
    },
  },
  [MotifScheme.SECONDARY]: {
    name: 'Imported secondary',
    legacyName: 'Black',
    [MotifComponent.ROOT]: {
      '--box-shadow-s': '0px 4px 8px 0 rgba(0, 0, 0, 0.12)',
      '--box-shadow-m': '0px 8px 16px 0 rgba(0, 0, 0, 0.12)',
      '--border-radius-xs': '1px',
      '--border-radius-s': '4px',
      '--border-radius-m': '8px',
      '--border-radius-l': '12px',
      '--border-radius-xl': '16px',
      '--spacing-xxxs': '2px',
      '--spacing-xxs': '4px',
      '--spacing-xs': '8px',
      '--spacing-s': '12px',
      '--spacing-m': '16px',
      '--spacing-l': '24px',
      '--spacing-xl': '32px',
      '--spacing-xxl': '40px',
      '--spacing-xxxxl': '64px',
      '--bg-color': '#042500',
      '--fg-color': '#f0fff0',
    },
    [MotifComponent.BUTTON]: {
      '--button-primary-bg-color': Green.V100,
      '--button-primary-hover-bg-color': Green.V100,
      '--button-primary-border-color': Green.V100,
      '--button-primary-hover-border-color': Green.V100,
      '--button-primary-fg-color': '#042500',
      '--button-secondary-bg-color': '#f0fff0',
      '--button-secondary-hover-bg-color': '#f0fff0',
      '--button-secondary-border-color': '#f0fff0',
      '--button-secondary-hover-border-color': '#f0fff0',
      '--button-secondary-fg-color': '#042500',
      '--button-flat-fg-color': '#f0fff0',
      '--button-border-radius': `${m('--border-radius-m')}`,
      '--button-hover-shadow': `${m('--box-shadow-l')}`,
      '--button-regular-padding': 'var(--spacing-s) var(--spacing-xl)',
      '--button-compact-padding': 'var(--spacing-xs) var(--spacing-m)',
    },
    [MotifComponent.HEADER]: {
      '--global-header-fg-color': m('--neutral-v200'),
    },
    [MotifComponent.BLOCK]: {
      '--block-eyebrow-color': '#f0fff0',
      '--block-title-color': '#f0fff0',
      '--block-subtitle-color': '#f0fff0',
    },
    [MotifComponent.BREAK]: {},
    [MotifComponent.ACCORDION]: {
      '--accordion-header-color': `${m('--fg-color')}`,
    },
    [MotifComponent.QUOTE]: {
      '--quote-bg-color': '#f0fff0',
      '--quote-fg-color': '#042500',
    },
    [MotifComponent.FOOTER]: {
      '--footer-bg-color': `${m('--bg-color')}`,
      '--footer-bar-bg-color': `${m('--bg-color')}`,
      '--footer-divider-border-color': `${m('--fg-color')}`,
    },
    [MotifComponent.FORM]: {
      '--form-grid-gap': `${m('--spacing-m')}`,
    },
    [MotifComponent.FOOTNOTE]: {
      '--footnote-fg-color': m('--neutral-v400'),
      '--footnote-bg-color': m('--neutral-v0'),
    },
    [MotifComponent.DROPDOWN_MENU]: {
      '--dropdown-menu-bg-color': m('--neutral-v600'),
      '--dropdown-item-fg-color': m('--neutral-v300'),
      '--dropdown-item-fg-active-color': '#f0fff0',
    },
    [MotifComponent.BANNER]: {
      '--banner-bg-color': `${m('--bg-color')}`,
      '--banner-fg-color': `${m('--fg-color')}`,
    },
    [MotifComponent.HYPERLINK]: {
      '--hyperlink-color': m('--neutral-v250'),
    },
    [MotifComponent.MOSAIC]: {
      '--mosaic-border-radius': '24px',
      '--mosaic-grid-gap': '32px',
      '--mosaic-highlight-color': Green.V100,
      '--mosaic-title-color': '#f0fff0',
    },
    [MotifComponent.SEARCH]: {
      '--search-no-results-bg-color': '#c6dfc6',
      '--search-no-results-fg-color': m('--neutral-v650'),
    },
    [MotifComponent.PAGINATION]: {
      '--pagination-text-active-color': '#f0fff0',
      '--pagination-text-color': m('--neutral-v400'),
      '--pagination-text-hover-color': m('--neutral-v300'),
    },
    [MotifComponent.SPINNER]: {
      '--spinner-fg-color': `${m('--button-primary-fg-color')}`,
    },
    [MotifComponent.MODAL]: {
      '--modal-bg-color': 'rgba(0,0,0,0.7)',
      '--modal-close-bg-color': '#042500',
      '--modal-close-fg-color': '#f0fff0',
    },
    [MotifComponent.ICON_BUTTON]: {
      '--icon-button-hover-bg-color': m('--neutral-v200'),
      '--icon-button-disabled-bg-color': m('--neutral-v500'),
      '--icon-button-border-color': m('--neutral-v0'),
      '--icon-button-fg-color': m('--neutral-v700'),
      '--icon-button-disabled-fg-color': m('--neutral-v400'),
    },
  },
  [MotifScheme.TERTIARY]: {
    name: 'Imported tertiary',
    legacyName: 'White',
    [MotifComponent.ROOT]: {
      '--box-shadow-s': '0px 4px 8px 0 rgba(0, 0, 0, 0.12)',
      '--box-shadow-m': '0px 8px 16px 0 rgba(0, 0, 0, 0.12)',
      '--border-radius-xs': '1px',
      '--border-radius-s': '4px',
      '--border-radius-m': '8px',
      '--border-radius-l': '12px',
      '--border-radius-xl': '16px',
      '--spacing-xxxs': '2px',
      '--spacing-xxs': '4px',
      '--spacing-xs': '8px',
      '--spacing-s': '12px',
      '--spacing-m': '16px',
      '--spacing-l': '24px',
      '--spacing-xl': '32px',
      '--spacing-xxl': '40px',
      '--spacing-xxxxl': '64px',
      '--bg-color': '#f0fff0',
      '--fg-color': '#042500',
    },
    [MotifComponent.BUTTON]: {
      '--button-primary-bg-color': Green.V100,
      '--button-primary-hover-bg-color': Green.V100,
      '--button-primary-border-color': Green.V100,
      '--button-primary-hover-border-color': Green.V100,
      '--button-primary-fg-color': '#042500',
      '--button-secondary-bg-color': '#042500',
      '--button-secondary-hover-bg-color': '#042500',
      '--button-secondary-border-color': '#042500',
      '--button-secondary-hover-border-color': '#042500',
      '--button-secondary-fg-color': '#f0fff0',
      '--button-flat-fg-color': '#042500',
      '--button-border-radius': `${m('--border-radius-m')}`,
      '--button-hover-shadow': `${m('--box-shadow-l')}`,
      '--button-regular-padding': 'var(--spacing-s) var(--spacing-xl)',
      '--button-compact-padding': 'var(--spacing-xs) var(--spacing-m)',
    },
    [MotifComponent.HEADER]: {
      '--global-header-nav-screen-bg-color': '#f0fff0',
      '--global-header-nav-screen-global-links-bg-color': '#f0fff0',
      '--global-header-fg-color': m('--neutral-v650'),
    },
    [MotifComponent.BLOCK]: {
      '--block-eyebrow-color': '#042500',
      '--block-title-color': '#042500',
      '--block-subtitle-color': '#042500',
    },
    [MotifComponent.ACCORDION]: {
      '--accordion-header-color': `${m('--fg-color')}`,
    },
    [MotifComponent.QUOTE]: {
      '--quote-bg-color': '#f0fff0',
      '--quote-fg-color': '#042500',
    },
    [MotifComponent.FOOTER]: {
      '--footer-bg-color': `${m('--bg-color')}`,
      '--footer-bar-bg-color': '#c6dfc6',
    },
    [MotifComponent.FORM]: {
      '--form-grid-gap': `${m('--spacing-m')}`,
    },
    [MotifComponent.FOOTNOTE]: {
      '--footnote-fg-color': m('--neutral-v400'),
      '--footnote-bg-color': m('--neutral-v0'),
    },
    [MotifComponent.DROPDOWN_MENU]: {
      '--dropdown-menu-bg-color': '#f0fff0',
      '--dropdown-item-fg-color': m('--neutral-v500'),
      '--dropdown-item-fg-active-color': '#042500',
      '--dropdown-item-bg-hover-color': '#c6dfc6',
    },
    [MotifComponent.BANNER]: {
      '--banner-bg-color': `${m('--bg-color')}`,
      '--banner-fg-color': `${m('--fg-color')}`,
    },
    [MotifComponent.HYPERLINK]: {
      '--hyperlink-color': m('--neutral-v600'),
    },
    [MotifComponent.MOSAIC]: {
      '--mosaic-border-radius': '24px',
      '--mosaic-grid-gap': '32px',
      '--mosaic-highlight-color': Green.V100,
      '--mosaic-title-color': '#f0fff0',
    },
    [MotifComponent.SEARCH]: {
      '--search-no-results-bg-color': '#c6dfc6',
      '--search-no-results-fg-color': m('--neutral-v650'),
    },
    [MotifComponent.PAGINATION]: {
      '--pagination-text-active-color': '#042500',
    },
    [MotifComponent.SPINNER]: {
      '--spinner-fg-color': `${m('--button-primary-fg-color')}`,
    },
    [MotifComponent.MODAL]: {
      '--modal-bg-color': 'rgba(0,0,0,0.7)',
      '--modal-close-bg-color': '#042500',
      '--modal-close-fg-color': '#f0fff0',
    },
    [MotifComponent.ICON_BUTTON]: {
      '--icon-button-bg-color': m('--neutral-v700'),
      '--icon-button-hover-bg-color': m('--neutral-v600'),
      '--icon-button-disabled-bg-color': 'rgba(0,0,0,0)',
    },
  },
  [MotifScheme.QUATERNARY]: {
    name: 'Imported quaternary',
    legacyName: 'Gray',
    [MotifComponent.ROOT]: {
      '--box-shadow-s': '0px 4px 8px 0 rgba(0, 0, 0, 0.12)',
      '--box-shadow-m': '0px 8px 16px 0 rgba(0, 0, 0, 0.12)',
      '--border-radius-xs': '1px',
      '--border-radius-s': '4px',
      '--border-radius-m': '8px',
      '--border-radius-l': '12px',
      '--border-radius-xl': '16px',
      '--spacing-xxxs': '2px',
      '--spacing-xxs': '4px',
      '--spacing-xs': '8px',
      '--spacing-s': '12px',
      '--spacing-m': '16px',
      '--spacing-l': '24px',
      '--spacing-xl': '32px',
      '--spacing-xxl': '40px',
      '--spacing-xxxxl': '64px',
      '--bg-color': '#c6dfc6',
      '--fg-color': '#042500',
    },
    [MotifComponent.BUTTON]: {
      '--button-primary-bg-color': Green.V100,
      '--button-primary-hover-bg-color': Green.V100,
      '--button-primary-border-color': Green.V100,
      '--button-primary-hover-border-color': Green.V100,
      '--button-primary-fg-color': '#042500',
      '--button-secondary-bg-color': '#f0fff0',
      '--button-secondary-hover-bg-color': '#f0fff0',
      '--button-secondary-border-color': '#f0fff0',
      '--button-secondary-hover-border-color': '#f0fff0',
      '--button-secondary-fg-color': '#042500',
      '--button-flat-fg-color': '#042500',
      '--button-border-radius': `${m('--border-radius-m')}`,
      '--button-hover-shadow': `${m('--box-shadow-l')}`,
      '--button-regular-padding': 'var(--spacing-s) var(--spacing-xl)',
      '--button-compact-padding': 'var(--spacing-xs) var(--spacing-m)',
    },
    [MotifComponent.HEADER]: {
      '--global-header-nav-screen-bg-color': '#f0fff0',
      '--global-header-nav-screen-global-links-bg-color': '#f0fff0',
      '--global-header-fg-color': m('--neutral-v650'),
    },
    [MotifComponent.BLOCK]: {
      '--block-eyebrow-color': '#042500',
      '--block-title-color': '#042500',
      '--block-subtitle-color': '#042500',
    },
    [MotifComponent.BREAK]: {},
    [MotifComponent.ACCORDION]: {
      '--accordion-header-color': `${m('--fg-color')}`,
    },
    [MotifComponent.QUOTE]: {
      '--quote-bg-color': '#f0fff0',
      '--quote-fg-color': '#042500',
    },
    [MotifComponent.FOOTER]: {
      '--footer-bg-color': `${m('--bg-color')}`,
      '--footer-bar-bg-color': `${m('--bg-color')}`,
    },
    [MotifComponent.FORM]: {
      '--form-grid-gap': `${m('--spacing-m')}`,
    },
    [MotifComponent.FOOTNOTE]: {
      '--footnote-fg-color': m('--neutral-v400'),
      '--footnote-bg-color': m('--neutral-v0'),
    },
    [MotifComponent.DROPDOWN_MENU]: {
      '--dropdown-menu-bg-color': '#f0fff0',
      '--dropdown-item-fg-color': m('--neutral-v500'),
      '--dropdown-item-fg-active-color': '#042500',
      '--dropdown-item-bg-hover-color': '#c6dfc6',
    },
    [MotifComponent.BANNER]: {
      '--banner-bg-color': `${m('--bg-color')}`,
      '--banner-fg-color': `${m('--fg-color')}`,
    },
    [MotifComponent.HYPERLINK]: {
      '--hyperlink-color': m('--neutral-v600'),
    },
    [MotifComponent.MOSAIC]: {
      '--mosaic-border-radius': '24px',
      '--mosaic-grid-gap': '32px',
      '--mosaic-highlight-color': Green.V100,
      '--mosaic-title-color': '#f0fff0',
    },
    [MotifComponent.SEARCH]: {
      '--search-no-results-bg-color': '#c6dfc6',
      '--search-no-results-fg-color': m('--neutral-v650'),
    },
    [MotifComponent.PAGINATION]: {
      '--pagination-text-active-color': '#042500',
    },
    [MotifComponent.SPINNER]: {
      '--spinner-fg-color': `${m('--button-primary-fg-color')}`,
    },
    [MotifComponent.MODAL]: {
      '--modal-bg-color': 'rgba(0,0,0,0.7)',
      '--modal-close-bg-color': '#042500',
      '--modal-close-fg-color': '#f0fff0',
    },
    [MotifComponent.ICON_BUTTON]: {
      '--icon-button-bg-color': m('--neutral-v700'),
      '--icon-button-hover-bg-color': m('--neutral-v600'),
      '--icon-button-disabled-bg-color': 'rgba(0,0,0,0)',
    },
  },
});
