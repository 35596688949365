import { cx } from '@emotion/css';
import type { ReactEventHandler } from 'react';
import { forwardRef, useContext, useEffect, useRef } from 'react';

import { BrowserFeaturesContext } from '../../BrowserFeatures';
import type { BaseComponentProps } from '../../types';
import { dataSetToAttributes } from '../../utils';
import { combineRefs } from '../../utils/useCombinesRefs';
import { TabsContext } from '../Tabs/TabsContext';
import { getObjectFit, videoCss } from './styles';
import { isVideoPlaying } from './utils';

export interface VideoProps extends BaseComponentProps {
  source: string;
  mobileSource?: string;
  sourceType?: string;
  mobileSourceType?: string;
  posterSource?: string;
  altText?: string;
  muted?: boolean;
  autoPlay?: boolean;
  loop?: boolean;
  controls?: boolean;
  playsInline?: boolean;
  onPlay?: ReactEventHandler<HTMLVideoElement>;
  captionsSource?: string;
  isBackgroundVideo?: boolean;
  onTimeUpdate?: ReactEventHandler<HTMLVideoElement>;
}

export const Video = forwardRef<HTMLElement, VideoProps>((props, ref) => {
  const {
    source,
    mobileSource,
    sourceType = 'video/mp4',
    mobileSourceType = 'video/mp4',
    posterSource,
    altText,
    className,
    style,
    muted = true,
    autoPlay = true,
    loop = true,
    controls = false,
    playsInline = true,
    onPlay,
    captionsSource,
    isBackgroundVideo,
    onTimeUpdate,
    dataset,
  } = props;

  const videoRef = useRef<HTMLVideoElement>(null);
  const { getLowEntropyHints } = useContext(BrowserFeaturesContext);
  const clientHints = getLowEntropyHints();

  useEffect(() => {
    const currentTrack = videoRef.current?.querySelector('track');

    if (videoRef.current && captionsSource && !currentTrack) {
      const track = document.createElement('track');
      track.setAttribute('src', captionsSource);
      track.setAttribute('kind', 'captions');
      track.setAttribute('srclang', 'en');
      track.setAttribute('label', 'English');
      videoRef.current.appendChild(track);
    }
  }, [captionsSource]);

  const { addOnTabChangeListener, removeOnTabChangeListener } = useContext(TabsContext);

  // Handle when tabs have changed to pause videos
  useEffect(() => {
    // only pause videos that have sound / autoplay
    if (!videoRef.current || muted || autoPlay) {
      return;
    }

    const onTabChange = () => {
      if (isVideoPlaying(videoRef.current || undefined)) {
        videoRef.current?.pause();
      }
    };
    addOnTabChangeListener?.(onTabChange);
    return () => removeOnTabChangeListener?.(onTabChange);
  }, [addOnTabChangeListener, removeOnTabChangeListener, videoRef, muted, autoPlay]);

  const objectFitStyle = getObjectFit(isBackgroundVideo);

  // Preload determines how much of the video to load.
  // 'none' - don't load anything
  // 'auto' - load the whole video
  // 'metadata' - load only the necessary metadata to display the video (thumbnail, video duration)
  const preload = autoPlay ? 'auto' : posterSource ? 'none' : 'metadata';
  const isSafari = clientHints.browsers.find(browser => browser.brand === 'Safari');

  // This is a workaround for Safari not loading the first frame to use as the thumbnail image.
  // This sets the video starting position to 0.01 seconds into the video, which forces a frame to load
  // See: https://stackoverflow.com/questions/56428378/no-way-to-preload-first-video-frame-on-ios-safari
  const videoSource = isSafari && preload === 'metadata' ? `${source}#t=0.01` : source;
  const mobileVideoSource =
    mobileSource && isSafari && preload === 'metadata' ? `${mobileSource}#t=0.01` : mobileSource;

  return (
    <video
      className={cx('video', videoCss, objectFitStyle, className)}
      style={style}
      poster={posterSource}
      autoPlay={autoPlay}
      loop={loop}
      muted={muted}
      controls={controls}
      playsInline={playsInline}
      onPlay={onPlay}
      preload={preload}
      // See: https://www.w3.org/WAI/PF/HTML/wiki/Media_Alt_Technologies
      aria-describedby={altText}
      ref={combineRefs(videoRef, ref)}
      // See: https://stackoverflow.com/questions/55645393/issue-with-loading-vtt-from-cross-domain
      crossOrigin="anonymous"
      key={source}
      onTimeUpdate={onTimeUpdate}
      {...dataSetToAttributes(dataset)}
    >
      {mobileVideoSource && (
        <source src={mobileSource} type={mobileSourceType} media="(max-width: 768px)" />
      )}
      <source src={videoSource} type={sourceType} />
    </video>
  );
});

Video.displayName = 'Video';
