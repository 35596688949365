import type { FC, ReactNode } from 'react';
import { useContext } from 'react';

import type { FormProps } from './Form';
import { FormStateType } from './Form.types';
import { FormContext } from './FormContext';
import { errorCodeCss, errorDebugSectionCss, errorMessageCss } from './styles';

type FormErrorMessageProps = Pick<
  FormProps,
  | 'on400ResponseMessage'
  | 'on500ResponseMessage'
  | 'renderErrorMessage'
  | 'onInvalidClientSideSubmissionMessage'
  | 'renderErrorDetails'
>;

export const FormErrorMessage: FC<FormErrorMessageProps> = ({
  renderErrorMessage,
  on400ResponseMessage,
  on500ResponseMessage,
  onInvalidClientSideSubmissionMessage,
  renderErrorDetails,
}) => {
  const { state } = useContext(FormContext);
  const { errorStatusCode, error, type } = state;

  if (type === FormStateType.INVALID) {
    return <div className={errorMessageCss}>{onInvalidClientSideSubmissionMessage}</div>;
  }

  if (!error) return null;

  let errorDisplayText: ReactNode;
  if (errorStatusCode === undefined) {
    errorDisplayText = null;
  } else if (renderErrorMessage) {
    errorDisplayText = renderErrorMessage?.(errorStatusCode);
  } else {
    errorDisplayText = errorStatusCode === 400 ? on400ResponseMessage : on500ResponseMessage;
  }

  const errorObject = JSON.stringify(
    {
      name: error.name,
      message: error.message,
      callStack: error.stack,
    },
    null,
    2
  );

  return (
    <>
      <div className={errorMessageCss}>{errorDisplayText}</div>
      {renderErrorDetails && (
        <div className={errorDebugSectionCss}>
          <h6>Error Object</h6>
          <code className={errorCodeCss}>{errorObject}</code>
        </div>
      )}
    </>
  );
};
