import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import {
  Bold,
  CollapseTableMobile,
  H1,
  H2,
  H3,
  H4,
  H5,
  H6,
  HorizontalRule,
  Italics,
  ListItem,
  NoCollapseTableMobile,
  OrderedList,
  Paragraph,
  PrimitiveTableCell as TableCell,
  PrimitiveTableHeader as TableHeader,
  PrimitiveTableRow as TableRow,
  Quote as BlockQuote,
  Underline,
  UnorderedList,
} from '@snapchat/snap-design-system-marketing';
import type { FC, ReactNode } from 'react';

import { Hyperlink } from '../../components/Hyperlink';
import type { RichText } from '../../types/RichText';
import {
  NoHeadingH1,
  NoHeadingH2,
  NoHeadingH3,
  NoHeadingH4,
  NoHeadingH5,
  NoHeadingH6,
} from './NoHeadingRichTextComponents';
import { createRichTextRenderer } from './renderText';
import { RichTextDocument, RichTextNoPaddingParagraph } from './RichTextComponents';
import { renderMultiLineText } from './textUtils';

export const RichTextWithElementsComponents = {
  Document: RichTextDocument,
  Text: renderMultiLineText,
  Bold,
  Italics,
  Underline,

  H1,
  H2,
  H3,
  H4,
  H5,
  H6,

  Paragraph,
  Hyperlink,
  ListItem,
  UnorderedList,
  OrderedList,
  Table: NoCollapseTableMobile,
  TableCell,
  TableHeader,
  TableRow,
  HorizontalRule,
  BlockQuote: BlockQuote as FC,
};

/**
 * Renders wich text that has NO EMBEDDED ASSETS or ENTRIES.
 *
 * If you need to render embedded entries, see 'renderText.ts'.
 *
 * This uses standard HTML elements like <b>, <hr>, <sup> etc.
 *
 * Going forward, we should be using this for most RichText needs.
 */
export const renderRichText = (data?: RichText | string): ReactNode => {
  if (!data) {
    return undefined;
  }

  if (typeof data === 'string') {
    return data;
  }

  return documentToReactComponents(data.json /* useDefaultOptions */);
};

/**
 * Renders rich text as plain text only wrapping around markings like underline / italics and bold.
 *
 * This is useful when we want to render rich text but not honor the <p> tag to avoid extra spacing.
 */
export const renderRichTextMarkingsOnly = createRichTextRenderer({
  components: {
    Bold,
    Italics,
    Underline,
  },
});

/**
 * Same as {@link renderRichTextMarkingsOnly} except that it inserts <br /> where new-line characters
 * are so whitespaces ends up being rendered.
 */
export const renderRichTextMultiLineWithMarkings = createRichTextRenderer({
  components: {
    // To insert <br /> between new lines
    Text: renderMultiLineText,
    Bold,
    Italics,
    Underline,
    // To wrap each node in <p>, but without margins since we don't expect
    // these lines of text to actually be paragraphs but rather multi-line titles/subtitles
    Paragraph: RichTextNoPaddingParagraph,
  },
});

/**
 * Render RichText components using basic SDSM primitives.
 *
 * This one is probably worse when it comes to rendering basic tags but includes the embedded
 * entries like CTAs and Media.
 */
export const renderRichTextWithElements = createRichTextRenderer({
  components: RichTextWithElementsComponents,
});

/**
 * RichText renderer that uses SDSM primives but does not use h1-h6 tags.
 *
 * These exist to not interfere with screenreaders and SEO where the order of h1-h6 and their
 * position matters.
 */
export const renderRichTextWithElementsNoHeadings = createRichTextRenderer({
  components: {
    ...RichTextWithElementsComponents,
    H1: NoHeadingH1,
    H2: NoHeadingH2,
    H3: NoHeadingH3,
    H4: NoHeadingH4,
    H5: NoHeadingH5,
    H6: NoHeadingH6,
  },
});

/**
 * RichText renderer that uses SDSM primives, useHeadingTagsForBody is used to conditionally use or
 * not h1-h6 tags, collapseMobileTable is used to render the primitive table with a set prop
 */
export const getRichTextWithEmbeddingsRenderer = (
  useHeadingTagsForBody = false,
  collapseMobileTable = false
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
) => {
  const headings = useHeadingTagsForBody
    ? {
        H1,
        H2,
        H3,
        H4,
        H5,
        H6,
      }
    : {
        H1: NoHeadingH1,
        H2: NoHeadingH2,
        H3: NoHeadingH3,
        H4: NoHeadingH4,
        H5: NoHeadingH5,
        H6: NoHeadingH6,
      };

  const Table = collapseMobileTable ? CollapseTableMobile : NoCollapseTableMobile;

  return createRichTextRenderer({
    components: {
      ...RichTextWithElementsComponents,
      ...headings,
      Table,
    },
  });
};
