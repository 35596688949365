import { m } from '../../motif/m';
import type { MotifComponent } from '../../motif/motifConstants';
import type { MotifComponentProps } from '../../motif/motifTypes';

export const mosaicLightBgVars: MotifComponentProps<MotifComponent.MOSAIC> = {
  '--mosaic-border-radius': m('--border-radius-m'),
  '--mosaic-grid-gap': m('--spacing-l'),
  '--mosaic-title-color': m('--neutral-v0'),
  '--mosaic-highlight-color': m('--primary-v100'),
  '--mosaic-duration-color': m('--neutral-v0'),
};

export const mosaicDarkBgVars: MotifComponentProps<MotifComponent.MOSAIC> = {
  '--mosaic-border-radius': m('--border-radius-m'),
  '--mosaic-grid-gap': m('--spacing-l'),
  '--mosaic-title-color': m('--neutral-v0'),
  '--mosaic-highlight-color': m('--primary-v100'),
  '--mosaic-duration-color': m('--neutral-v0'),
};
