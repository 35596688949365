import { cx } from '@emotion/css';
import type { CSSProperties, ReactEventHandler, ReactNode } from 'react';
import { forwardRef, useContext, useRef } from 'react';

import { BrowserFeaturesContext } from '../../BrowserFeatures/BrowserFeaturesContextProvider';
import { Device } from '../../constants';
import { MotifComponent, useMotifStyles } from '../../motif';
import type { BaseComponentProps, ImageSources } from '../../types';
import { combineRefs } from '../../utils/useCombinesRefs';
import { Picture } from '../Picture';
import { Video } from '../Video';
import { LaptopWrapper } from './LaptopWrapper';
import {
  divStyleCss,
  macSafariCss,
  noWrapBackgroundVideoCss,
  noWrapImageCss,
  noWrapVideoCss,
  phoneContainerCss,
  phoneVideoCss,
  shadowContainerCss,
  wrappedBackgroundVideoCss,
  wrappedImageCss,
  wrappedVideoCss,
} from './Media.styled';
import { PhoneWrapper } from './PhoneWrapper';

export interface MediaProps extends BaseComponentProps {
  /** @deprecated Use imgSrcs instead */
  imageSource?: string;

  /** @deprecated. Does nothing. Apply styles using className. */
  marginBottom?: unknown;

  altText?: string;
  maxHeight?: number;
  maxWidth?: number;
  height?: number;
  width?: number;
  videoSource?: string;
  mobileVideoSource?: string;
  sourceType?: string;
  wrap?: Device;
  className?: string;

  // TODO: Consider expanding this into multiple parameters since it controls
  // auto play behavior and others.
  showVideoControls?: boolean;
  autoplay?: boolean;

  posterSource?: string;
  onPlay?: ReactEventHandler<HTMLVideoElement>;
  captionsSource?: string;
  isBackgroundVideo?: boolean;
  onTimeUpdate?: ReactEventHandler<HTMLVideoElement>;
  imgSrcs?: ImageSources;
  isDraggable?: boolean;
}

const mediaWrapperClassName = 'sdsm-media-wrapper';

export const Media = forwardRef<HTMLElement, MediaProps>((props, ref) => {
  const {
    className,
    altText,
    imageSource,
    maxHeight,
    maxWidth,
    videoSource,
    mobileVideoSource,
    sourceType,
    wrap = Device.None,
    // Don't expect this to work unless you're passing a video
    showVideoControls = false,
    autoplay = !showVideoControls,
    posterSource,
    onPlay,
    captionsSource,
    isBackgroundVideo,
    onTimeUpdate,
    imgSrcs,
    dataset,
    height,
    width,
    isDraggable,
  } = props;

  useMotifStyles(MotifComponent.MEDIA);

  let unwrapped: ReactNode = null;
  const { getLowEntropyHints } = useContext(BrowserFeaturesContext);
  const mediaRef = useRef<HTMLElement>(null);
  const clientHints = getLowEntropyHints();
  const isMacSafari = !!(
    clientHints.platform === 'macOS' &&
    clientHints.browsers.find(browser => browser.brand === 'Safari')
  );
  const applyMacSafariStyles = isMacSafari && wrap === Device.Phone;

  const isDevice = wrap === Device.Laptop || wrap === Device.Phone;

  const sizeStyles: CSSProperties = {
    maxHeight: maxHeight && !isDevice ? `${maxHeight}px` : undefined,
    maxWidth: maxWidth && !isDevice ? `${maxWidth}px` : undefined,
  };

  if (imageSource || imgSrcs) {
    const imgClassName = cx(
      {
        [wrappedImageCss]: isDevice,
        [noWrapImageCss]: !isDevice,

        [phoneContainerCss]: wrap === Device.Phone,
        [shadowContainerCss]: wrap === Device.Shadow,
      },
      // Comes last to do overrides.
      className
    );

    if (imgSrcs) {
      unwrapped = (
        <Picture
          className={MotifComponent.MEDIA}
          imgSrcs={imgSrcs}
          altText={altText}
          imgClassName={imgClassName}
          style={sizeStyles}
          dataset={dataset}
          height={height}
          width={width}
          isDraggable={isDraggable}
        />
      );
    } else if (imageSource) {
      // if the image will not be wrapped inside a media wrapper, use the height
      // here. Otherwise, the wrapper will implement the height
      unwrapped = (
        <Picture
          className={MotifComponent.MEDIA}
          altText={altText}
          imgClassName={imgClassName}
          style={sizeStyles}
          defaultSrc={imageSource}
          dataset={dataset}
          height={height}
          width={width}
          isDraggable={isDraggable}
        />
      );
    }
  } else if (videoSource) {
    const wrappedVideo2Css = isBackgroundVideo ? wrappedBackgroundVideoCss : wrappedVideoCss;
    const noWrapVideo2Css = isBackgroundVideo ? noWrapBackgroundVideoCss : noWrapVideoCss;
    const videoClassName = cx(
      {
        [phoneVideoCss]: wrap === Device.Phone,
        [wrappedVideo2Css]: isDevice,
        [macSafariCss]: wrap === Device.Phone && applyMacSafariStyles,
        [noWrapVideo2Css]: !isDevice,
        [shadowContainerCss]: wrap === Device.Shadow,
      },
      className
    );

    unwrapped = (
      <Video
        key={videoSource}
        source={videoSource}
        mobileSource={mobileVideoSource}
        sourceType={sourceType}
        altText={altText}
        className={cx(MotifComponent.MEDIA, videoClassName)}
        style={sizeStyles}
        autoPlay={autoplay}
        playsInline={!showVideoControls}
        loop={!showVideoControls}
        muted={!showVideoControls}
        controls={showVideoControls}
        posterSource={posterSource}
        onPlay={onPlay}
        captionsSource={captionsSource}
        onTimeUpdate={onTimeUpdate}
        isBackgroundVideo={isBackgroundVideo}
        dataset={dataset}
        ref={combineRefs(mediaRef, ref)}
      />
    );

    if (applyMacSafariStyles) {
      unwrapped = (
        <div data-testid="sdsm-media-safari-wrapper" className={divStyleCss}>
          {unwrapped}
        </div>
      );
    }
  } else {
    // early escape in case there is no media
    return null;
  }

  if (wrap === Device.Laptop) {
    return (
      <LaptopWrapper className={mediaWrapperClassName} maxHeight={maxHeight}>
        {unwrapped}
      </LaptopWrapper>
    );
  }

  if (wrap === Device.Phone) {
    return (
      <PhoneWrapper className={mediaWrapperClassName} maxHeight={maxHeight}>
        {unwrapped}
      </PhoneWrapper>
    );
  }

  return (
    // TODO: Delete this nonsense div. It serves no purpose.
    <div className={mediaWrapperClassName} data-testid="no-device-wrapper">
      {unwrapped}
    </div>
  );
});

Media.displayName = 'Media';
