import { cx } from '@emotion/css';
import type { FC, PropsWithChildren } from 'react';
import { useContext, useEffect, useRef } from 'react';

import { dataSetToAttributes } from '../../utils';
import { PrimitivesContext } from '../Primitives';
import { itemCss, itemLinkActiveCss, itemLinkCss } from './styles';
import type { SubNavigationItemProps } from './types';

export const SubNavigationItem: FC<PropsWithChildren<SubNavigationItemProps>> = ({
  href,
  isActive,
  onLinkClick,
  className,
  children,
  dataset,
}) => {
  const { Anchor } = useContext(PrimitivesContext);
  const ref = useRef<HTMLLIElement>(null);

  // Scroll active item to the center
  useEffect(() => {
    if (isActive) {
      ref.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'center',
      });
    }
  }, [isActive]);

  return (
    <li ref={ref} className={cx(itemCss, className)} {...dataSetToAttributes(dataset)}>
      <Anchor
        className={cx(itemLinkCss, { [itemLinkActiveCss]: isActive })}
        href={href}
        onClick={onLinkClick}
      >
        {children}
      </Anchor>
    </li>
  );
};

SubNavigationItem.displayName = 'SubNavigationItem';
