import { cx } from '@emotion/css';
import type { FC } from 'react';
import { useEffect, useState } from 'react';

import { MediaMode } from '../../constants';
import { useMediaMode } from '../../hooks/useMediaMode';
import { ToggleState, ToggleTarget, useToggleState } from '../../hooks/useToggleState';
import { MotifComponent } from '../../motif/motifConstants';
import { useMotifStyles } from '../../motif/motifReactUtils';
import { getBackgroundClassName } from '../../utils';
import { globalHeaderTransitionDuration, testIds } from './GlobalHeader.constants';
import { GlobalHeaderContext } from './GlobalHeaderContext';
import { GlobalHeaderDesktop } from './GlobalHeaderDesktop';
import { GlobalHeaderMobile } from './GlobalHeaderMobile';
import type { GlobalHeaderProps } from './types';

/** Synthetic group key for the local nav as displayed on the mobile nav screen. */
export const defaultGlobalNavMobileGroupKey = 'home';

// npm run test -- --testPathPattern=GlobalHeader
export const GlobalHeader: FC<GlobalHeaderProps> = ({
  backgroundColor,
  className,
  defaultGroupKey,
  children,
  displayed,
  onToggleExpanded,
  stayOpenInvariant,
  trackingSiteName,
  showNavScreen = true,
  dataset,
  isUrlCurrent,
  buttonAriaLabel,
  ...otherHeaderProps
}) => {
  useMotifStyles(MotifComponent.HEADER);

  const mode = useMediaMode();

  const { state: isExpandedState, toggle: toggleExpanded } = useToggleState({
    transitionDurationMs: globalHeaderTransitionDuration,
    onToggle: (newState, _oldState) => {
      if (onToggleExpanded) {
        // Immediately show screen turning on begins.
        if (newState === ToggleState.TURNING_ON) {
          onToggleExpanded(true);
        }

        // Hide the screen when turning off animation finishes.
        if (newState === ToggleState.OFF) {
          onToggleExpanded(false);
        }
      }

      // Lose focus on the toggle button in case it was triggered via keyboard.
      const activeElement = document?.activeElement as HTMLElement;
      activeElement?.blur();
    },
  });

  // Close the open nav if the open-invariant deviates or if the mobile/desktop mode changes.
  // Note that we not want to trigger this when `toggleExpanded` changes.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => toggleExpanded(ToggleTarget.OFF), [mode, stayOpenInvariant]);

  const [groupKey, setGroupKey] = useState<string>(
    mode === MediaMode.Mobile ? defaultGlobalNavMobileGroupKey : defaultGroupKey ?? ''
  );

  const isVisible = displayed ?? true;
  const classNameWithTheme = cx(
    MotifComponent.HEADER,
    backgroundColor ? getBackgroundClassName(backgroundColor) : null,
    isVisible ? null : 'hidden',
    className
  );

  if (!dataset?.testid) {
    dataset = { ...dataset, testid: testIds.headerTopLevel } as DOMStringMap;
  }

  const isExpanded = isExpandedState !== ToggleState.OFF;
  const GlobalHeaderImpl = mode === MediaMode.Mobile ? GlobalHeaderMobile : GlobalHeaderDesktop;
  return (
    <GlobalHeaderContext.Provider
      value={{
        mode,
        groupKey,
        setGroupKey,
        toggleExpanded,
        screenState: isExpandedState,
        trackingSiteName,
        isUrlCurrent,
      }}
    >
      <GlobalHeaderImpl
        {...otherHeaderProps}
        isExpanded={isExpanded}
        toggleExpanded={toggleExpanded}
        className={classNameWithTheme}
        showNavScreen={showNavScreen}
        buttonAriaLabel={buttonAriaLabel}
        dataset={dataset}
      />
      {isExpanded && showNavScreen && children}
    </GlobalHeaderContext.Provider>
  );
};

GlobalHeader.displayName = 'GlobalHeader';
