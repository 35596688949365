import type { MotifComponent, MotifComponentProps } from '../../../motif';
import { m } from '../../../motif/m';

export const chartSkeletonLightVars: MotifComponentProps<MotifComponent.CHART_SKELETON> = {
  '--chart-skeleton-bg-color': m('--neutral-v100'),
  '--chart-skeleton-mask-color': m('--neutral-v200'),
  '--chart-skeleton-dark-animation-color': m('--neutral-v200'),
  '--chart-skeleton-light-animation-color': m('--neutral-v0'),
};

export const chartSkeletonDarkVars: MotifComponentProps<MotifComponent.CHART_SKELETON> = {
  '--chart-skeleton-bg-color': m('--neutral-v625'),
  '--chart-skeleton-mask-color': m('--neutral-v500'),
  '--chart-skeleton-dark-animation-color': m('--neutral-v500'),
  '--chart-skeleton-light-animation-color': m('--neutral-v400'),
};
