import { css } from '@emotion/css';

import { m } from '../../../motif';

export const controlsCss = css`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 20px;
  gap: 8px;
`;

export const filtersCss = css`
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
  flex-wrap: wrap;
  max-width: 100%;
  gap: 8px;
  button:first-child {
    margin-left: 0;
  }
`;

export const tooltipCss = css`
  background-color: ${m('--palette-black-v200')};
  padding: 0px 10px;
  color: ${m('--palette-plain-white')};
  border-radius: 4px;
  display: flex;
  flex-direction: column;
`;

export const tooltipFlagCss = css`
  font-size: 25px;
  margin-top: 5px;
`;

export const tooltipAmountCss = css`
  font-size: 18px;
  margin: 0 0 0 5px;
  *[dir='rtl'] & {
    margin: 0 5px 0 0;
  }
`;

export const tooltipLabelCss = css`
  font-size: 12px;
  margin-bottom: 10px;
`;

export const rowCss = css`
  display: flex;
  align-items: center;
`;

export const mobileTooltipCss = css`
  margin-top: 2rem;
`;
