import { useContext } from 'react';

import { AppContext } from '../../AppContext';
import { Config } from '../../config';
import { SitewideConfigurationContext } from '../../context/SitewideConfiguration/SitewideConfigurationContext';
import type { FeatureFlags } from './featureFlagTypes';
import { Feature } from './featureFlagTypes';

/** Default values for the feature flags. */
export const defaultFeatureFlags: FeatureFlags = {
  [Feature.TEST_FEATURE]: 'false',
  [Feature.HOME_REDIRECT]: 'false',
  [Feature.DISABLE_LAZY_LOAD_LOGGING]: 'false',
  [Feature.SCROLL_TO_ANCHOR]: 'true',
  [Feature.EXPERIENCE_ENABLE_ASL_STREAM]: 'true',
  [Feature.EXPERIENCE_ENABLE_CAPTIONS]: 'true',
  [Feature.SPS_SHOW_GO_TO_STREAM_BUTTON]: 'false',
  [Feature.SPS_USE_EVENT_PLAYER]: 'false',
  [Feature.ENABLE_PIXY_EUROPEAN_STORES]: 'false',
  [Feature.COOKIE_BASED_BUCKETING]: 'false',
  [Feature.ENABLE_DEVICE_SPECIFIC_MEDIA]: 'false',
  [Feature.GLOBAL_NAV_ITEMS_POSITION]: 'right',
  [Feature.USE_CHART_DROPDOWN_TOGGLE]: 'false',
  [Feature.CAREERS_ENABLE_JOB_DETAIL_PAGE]: 'false',
  [Feature.AVALON_ENABLE_CHAT_WIDGET]: 'false',
  [Feature.ENABLE_SITE_SEARCH]: 'false',
  [Feature.USE_CONTENT_ACCORDION_ITEMS]: 'true',
  [Feature.ENABLE_HLS_PLAYBACK]: 'false',
  [Feature.USE_SNAP_MOTIF]: 'false',
} as const;

/**
 * Returns the value of FeatureFlags for the current request.
 *
 * Takes into consideration site settings and contentful overrides.
 */
export function useFeatureFlags(): FeatureFlags {
  // Reads URL overrides (non-prod only).
  const { getCurrentUrl } = useContext(AppContext);
  const searchParams = new URL(getCurrentUrl()).searchParams;
  const { sitewideValues } = useContext(SitewideConfigurationContext);

  const featureFlagsEntry = sitewideValues?.featureFlags;
  const contentfulFeatureFlags = featureFlagsEntry?.featureFlags as FeatureFlags | undefined;

  return combineFeatureFlags(contentfulFeatureFlags, getUrlFeatureFlags(searchParams));
}

/**
 * Function to combine all feature flags in proper overrided ordering.
 *
 * @param contentfulFeatureFlags Flags sourced from contentful
 * @param urlOverrides Flags sourced from the url parameters
 * @returns
 */
export function combineFeatureFlags(
  contentfulFeatureFlags?: FeatureFlags,
  urlOverrides?: Partial<FeatureFlags>
): FeatureFlags {
  return {
    // First copy defaults.
    ...defaultFeatureFlags,
    // Then use site-specific settings.
    ...Config.featureFlags,
    // Thie use contentful overrides.
    ...contentfulFeatureFlags,
    // Lastly, apply URL overrides.
    ...urlOverrides,
  };
}

/** Get the feature flags stored in a urls search parameters */
export function getUrlFeatureFlags(searchParams: URLSearchParams): Partial<FeatureFlags> {
  const urlOverrides: Partial<FeatureFlags> = {};

  if (!Config.isDeploymentTypeProd) {
    for (const feature of Object.keys(defaultFeatureFlags)) {
      const override = searchParams.get(feature);
      if (!override) continue;
      urlOverrides[feature as Feature] = override;
    }
  }

  return urlOverrides;
}
