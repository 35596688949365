import { css } from '@emotion/css';
import { m, Spacing, White } from '@snapchat/snap-design-system-marketing';

import { mediaQuery } from '../../styles/mediaQueries';

export const cartWrapperStyles = (hasItems: boolean): string => css`
  background-color: ${m('--palette-plain-white')};
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.06);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: ${hasItems ? 'normal' : 'center'};
  height: 100%;
  overflow-y: auto;
  pointer-events: none;
  position: fixed;
  right: -400px;
  top: 0;
  transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1);
  z-index: 100;
  width: 375px;
  text-align: center;
`;

export const activeWrapperCss = css`
  opacity: 1;
  pointer-events: all;
  right: 0;
  left: 0;
  width: 100%;

  ${mediaQuery.over768_desktop_small} {
    left: unset;
    width: 400px;
  }
`;

export const cartOverlayCss = css`
  background-color: ${White}cc;
  position: fixed;
  opacity: 0;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1);
  display: none;

  ${mediaQuery.over768_desktop_small} {
    display: unset;
  }
`;

export const cartOverlayActiveCss = css`
  opacity: 1;
  right: 400px;
`;

export const cartInternalCss = css`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const cartCloseCss = css`
  color: ${m('--palette-black-v200')};
  cursor: pointer;
  background: transparent;
  border-radius: 50px;
  padding-top: ${Spacing.MD}px;
  padding-right: ${Spacing.MD}px;
`;

export const cartCloseButtonCss = css`
  font-weight: bold;
`;

export const cartItemWrapperCss = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 ${Spacing.XS}px;
`;

export const cartItemImg = (imageUrl: string): string => css`
  background: url(${imageUrl}) no-repeat center center;
  background-size: contain;
  width: 200px;
  height: 200px;
`;

export const buttonWrapperCss = css`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  height: ${Spacing['2XL']}px;
`;

export const quantityCss = css`
  color: ${m('--palette-plain-white')};
  width: ${Spacing['2XL']}px;
  height: ${Spacing['2XL']}px;
  background-color: ${m('--palette-black-v200')};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  margin: 20px 5px;
`;

export const allAccessoriesCss = css`
  width: 100%;
  max-width: 400px;
  padding: 0 ${Spacing['2XL']}px;
`;

export const accessoryWrapperCss = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid ${m('--palette-gray-v150')};
  margin: ${Spacing.MD}px 0;
  padding: 0 ${Spacing.MD}px;
`;

export const accessoryQuantityCss = css`
  width: ${Spacing['2XL']}px;
  height: ${Spacing['2XL']}px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px ${Spacing.MD}px;
`;

export const quantityWrapperCss = css`
  display: flex;
  align-items: center;
`;

export const accessoryNameAndPriceCss = css`
  padding: ${Spacing.SM}px 0;
  text-align: left;
`;

export const accessoryTitleCss = css`
  margin-bottom: ${Spacing['2XS']}px;
  font-weight: 500;
`;

export const quantityModificationCss = css`
  background: ${m('--palette-plain-white')};
  border: 1px solid ${m('--palette-gray-v200')};
  color: ${m('--palette-black-v200')};
  cursor: pointer;
  font-size: 12px;
  width: ${Spacing.XL}px;
  height: ${Spacing.XL}px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  margin: 0 ${Spacing['2XS']}px;
  transition: border 0.05s ease-in-out;

  &:hover {
    border: 2px solid ${m('--palette-yellow-v100')};
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.14);
  }
`;

export const quantityModificationDisabledCss = css`
  background: ${m('--palette-gray-v100')};
  border: unset;
  color: ${m('--palette-gray-v300')};
  cursor: default;
  pointer-events: none;
`;

export const accessoryQuantityModificationCss = css`
  background: ${m('--palette-plain-white')};
  color: ${m('--palette-black-v200')};
  cursor: pointer;
  font-size: 12px;
`;

export const accessoryQuantityModificationDisabledCss = css`
  color: ${m('--palette-gray-v300')};
  pointer-events: none;
`;

export const titleCss = css`
  margin: ${Spacing.MD}px 0;
  font-size: 30px;
`;

export const atpCss = css`
  color: ${m('--palette-gray-v300')};
`;

export const priceCss = css`
  font-size: 24px;
`;

export const totalCss = css`
  margin-top: ${Spacing['2XL']}px;
  font-size: 24px;
  text-align: center;
`;

export const checkoutButtonCss = css`
  button.sdsm-button {
    background-color: transparent;
  }

  margin-top: ${Spacing.MD}px;
  padding: 0 ${Spacing.MD}px;
`;

export const checkoutDisclaimerCss = css`
  color: ${m('--palette-gray-v300')};
  margin-top: ${Spacing.XL}px;
  padding-bottom: ${Spacing['2XL']}px;
`;

export const noCartItemsContainerCss = css`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const noCartItemsTitleCss = css`
  text-align: center;
  font-size: 24px;
`;

export const featuredProductImg = (imageUrl: string): string => css`
  background: url(${imageUrl}) no-repeat center center;
  background-size: contain;
  width: 200px;
  height: 200px;
`;

export const featuredProductTitleCss = css`
  text-align: center;
  font-size: 30px;
`;

export const featuredProductPriceCss = css`
  margin-top: ${Spacing.MD}px;
  font-size: 24px;
`;

export const featuredProductButtonCss = css`
  margin-top: ${Spacing.MD}px;
`;

export const cartIconButtonCss = css`
  background: transparent;
  color: ${m('--palette-black-v200')};
  cursor: pointer;
  font-size: 22px;
  display: flex;
  align-items: center;
  position: relative;
  text-decoration: none;
`;

export const cartIconQuantityCss = css`
  background-color: ${m('--palette-yellow-v100')};
  border-radius: 50%;
  position: absolute;
  font-size: 16px;
  font-weight: 400;
  top: -14px;
  left: 13px;
  text-align: center;
  padding: ${Spacing.SM}px;
  width: 8px;
  height: 8px;
  display: flex;
  align-items: center;
  justify-content: center;

  ${mediaQuery.over768_desktop_small} {
    top: -18px;
    left: 15px;
  }
`;

export const cartIconCss = css`
  margin: 0 -2.5px;
`;
