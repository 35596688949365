import { m } from '../../../motif/m';

export const chartToggleBlackBgVars = {
  '--chart-toggle-buttons-bg-color': m('--neutral-v0'),
  '--chart-toggle-buttons-color': m('--neutral-v500'),
  '--chart-toggle-buttons-border-color': m('--neutral-v500'),
  '--chart-toggle-buttons-active-bg-color': m('--primary-v100'),
  '--chart-toggle-buttons-active-color': m('--neutral-v700'),
  '--chart-toggle-buttons-active-border-color': m('--primary-v100'),
};

export const chartToggleYellowBgVars = {
  '--chart-toggle-buttons-bg-color': m('--neutral-v0'),
  '--chart-toggle-buttons-color': m('--neutral-v500'),
  '--chart-toggle-buttons-border-color': m('--neutral-v500'),
  '--chart-toggle-buttons-active-bg-color': m('--neutral-v700'),
  '--chart-toggle-buttons-active-color': m('--neutral-v0'),
  '--chart-toggle-buttons-active-border-color': m('--neutral-v700'),
};

export const chartToggleWhiteBgVars = {
  '--chart-toggle-buttons-bg-color': m('--neutral-v0'),
  '--chart-toggle-buttons-color': m('--neutral-v500'),
  '--chart-toggle-buttons-border-color': m('--neutral-v500'),
  '--chart-toggle-buttons-active-bg-color': m('--primary-v100'),
  '--chart-toggle-buttons-active-color': m('--neutral-v700'),
  '--chart-toggle-buttons-active-border-color': m('--primary-v100'),
};

export const chartToggleGrayBgVars = {
  '--chart-toggle-buttons-bg-color': m('--neutral-v0'),
  '--chart-toggle-buttons-color': m('--neutral-v500'),
  '--chart-toggle-buttons-border-color': m('--neutral-v500'),
  '--chart-toggle-buttons-active-bg-color': m('--neutral-v700'),
  '--chart-toggle-buttons-active-color': m('--neutral-v0'),
  '--chart-toggle-buttons-active-border-color': m('--neutral-v700'),
};
