import type { SrcSetSizes } from '@snapchat/mw-contentful-client';
import { useContentfulImages } from '@snapchat/mw-contentful-client';
import type { CarouselV3AspectRatios } from '@snapchat/snap-design-system-marketing';
import {
  CarouselCardItem as CarouselCardItemSDS,
  mobileMaxWidth,
} from '@snapchat/snap-design-system-marketing';
import { type FC, useContext } from 'react';

import { shouldFlushLogs } from '../../helpers/getLocalPath';
import { logger } from '../../helpers/logging';
import { UserAction } from '../../types/events';
import { combineImageSources } from '../../utils/combineImageSources';
import { parseMedia } from '../../utils/media';
import { ConsumerContext } from '../ConsumerContextProvider';
import { imageQuality } from '../Image';
import type { ImageDataProps } from '../Image/types';
import type { CarouselV3CardItemProps } from './types';

const getPortraitCardImgSrcSetSizes = (quality: number): SrcSetSizes => ({
  sizeToUrl: [
    {
      size: '600w',
      settings: { width: 600, quality },
    },
  ],
  sizes: '600px',
});

const cardWidths = [622, 728];
const cardWidthsStr = cardWidths.map(cw => `${cw}px`).join(', ');

const getLandscapeCardImgSrcSetSizes = (quality: number): SrcSetSizes => ({
  sizeToUrl: cardWidths.map(width => ({
    size: `${width}w`,
    settings: { width, quality },
  })),
  sizes: `(max-width: ${mobileMaxWidth}px) ${cardWidthsStr}`,
});

export const CarouselV3ImageItem: FC<{
  cardItem: CarouselV3CardItemProps;
  mediaAspectRatio: CarouselV3AspectRatios;
  isVisible?: boolean;
  shouldLoad?: boolean;
  className?: string;
}> = ({ cardItem, mediaAspectRatio, isVisible, shouldLoad, ...otherProps }) => {
  const imageMedia = cardItem.media as ImageDataProps;

  const { imageSource, imageAltText } = parseMedia(imageMedia.media);
  const { imageSource: mobileImageSource } = parseMedia(imageMedia.mobileMedia);
  const { getImageSources } = useContentfulImages();

  const { logEvent } = useContext(ConsumerContext);

  const quality = imageQuality[imageMedia.quality ?? 'Standard'];
  const imgSettings =
    mediaAspectRatio === '9:16'
      ? getPortraitCardImgSrcSetSizes(quality)
      : getLandscapeCardImgSrcSetSizes(quality);

  const imageSrcSettings = { size: imgSettings, quality };

  const imgSrcs = combineImageSources({
    desktop: getImageSources(imageSource, imageSrcSettings),
    /* TODO(kpan2): These aren't good for mobile images. */
    mobile: getImageSources(mobileImageSource, imageSrcSettings),
  });

  const slug = cardItem.slugReference?.slug;
  const url = slug ?? cardItem.url;

  const onClick = (): void => {
    if (!url) {
      return;
    }

    logEvent?.({
      type: UserAction.Click,
      component: 'CarouselV3',
      label: `CarouselV3.ImageItem - ${url}`,
      url,
    });

    if (shouldFlushLogs(url)) {
      void logger.flush();
    }
  };

  return (
    <CarouselCardItemSDS
      aspectRatio={mediaAspectRatio}
      body={cardItem.body}
      key={cardItem.sys.id}
      subtitle={cardItem.subtitle}
      title={cardItem.title}
      url={url}
      imgSrcs={imgSrcs}
      imgAltText={imageAltText}
      isVisible={isVisible}
      shouldLoad={shouldLoad}
      onClick={onClick}
      {...otherProps}
    />
  );
};

CarouselV3ImageItem.displayName = 'CarouselV3ImageItem';
