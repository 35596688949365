import {
  BackgroundColor,
  Block as BlockSDS,
  TabItems as TabItemsSDS,
} from '@snapchat/snap-design-system-marketing';
import { type FC, useCallback, useState } from 'react';

import { logUserEvent } from '../../helpers/logging';
import { UserAction } from '../../types/events';
import { renderRichTextMultiLineWithMarkings } from '../../utils/renderText/renderRichText';
import { MultiValuePropBlock } from '../MultiValuePropBlock';
import type { BlockTabsDataProps } from './types';

export const BlockTabs: FC<BlockTabsDataProps> = props => {
  const {
    title,
    subtitle,
    titleAlignment,
    titleAlignmentMobile,
    backgroundColor = BackgroundColor.White,
    fullHeight = false,
    tabsCollection,
  } = props;

  const [selectedTab, setSelectedTab] = useState(0);

  const onSelectTab = (index: number) => {
    logUserEvent({
      eventAction: UserAction.Click,
      eventCategory: 'Block Tab',
      eventLabel: `tab:${index}`,
    });
  };

  const handleSelectTab = useCallback(
    (index: number) => {
      setSelectedTab(index);
      onSelectTab(index);
    },
    [setSelectedTab, onSelectTab]
  );

  // Only MultiValuePropBlock is supported at the time
  const tabItems = tabsCollection?.items
    ? tabsCollection.items
        .filter(blockTab => blockTab.block.__typename === 'MultiValuePropBlock')
        .map((blockTab, index) => {
          return {
            text: blockTab.tabText,
            content: [
              <MultiValuePropBlock
                key={blockTab.sys.id}
                {...blockTab.block}
                fullHeight={fullHeight}
                autoplayCarousel={selectedTab === index ? blockTab.block.autoplayCarousel : false}
                backgroundColor={backgroundColor}
                style={{
                  display: selectedTab !== index ? 'none' : 'flex',
                }}
              />,
            ],
            maxColumns: 1,
          };
        })
    : [];

  return (
    <>
      <BlockSDS
        backgroundColor={backgroundColor}
        title={renderRichTextMultiLineWithMarkings(title)}
        subtitle={renderRichTextMultiLineWithMarkings(subtitle)}
        titleAlignment={titleAlignment}
        titleAlignmentMobile={titleAlignmentMobile}
      >
        {tabItems.length >= 2 && (
          <TabItemsSDS items={tabItems} selectTab={handleSelectTab} selectedTab={selectedTab} />
        )}
      </BlockSDS>
      {tabItems.map(tabItem => tabItem.content)}
    </>
  );
};
