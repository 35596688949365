/**
 * Checks if the destination URL matches with the current url as in window.location. This is the
 * default function for checking if a navigation item should be highlighted in the breadcrumb.
 */

export function defaultIsUrlCurrent(location?: Location): (url: string) => boolean {
  return (destinationUrl: string): boolean => {
    if (!location) {
      return false;
    }

    try {
      const base = `${location.protocol}//${location.hostname}`;
      const destUrl = new URL(destinationUrl, base);

      const currentUrlHost = location.hostname;
      const currentUrlPath = location.pathname;

      return currentUrlHost === destUrl.hostname && currentUrlPath === destUrl.pathname;
    } catch (_error) {
      return false;
    }
  };
}
