import gql from 'graphql-tag';

import { contentfulSysIdFragment } from '../../utils/contentful/contentfulQuery';
import { multiValuePropBlockFragment } from '../MultiValuePropBlock/query';

export const blockTabsFragment = gql`
  fragment BlockTabsAll on BlockTabs {
    ...ContentfulSysId
    tabsCollection {
      items {
        ...ContentfulSysId
        tabText
        block {
          ... on MultiValuePropBlock {
            ...MultiValuePropBlockAll
          }
        }
      }
    }
    title {
      json
    }
    subtitle {
      json
    }
    titleAlignment
    titleAlignmentMobile
    backgroundColor
    fullHeight
  }
  ${contentfulSysIdFragment}
  ${multiValuePropBlockFragment}
`;

export const blockTabsQuery = gql`
  query BlockTabs($preview: Boolean!, $locale: String!, $id: String!) {
    blockTabs(preview: $preview, locale: $locale, id: $id) {
      ...BlockTabsAll
    }
  }
  ${blockTabsFragment}
`;
