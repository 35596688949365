import { css, cx } from '@emotion/css';
import { useImperativeEffect } from '@snapchat/core-browser';
import { useContentfulImages } from '@snapchat/mw-contentful-client';
import type { Banner as BannerType, PageBlocksItem } from '@snapchat/mw-contentful-schema';
// TODO: Move this to common/client.
import type { BackgroundColor, ImageSources } from '@snapchat/snap-design-system-marketing';
import {
  Banner,
  bannerMinHeight,
  Break as BreakSDS,
  BreakOverlayType,
  BreakSpacer as BreakSpacerSDS,
  ButtonType,
  FootnoteBlock,
  footnoteBlockBackgroundColor,
  getBackgroundClassName,
  getBreakOverlayType,
  Header as HeaderSDS,
  MessageContext,
  MotifScheme,
  Page as PageSDS,
  pageTransparentBgCss,
  PictureContext,
  Template,
  useIsMobile,
} from '@snapchat/snap-design-system-marketing';
import type { CSSProperties, FC, ReactElement, ReactNode } from 'react';
import { cloneElement, Fragment, useContext, useEffect, useMemo, useRef } from 'react';
import { useLocation } from 'react-router-dom';

import { Config } from '../../config';
import { FooterView, HeaderView, PageLayoutContext } from '../../context/PageLayoutContext';
import { LayoutContext } from '../../contexts/LayoutContext';
import { SubscribedEventType } from '../../helpers/logging/eventListenerTypes';
import { logEvent } from '../../helpers/logging/loggingInstance';
import { useParseMediaContainer } from '../../hooks/useParseMediaContainer';
import type { RichText } from '../../types/RichText';
import { combineImageSources } from '../../utils/combineImageSources';
import { getContentfulInspectorProps } from '../../utils/contentful/getContentfulInspectorProps';
import { direction } from '../../utils/direction';
import { renderRichTextWithElements } from '../../utils/renderText/renderRichText';
import { AlternateLang } from '../AlternateLang/AlternateLang';
import type { BlockDataProps } from '../Block';
import { Block } from '../Block';
import { BlockTabs } from '../BlockTabs';
import type { BreakBackgroundColor } from '../Break';
import { CallToAction } from '../CallToAction';
import { Canonical } from '../Canonical/Canonical';
import { getCustomComponents } from '../customComponents';
import { DefinitionOverlay, DefinitionProvider } from '../Definition';
import { Feature, useFeatureFlags } from '../FeatureFlags';
import { Footnote } from '../Footnote';
import { totalHeaderHeightCssVar } from '../Header/headerSizeUtils';
import { Hero } from '../Hero';
import { LatestPostHero } from '../Hero/LatestPostHero';
import { HlsInitializer } from '../HlsInitializer/HlsInitializer';
import { getImageSourceSettings } from '../Image';
import { LatestPostBlock } from '../LatestPostBlock';
import { Metas } from '../Metas';
import { MultiValuePropBlock } from '../MultiValuePropBlock';
import { MultiVideoBlock } from '../MultiVideoBlock';
import { ScrollAnimatedSection } from '../ScrollAnimatedSection';
import { minHeightCss } from '../Slug/Slug.styles';
import { SlugContext } from '../Slug/SlugContext';
import { SplitBlock } from '../SplitBlock/SplitBlock';
import { SubNavigation } from '../SubNavigation';
import { ActiveEventCountTracker } from '../tracing/ActiveEventCountTracker';
import { WebSchemas } from '../WebSchema';
import {
  bannerContentContainerCss,
  defaultPageBackgroundCss,
  mobileCtaCss,
  pageSideNavCss,
  pageStickyCss,
  stickyBottomCss,
} from './Page.styles';
import { PageBottomStickyPortal } from './PageBottomStickyPortal';
import type { PageDataBlock, PageDataBlockWithBackgroundColor } from './pageQuery';
import { pageStickyHeightCssVar } from './pageStickyUtils';
import type { PageDataProps } from './types';
const defaultBreakTemplates: Template[] = [
  Template.Straight,
  Template.Skirt,
  Template.Straight,
  Template.HeadFlipped,
  Template.Straight,
  Template.Head,
];

const pageCss = css`
  min-height: calc(100vh - var(${totalHeaderHeightCssVar}));
`;

// TODO: Use a variable from SDS-M when it exposes this as a const.
const subNavHeightPx = 60;

const pageWithSubNavCss = css`
  min-height: calc(100vh - calc(var(${totalHeaderHeightCssVar}) + ${subNavHeightPx}px));
`;

// We declare these as constants so they are the same object across renders to prevent
// excessive rerendering of things that rely on the context.
const pictureContextLazy = { lazy: true };
const pictureContextNoLazy = { lazy: false };

type BlockType = PageBlocksItem['__typename'] | 'Unknown';

const allBlockTypes: BlockType[] = [
  'Break',
  'SubNavigation',
  'BlockHero',
  'LatestPosts',
  'MultiVideoBlock',
  'Block',
  'SplitBlock',
  'MultiValuePropBlock',
  'BlockTabs',
];

const isCustomComponent = (type: BlockType) => !allBlockTypes.includes(type);

// biome-ignore lint/complexity/noExcessiveCognitiveComplexity: TODO: Refactor to reduce complexity.
export const Page: FC<PageDataProps> = props => {
  const { pathname } = useLocation();
  const featureFlags = useFeatureFlags();
  const { formatMessage } = useContext(MessageContext);
  const { hasSideNav, hasSubNav } = useContext(LayoutContext);
  const isMobile = useIsMobile();

  const stickyContainerRef = useRef<HTMLDivElement>(null);
  const pageRef = useRef<HTMLDivElement>(null);

  // Unfortunately, we have to do this check here because on forbusiness, we use the same banner slot
  // to render the mobile CTA, but the mobile CTA doesn't use the sticky container
  // TODO: Rethink how this is done. Current this is done here because SSR needs to have a value... but does it really?
  const stickyHeightRef = useRef<number>(
    props.banner && props.banner.__typename === 'Banner' ? bannerMinHeight : 0
  );

  // TODO: Extract as a hook.
  const resizeObserver = useMemo(() => {
    // attach resize observer on client
    if (typeof window !== 'undefined') {
      return new ResizeObserver(entries => {
        const { height } = entries[0]!.contentRect;

        if (stickyHeightRef.current !== height) {
          stickyHeightRef.current = height;

          pageRef.current?.style.setProperty(pageStickyHeightCssVar, `${height}px`);
        }
      });
    }

    return undefined;
    // we disable here because we only need it to run once on mount (on ssr and on client)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (stickyContainerRef.current && resizeObserver) {
      resizeObserver.observe(stickyContainerRef.current, { box: 'border-box' });

      return () => {
        resizeObserver.disconnect();
      };
    }

    return;
  }, [resizeObserver]);

  useImperativeEffect(() => {
    // Note we log the page load event here, because at this point the page experiment information is available.
    logEvent({
      subscribedEventType: SubscribedEventType.PAGE_LOAD,
    });
  }, [pathname]);

  // NOTE: Since these useEffect calls can modify renders above this component,
  // we need to ensure they are always run (and not gated by an if statement).
  const {
    headerView: currentHeaderView,
    footerView: currentFooterView,
    setHeaderView,
    setFooterView,
  } = useContext(PageLayoutContext);
  const headerView = props.headerView ?? HeaderView.FULL_HEADER;
  const footerView = props.footerView ?? FooterView.FULL_FOOTER;

  // Hack to mark the session header/footer with the correct values on the
  // server-side only. Since useEffect doesn't run on the server, we need to
  // set the value somehow.
  // One of the issues here is that this only works if there are async promises
  // in the first render pass that forces the server re-render everything. In
  // those cases the server will pick up the correct values on the second pass.
  // So, if for some reason, the server hasn't re-rendered (because all async
  // data is already in the cache), then the server will have the wrong values.
  if (!Config.isClient) {
    setHeaderView?.(headerView);
    setFooterView?.(footerView);
  }

  useEffect(() => {
    if (headerView === currentHeaderView) return;
    setHeaderView?.(headerView);
  }, [headerView, currentHeaderView, setHeaderView]);

  useEffect(() => {
    if (footerView === currentFooterView) return;
    setFooterView?.(footerView);
  }, [footerView, currentFooterView, setFooterView]);

  const slugContext = useContext(SlugContext);

  const {
    title,
    metas,
    blocksCollection,
    footnotesCollection,
    collapseFootnotes,
    backgroundColor,
    backgroundMediaV2,
    backgroundMediaStyle,
    scrollSnap,
    mobileCta,
  } = props;

  // This value is loaded here for testing.
  // TODO: Add regression tests to very this value is set correctly.
  const testFeature = featureFlags[Feature.TEST_FEATURE];

  const pageBackgroundColor = backgroundColor ?? Config.theme?.defaultPageBackgroundColor;

  // =================================================================================================
  // Footnotes
  // =================================================================================================
  // TODO: This should probably be OL and children should be LI
  const footnotes = footnotesCollection?.items?.map(footnoteProps => (
    <Footnote key={footnoteProps.sys.id} {...footnoteProps} />
  ));

  const footnotesTitle = formatMessage({ id: 'footnotesTitle', defaultMessage: '' });

  // Don't pass in a footnoteBlock if there are no footnotes
  const footnoteBlock = footnotes?.length ? (
    <FootnoteBlock title={footnotesTitle} isOpenInitially={!collapseFootnotes}>
      {footnotes}
    </FootnoteBlock>
  ) : undefined;

  // =================================================================================================
  // All blocks from CMS
  // =================================================================================================
  // TODO: This should be using PageBlocksItem
  const blocks: PageDataBlock[] = [];
  blocks.push(...(blocksCollection?.items ?? []));

  // =================================================================================================
  // Convert blocks data to components and auto-break insertion
  // =================================================================================================
  const breakTemplates = Config.theme?.breakTemplates ?? defaultBreakTemplates;
  let templateIndex = 0;
  let firstHeroOrBlockIndex = -1;

  const blocksWithBreaks: { data: PageDataBlock; component: ReactElement }[] = [];

  blocks.forEach((props, idx) => {
    const { sys, __typename } = props;

    const isLastBlock = idx === blocks.length - 1;

    const prevBlock = blocks?.[idx - 1];
    const nextBlock = blocks?.[idx + 1];

    const blockBackgroundColor = (props as PageDataBlockWithBackgroundColor)?.backgroundColor;
    const prevBackgroundColor = (prevBlock as PageDataBlockWithBackgroundColor)?.backgroundColor;
    const nextBackgroundColor = (nextBlock as PageDataBlockWithBackgroundColor)?.backgroundColor;

    const blockHasBackgroundMedia = !!(props as BlockDataProps)?.backgroundMediaV2;
    const prevBlockHasBackgroundMedia = !!(prevBlock as BlockDataProps)?.backgroundMediaV2;
    const nextBlockHasBackgroundMedia = !!(nextBlock as BlockDataProps)?.backgroundMediaV2;

    // get index of first hero or block (basically anything thats not subnav)
    // to guestimate above the fold
    if (firstHeroOrBlockIndex === -1 && __typename !== 'SubNavigation') {
      firstHeroOrBlockIndex = idx;
    }

    // Create component based on block type
    switch (__typename) {
      case 'Break': {
        // TODO: Extract this out into a mwp/components/Break.
        if (props.type !== 'None') {
          const topColorFallback: BreakBackgroundColor =
            prevBlockHasBackgroundMedia || !prevBackgroundColor
              ? 'Transparent'
              : prevBackgroundColor;

          let bottomColorFallback: BreakBackgroundColor;

          if (isLastBlock && !!footnoteBlock) {
            bottomColorFallback = footnoteBlockBackgroundColor;
          } else {
            bottomColorFallback =
              nextBlockHasBackgroundMedia || !nextBackgroundColor
                ? 'Transparent'
                : nextBackgroundColor;
          }

          // Use the user-selected color if there is one, otherwise use the fallback color
          const topColor = props.topColor ?? topColorFallback;
          const bottomColor = props.bottomColor ?? bottomColorFallback;

          // If user-selected color is 'Transparent' then use a corresponding transparent option
          // from 'BackgroundColor' type which the 'Break' component expects as a prop
          const isTopTransparent = topColor === 'Transparent';
          const isBottomTransparent = bottomColor === 'Transparent';
          const topColorClean = isTopTransparent ? undefined : (topColor as BackgroundColor);
          const bottomColorClean = isBottomTransparent
            ? undefined
            : (bottomColor as BackgroundColor);

          blocksWithBreaks.push({
            data: props,
            component: (
              <BreakSDS
                key={props.sys.id}
                template={Template[props.type]}
                topColor={topColorClean}
                bottomColor={bottomColorClean}
                isOverlaid={props.isOverlaid && !isLastBlock}
              />
            ),
          });
        }
        break;
      }

      case 'ScrollAnimatedSection': {
        if (isMobile) {
          const mobileContent = props.mobileContentCollection?.items ?? [];

          for (const blockProps of mobileContent) {
            const blockPropsAsDataProps = blockProps as BlockDataProps;

            blocksWithBreaks.push({
              data: props,
              component: (
                <Block
                  key={sys.id}
                  {...blockPropsAsDataProps}
                  isPreviousSameBackgroundColor={
                    blockPropsAsDataProps.backgroundColor === prevBackgroundColor
                  }
                  isNextSameBackgroundColor={
                    blockPropsAsDataProps.backgroundColor === nextBackgroundColor
                  }
                  fullHeight={scrollSnap || blockPropsAsDataProps.fullHeight}
                />
              ),
            });
          }
          break;
        }

        blocksWithBreaks.push({
          data: props,
          component: <ScrollAnimatedSection key={sys.id} {...props} />,
        });
        break;
      }

      case 'SubNavigation': {
        blocksWithBreaks.push({
          data: props,
          component: (
            <SubNavigation
              key={sys.id}
              items={props?.subNavigationItemsCollection?.items ?? []}
              {...props}
            />
          ),
        });
        break;
      }

      case 'BlockHero': {
        blocksWithBreaks.push({
          data: props,
          component: props.useLatestPost ? (
            <LatestPostHero key={sys.id} backgroundColor={props.backgroundColor} />
          ) : (
            <Hero
              key={sys.id}
              {...props}
              fitWindow={scrollSnap || props.fitWindow}
              fallbackDate={slugContext.postedDate ?? slugContext.publishedAt}
              shareable={slugContext.isShareable}
            />
          ),
        });
        break;
      }

      case 'LatestPosts': {
        blocksWithBreaks.push({
          data: props,
          component: <LatestPostBlock {...props} key={sys.id} />,
        });
        break;
      }

      case 'MultiVideoBlock': {
        blocksWithBreaks.push({
          data: props,
          component: <MultiVideoBlock key={sys.id} id={sys.id} />,
        });
        break;
      }

      case 'Block': {
        blocksWithBreaks.push({
          data: props,
          component: (
            <Block
              key={sys.id}
              {...props}
              isPreviousSameBackgroundColor={props.backgroundColor === prevBackgroundColor}
              isNextSameBackgroundColor={props.backgroundColor === nextBackgroundColor}
              fullHeight={scrollSnap || props.fullHeight}
            />
          ),
        });
        break;
      }

      case 'SplitBlock': {
        blocksWithBreaks.push({
          data: props,
          component: <SplitBlock key={sys.id} {...props} />,
        });
        break;
      }

      case 'MultiValuePropBlock': {
        blocksWithBreaks.push({
          data: props,
          component: <MultiValuePropBlock key={sys.id} {...props} />,
        });
        break;
      }

      case 'BlockTabs': {
        blocksWithBreaks.push({
          data: props,
          component: <BlockTabs key={sys.id} {...props} />,
        });
        break;
      }

      default: {
        const customBlocks = getCustomComponents();

        if (__typename in customBlocks) {
          const CustomComponent = customBlocks[__typename]!;

          blocksWithBreaks.push({
            data: props,
            component: <CustomComponent key={sys.id} id={sys.id} />,
          });
        } else {
          blocksWithBreaks.push({
            // Putting in a dummy value for iteration safety.
            data: {
              __typename: 'Break',
              sys: { id: '__generated_error' },
              type: 'Straight',
            },
            component: (
              <Fragment key={sys.id}>
                {!Config.isCompilationModeProd &&
                  `Dev error: Unknown component type ${__typename} with id ${sys.id}`}
              </Fragment>
            ),
          });
        }
      }
    }

    // Auto-insert breaks
    if (!isLastBlock) {
      const skipBreak =
        __typename === 'Break' ||
        nextBlock?.__typename === 'Break' ||
        __typename === 'SubNavigation' ||
        nextBlock?.__typename === 'SubNavigation' ||
        isCustomComponent(__typename) ||
        isCustomComponent(nextBlock?.__typename) ||
        (blockHasBackgroundMedia && nextBlockHasBackgroundMedia) ||
        (blockBackgroundColor === nextBackgroundColor &&
          !blockHasBackgroundMedia &&
          !nextBlockHasBackgroundMedia) ||
        breakTemplates.length <= 0;

      // Auto-insert break if needed
      if (!skipBreak) {
        const heroBreakTemplate =
          Config.theme?.heroBreakTemplate === Template.Straight
            ? Template.Straight
            : Template.Skirt2;
        const template =
          __typename === 'BlockHero' ? heroBreakTemplate : breakTemplates[templateIndex]!;
        const topColor =
          blockHasBackgroundMedia || !blockBackgroundColor ? undefined : blockBackgroundColor;
        const bottomColor =
          nextBlockHasBackgroundMedia || !nextBackgroundColor ? undefined : nextBackgroundColor;
        const isOverlaid = false;

        blocksWithBreaks.push({
          data: {
            __typename: 'Break',
            sys: { id: '__generated' },
            type: template,
            topColor,
            bottomColor,
            isOverlaid,
          },
          component: (
            <BreakSDS
              key={`break ${idx}`}
              template={template}
              topColor={topColor}
              bottomColor={bottomColor}
              isOverlaid={isOverlaid}
            />
          ),
        });

        // Rotate template to use, unless this block is a Hero as that always uses a specific template
        if (__typename !== 'BlockHero') {
          templateIndex = (templateIndex + 1) % breakTemplates.length;
        }
      }
    }
  });

  // =================================================================================================
  // Add break spacers where 'Break' component overlays blocks/components
  // =================================================================================================
  const blocksToRender = blocksWithBreaks.map((block, idx) => {
    const { component } = block;
    const prevBlock = blocksWithBreaks?.[idx - 1];
    const nextBlock = blocksWithBreaks?.[idx + 1];

    const breakSpacerProps: {
      preChildren?: ReactNode;
      postChildren?: ReactNode;
    } = {};

    // If previous and/or next block is a 'Break' that overlays the block/component, inject a
    // 'BreakSpacer' component as pre/post children to the block/component, which will counter
    // the amount that the 'Break' overlays so that any content is not obscured and background
    // media continues behind the break
    // Do not add spacers for straight breaks as they will not obscure content
    if (
      prevBlock &&
      prevBlock.data.__typename === 'Break' &&
      prevBlock.data.type !== Template.Straight &&
      prevBlock.data.type !== 'None'
    ) {
      const topBreakOverlayType = getBreakOverlayType({
        template: prevBlock.data.type,
        isTopTransparent: !prevBlock.data.topColor || prevBlock.data.topColor === 'Transparent',
        isBottomTransparent:
          !prevBlock.data.bottomColor || prevBlock.data.bottomColor === 'Transparent',
        isOverlaid: prevBlock.data.isOverlaid,
      });

      breakSpacerProps.preChildren = <BreakSpacerSDS type={topBreakOverlayType} location="top" />;
    }

    if (
      nextBlock &&
      nextBlock.data.__typename === 'Break' &&
      nextBlock.data.type !== Template.Straight &&
      nextBlock.data.type !== 'None'
    ) {
      const bottomBreakOverlayType = getBreakOverlayType({
        template: nextBlock.data.type,
        isTopTransparent: !nextBlock.data.topColor || nextBlock.data.topColor === 'Transparent',
        isBottomTransparent:
          !nextBlock.data.bottomColor || nextBlock.data.bottomColor === 'Transparent',
        isOverlaid: nextBlock.data.isOverlaid,
      });

      breakSpacerProps.postChildren = (
        <BreakSpacerSDS type={bottomBreakOverlayType} location="bottom" />
      );
    }

    // The last block should have a post child to add extra spacing.
    if (!nextBlock && !breakSpacerProps.postChildren) {
      breakSpacerProps.postChildren = (
        <BreakSpacerSDS type={BreakOverlayType.TOP_HALF} location="bottom" />
      );
    }

    return cloneElement(component, breakSpacerProps);
  });

  // =================================================================================================
  // Content
  // =================================================================================================

  // New background media

  const {
    media: { imageSource, videoSource },
    mobileMedia: { imageSource: mobileImageSource, videoSource: mobileVideoSource },
    thumbnailSource,
  } = useParseMediaContainer(backgroundMediaV2);
  const { getImageSources } = useContentfulImages();

  let imgSrcs: ImageSources | undefined;

  if (backgroundMediaV2?.__typename === 'Image') {
    const { desktopSettings, mobileSettings } = getImageSourceSettings({
      desktopHeight: backgroundMediaV2.media?.height ?? 0,
      mobileHeight: backgroundMediaV2.mobileMedia?.height ?? 0,
      enableHighDpi: backgroundMediaV2.enableHighDpi,
      quality: backgroundMediaV2.quality,
    });

    imgSrcs = combineImageSources({
      desktop: getImageSources(imageSource, desktopSettings),
      mobile: getImageSources(mobileImageSource, mobileSettings),
    });
  }

  // set Page to ltr unless title points us to rtl
  const dir = direction(title);

  const content = (
    <>
      <DefinitionProvider>
        <PictureContext.Provider value={pictureContextNoLazy}>
          {blocksToRender.slice(0, firstHeroOrBlockIndex + 1)}
        </PictureContext.Provider>
        <PictureContext.Provider value={pictureContextLazy}>
          {blocksToRender.slice(firstHeroOrBlockIndex + 1)}
        </PictureContext.Provider>
        <DefinitionOverlay />
      </DefinitionProvider>
      {footnoteBlock}
    </>
  );

  let banner: ReactNode = null;
  let customBanner: ReactNode = null;

  if (props?.banner?.__typename === 'Banner') {
    const { contentfulDescriptionDataset } = getContentfulInspectorProps<BannerType>({
      entryId: props.banner.sys.id,
      fieldIds: ['contentfulDescription'],
    });

    banner = (
      <Banner
        className={pageStickyCss}
        contentClassName={bannerContentContainerCss}
        backgroundColor={props.banner?.backgroundColor as BackgroundColor}
        dataset={contentfulDescriptionDataset}
      >
        {renderRichTextWithElements(props.banner?.content as RichText | string)}
      </Banner>
    );
  } else if (props?.banner?.__typename) {
    const customBlocks = getCustomComponents();

    if (customBlocks[props.banner.__typename]) {
      const CustomComponent = customBlocks[props.banner.__typename]!;
      customBanner = <CustomComponent id={props.banner.sys.id} />;
    }
  }

  // =================================================================================================
  // MobileCTA
  // =================================================================================================

  let persistentCta: ReactElement | undefined;
  if (isMobile && mobileCta) {
    const button = { ...mobileCta.presentation! };
    button.buttonType = ButtonType.Primary;
    persistentCta = (
      <aside data-testid="mwp-persistent-cta" className={cx(MotifScheme.SECONDARY, mobileCtaCss)}>
        <CallToAction {...mobileCta} presentation={button} />
      </aside>
    );
  }

  // =================================================================================================
  // Render
  // =================================================================================================
  return (
    <div
      data-testid="page"
      ref={pageRef}
      className={cx(
        getBackgroundClassName(pageBackgroundColor),
        defaultPageBackgroundCss,
        {
          /**
           * Need set bg to transparent if bg img or video because we need to keep z-index: -1 on
           * the background media to ensure it's behind the content because some content does not
           * set position: relative. TODO: This is a temporary solution until we have a better way
           * to handle (specifically, cheerios is broken without this, can potentially be fixed by
           * setting position:relative on CheeriosStaticBlock)
           * https://jira.sc-corp.net/browse/WEBP-9706
           */
          [pageTransparentBgCss]: !!(imageSource || videoSource),
          [pageSideNavCss]: hasSideNav,
        },
        minHeightCss,
        'mwp-page'
      )}
      dir={dir}
      data-test-feature={testFeature}
      style={
        {
          [pageStickyHeightCssVar]: `${stickyHeightRef.current}px`,
        } as CSSProperties
      }
    >
      <Canonical localizedIn={props.localizedIn} />
      <AlternateLang localizedIn={props.localizedIn} />
      {!!metas && <Metas {...metas} />}
      {!!metas?.schemasCollection?.items && <WebSchemas schemas={metas.schemasCollection.items} />}

      {banner ? (
        <div className={pageStickyCss} ref={stickyContainerRef}>
          {banner}
        </div>
      ) : null}
      <HeaderSDS title={title} />
      {/* By including the HlsInitializer wrapper here, we are adding Hls playback support to the Page and any of its children */}
      <HlsInitializer>
        <PageSDS
          backgroundColor={pageBackgroundColor}
          backgroundImageSources={imgSrcs}
          backgroundMediaStyle={backgroundMediaStyle}
          backgroundVideoSource={videoSource}
          mobileBackgroundVideoSource={mobileVideoSource}
          backgroundPosterSource={thumbnailSource}
          className={cx({ [pageCss]: !hasSubNav, [pageWithSubNavCss]: hasSubNav })}
          scrollSnap={scrollSnap}
        >
          {content}
          {customBanner}
          {persistentCta}
          <PageBottomStickyPortal className={stickyBottomCss} />
        </PageSDS>
      </HlsInitializer>
      <ActiveEventCountTracker />
    </div>
  );
};

Page.displayName = 'Page';
