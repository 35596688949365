import { m } from '../../motif/m';

export const blockNavigationBlackBgVars = {
  '--block-navigation-buttons-color': m('--neutral-v500'),
  '--block-navigation-buttons-active-color': m('--neutral-v700'),
  '--block-navigation-buttons-bg-color': m('--neutral-v0'),
  '--block-navigation-buttons-border-color': m('--neutral-v500'),
  '--block-navigation-buttons-active-border-color': m('--primary-v100'),
  '--block-navigation-buttons-active-bg-color': m('--primary-v100'),
};

export const blockNavigationYellowBgVars = {
  '--block-navigation-buttons-bg-color': m('--neutral-v0'),
  '--block-navigation-buttons-color': m('--neutral-v500'),
  '--block-navigation-buttons-border-color': m('--neutral-v500'),
  '--block-navigation-buttons-active-color': m('--neutral-v0'),
  '--block-navigation-buttons-active-border-color': m('--neutral-v700'),
  '--block-navigation-buttons-active-bg-color': m('--neutral-v700'),
};

export const blockNavigationWhiteBgVars = {
  '--block-navigation-buttons-bg-color': m('--neutral-v0'),
  '--block-navigation-buttons-color': m('--neutral-v500'),
  '--block-navigation-buttons-border-color': m('--neutral-v500'),
  '--block-navigation-buttons-active-color': m('--neutral-v700'),
  '--block-navigation-buttons-active-border-color': m('--primary-v100'),
  '--block-navigation-buttons-active-bg-color': m('--primary-v100'),
};

export const blockNavigationGrayBgVars = {
  '--block-navigation-buttons-bg-color': m('--neutral-v0'),
  '--block-navigation-buttons-color': m('--neutral-v500'),
  '--block-navigation-buttons-border-color': m('--neutral-v500'),
  '--block-navigation-buttons-active-color': m('--neutral-v0'),
  '--block-navigation-buttons-active-border-color': m('--neutral-v700'),
  '--block-navigation-buttons-active-bg-color': m('--neutral-v700'),
};
