import type { MotifComponent, MotifComponentProps } from '../../motif';
import { m } from '../../motif/m';

export const progressBarBlackBgVars: MotifComponentProps<MotifComponent.PROGRESS_BAR> = {
  '--progress-bar-bg-color': m('--neutral-v250'),
  '--progress-bar-progress-color': m('--primary-v100'),
};

export const progressBarWhiteBgVars: MotifComponentProps<MotifComponent.PROGRESS_BAR> = {
  '--progress-bar-bg-color': m('--neutral-v250'),
  '--progress-bar-progress-color': m('--primary-v100'),
};

export const progressBarGrayBgVars: MotifComponentProps<MotifComponent.PROGRESS_BAR> = {
  '--progress-bar-bg-color': m('--neutral-v0'),
  '--progress-bar-progress-color': m('--primary-v100'),
};

export const progressBarYellowBgVars: MotifComponentProps<MotifComponent.PROGRESS_BAR> = {
  '--progress-bar-bg-color': m('--neutral-v0'),
  '--progress-bar-progress-color': m('--neutral-v300'),
};
