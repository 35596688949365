import { cx } from '@emotion/css';
import { useBrowserLayoutEffect } from '@snapchat/mw-common/client';
import { type FC, type PropsWithChildren, useEffect, useState } from 'react';

import { MotifComponent, useMotifStyles } from '../../motif';
import { dataSetToAttributes, getBackgroundClassName, useWindowSize } from '../../utils';
import { itemsCss, itemsWrapCss, leftGradientCss, navCss, rightGradientCss } from './styles';
import type { SubNavigationProps } from './types';

export const SubNavigation: FC<PropsWithChildren<SubNavigationProps>> = ({
  backgroundColor,
  className,
  children,
  horizontalScrollRef,
  dataset,
}) => {
  useMotifStyles(MotifComponent.SUB_NAVIGATION);

  const { width: windowWidth } = useWindowSize();

  const [isRTL, setIsRTL] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);

  useBrowserLayoutEffect(() => {
    setIsRTL(!!horizontalScrollRef?.current?.closest('[dir="rtl"]'));
  }, [horizontalScrollRef?.current, windowWidth]);

  useEffect(() => {
    const element = horizontalScrollRef?.current;
    if (!element) return;

    const listener = () => {
      /* There is an issue with the scroll poisition being .5px out in some cases so using
       * Math.ceil() to force to closest whole number
       */
      setScrollPosition(Math.ceil(horizontalScrollRef?.current?.scrollLeft ?? 0));
    };

    element.addEventListener('scroll', listener, { passive: false });
    element.addEventListener('touchmove', listener, { passive: false });

    return () => {
      element.removeEventListener('scroll', listener);
      element.removeEventListener('touchmove', listener);
    };
  }, [horizontalScrollRef]);

  let parentElementWidth = 0;
  let totalElementWidth = 0;

  if (horizontalScrollRef?.current?.parentElement) {
    parentElementWidth = horizontalScrollRef.current.parentElement.scrollWidth;
    totalElementWidth = horizontalScrollRef.current.scrollWidth;
  }

  let isGradientLeft = false;
  let isGradientRight = false;

  if (isRTL) {
    isGradientLeft = Math.ceil(-scrollPosition) < totalElementWidth - parentElementWidth;
    isGradientRight = scrollPosition < 0;
  } else {
    isGradientLeft = scrollPosition > 0;
    isGradientRight = scrollPosition < totalElementWidth - parentElementWidth;
  }

  return (
    <nav
      className={cx(
        MotifComponent.SUB_NAVIGATION,
        navCss,
        getBackgroundClassName(backgroundColor),
        className
      )}
      {...dataSetToAttributes(dataset)}
    >
      {isGradientLeft && <div className={leftGradientCss} />}
      <div ref={horizontalScrollRef} className={itemsWrapCss}>
        <ul className={itemsCss}>{children}</ul>
      </div>
      {isGradientRight && <div className={rightGradientCss} />}
    </nav>
  );
};
