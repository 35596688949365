import type { GetImageSources } from '@snapchat/mw-contentful-client';
import type {
  FeaturedHeaderMenuItem,
  HeaderMenuItem,
  HeaderMenuItemGroup,
} from '@snapchat/mw-contentful-schema';
import type {
  NavigatorFeaturedItemProps,
  NavigatorItemProps,
} from '@snapchat/snap-design-system-marketing';

import { combineImageSources } from '../../utils/combineImageSources';
import { getContentfulInspectorProps } from '../../utils/contentful/getContentfulInspectorProps';
import type { IsUrlCurrent } from '../ConsumerContextProvider';
import type { CriteriaChecker } from '../Experiment';

/**
 * Helper Type that overrides the model from Contentful with the following:
 *
 * - Adds an `isSelected` prop to easily mark the Navigator Group as currently selected
 * - Replaces child collections to SDS-M Component Prop types
 *
 * Reasoning: We need to traverse all children to determine whether they reference the current page
 * url in order to set the `isSelected` property. We also need to transform each child for rendering
 * the relevant SDS-M component (flatten the icon/media information, transform analytics field,
 * etc.) We'll do this within the Header component to minimize React render cycles and consolidate
 * this logic for use for both Desktop and Mobile UX.
 */
export interface NavigatorGroupProps
  extends Omit<
    HeaderMenuItemGroup,
    | '_id'
    | 'column1MenuItemsCollection'
    | 'column2MenuItemsCollection'
    | 'column3MenuItemsCollection'
    | 'featuredHeaderMenuItem'
  > {
  isSelected?: boolean;
  column1Items?: NavigatorItemProps[];
  column2Items?: NavigatorItemProps[];
  column3Items?: NavigatorItemProps[];
  featuredMenuItem?: NavigatorFeaturedItemProps;
}

/**
 * Processes input from Contentful to a a format that can be easily rendered:
 *
 * - Removes nav items that should be hidden based on criteria
 * - Flags top level nav items that should be marked active based on current url
 * - Converts child navigator Items to SDS-M Prop types so we can consolidate logic between Desktop
 *   and Mobile UX
 */
export function convertToPathAwareNavigatorGroups(
  items?: HeaderMenuItemGroup[],
  isUrlCurrent?: IsUrlCurrent,
  checkCriteria?: CriteriaChecker,
  getImageSources?: GetImageSources
): NavigatorGroupProps[] {
  const output: NavigatorGroupProps[] = [];
  for (const item of items ?? []) {
    const node = convertToPathAwareNavGroup(item, isUrlCurrent, checkCriteria, getImageSources);
    node && output.push(node);
  }

  return output;
}

const convertToPathAwareNavGroup = (
  group: HeaderMenuItemGroup,
  isUrlCurrent?: (url: string) => boolean,
  checkCriteria?: CriteriaChecker,
  getImageSources?: GetImageSources
): NavigatorGroupProps | null => {
  // don't render if hidden criteria is met
  const isVisible = checkCriteria?.(group.hideCriteria) ?? true;

  if (!isVisible) return null;

  // default isSelected to top level nav item, but this may be overridden by child menu items
  let isSelected = (!!group.url && isUrlCurrent?.(group.url)) ?? false;

  // filters out hidden menu items and updates `isSelected` if a child menu item is the current url
  const menuItemsReducer = (accumulator: NavigatorItemProps[], item: HeaderMenuItem) => {
    const isVisible = checkCriteria?.(item.hideCriteria) ?? true;

    // hidden, don't add to collection
    if (!isVisible) return accumulator;

    // updated parent isSelected if appropriate
    const isItemCurrent = (!!item.url && isUrlCurrent?.(item.url)) ?? false;
    isSelected ||= isItemCurrent;

    accumulator.push({
      title: item.title ?? '',
      url: item.url ?? '',
      description: item.description,
      analytics: getAnalyticsForNavigatorItem(item),
      icon: getIconForNavigatorItem(item),
      media: getMediaForNavigatorItem(item, getImageSources),
      dataset: getContentfulInspectorProps<HeaderMenuItem>({
        entryId: item.sys.id,
        fieldIds: ['contentfulDescription'],
      }).contentfulDescriptionDataset,
    });

    return accumulator;
  };

  const {
    column1MenuItemsCollection,
    column2MenuItemsCollection,
    column3MenuItemsCollection,
    featuredHeaderMenuItem,
    ...otherProps
  } = group;

  const column1Items = column1MenuItemsCollection?.items.reduce(menuItemsReducer, []);
  const column2Items = column2MenuItemsCollection?.items.reduce(menuItemsReducer, []);
  const column3Items = column3MenuItemsCollection?.items.reduce(menuItemsReducer, []);
  const featuredMenuItem = getFeaturedItemForNavigatorGroup(group, getImageSources);

  // updated parent isSelected if appropriate
  const isFeaturedItemVisible = checkCriteria?.(group.featuredHeaderMenuItem?.hideCriteria) ?? true;
  if (group.featuredHeaderMenuItem && isFeaturedItemVisible) {
    const isItemCurrent =
      (!!group.featuredHeaderMenuItem.url && isUrlCurrent?.(group.featuredHeaderMenuItem.url)) ??
      false;
    isSelected ||= isItemCurrent;
  }

  const output: NavigatorGroupProps = {
    ...otherProps,
    isSelected,
    column1Items,
    column2Items,
    column3Items,
    featuredMenuItem,
  };

  return output;
};

const getAnalyticsForNavigatorItem = (
  item: HeaderMenuItem | FeaturedHeaderMenuItem
): NavigatorItemProps['analytics'] | undefined => {
  const label = item.analytics?.label;

  if (!label) return;

  return {
    label,
  };
};

const getIconForNavigatorItem = (
  item: HeaderMenuItem | FeaturedHeaderMenuItem
): NavigatorItemProps['icon'] | undefined => {
  const iconName = item.icon?.icon;

  if (!iconName) return;

  return {
    name: iconName,
  };
};

/**
 * This helper function enforces a Business Requirement that we only render SVG images.
 *
 * This isn't enforcible within Contentful, so we do it here.
 */
const getMediaForNavigatorItem = (
  item: HeaderMenuItem | FeaturedHeaderMenuItem,
  getImageSources?: GetImageSources
): NavigatorItemProps['media'] | undefined => {
  // Helper function to validate if icon format is valid (must be SVG)
  const isMediaSvg = item.icon?.media?.contentType === 'image/svg+xml';
  const media = item.icon?.media;

  if (!isMediaSvg || !media) {
    return;
  }

  if (!getImageSources) {
    return;
  }

  const imgSrcs = combineImageSources({
    desktop: getImageSources(media.url),
  });

  return {
    altText: media.description,
    imgSrcs,
  };
};

const getFeaturedItemForNavigatorGroup = (
  group: HeaderMenuItemGroup,
  getImageSources?: GetImageSources
): NavigatorFeaturedItemProps | undefined => {
  if (!group.featuredHeaderMenuItem) return undefined;

  const { title, url, description, ctaLabel } = group.featuredHeaderMenuItem;

  return {
    title: title ?? '',
    url: url ?? '',
    description,
    ctaLabel,
    analytics: getAnalyticsForNavigatorItem(group.featuredHeaderMenuItem),
    icon: getIconForNavigatorItem(group.featuredHeaderMenuItem),
    media: getMediaForNavigatorItem(group.featuredHeaderMenuItem, getImageSources),
    dataset: getContentfulInspectorProps<FeaturedHeaderMenuItem>({
      entryId: group.featuredHeaderMenuItem.sys.id,
      fieldIds: ['contentfulDescription'],
    }).contentfulDescriptionDataset,
  };
};
