import { gql } from '@apollo/client';

import { contentfulSysIdFragment } from '../../utils/contentful/contentfulQuery';
import { fragments as analyticsFragments } from '../Analytics';
import { ctaFragment } from '../CallToAction';
import { criteriaFragment } from '../Experiment';
import { assetFragment, fragments as mediaFragments } from '../Media';
import { navigationCtaFragment } from '../NavigationCta';

// =================================================================================================
// Header Menu Item Group Fragment
// =================================================================================================

export const headerMenuItemGroupFragment = gql`
  fragment HeaderMenuItemGroupFieldsOnly on HeaderMenuItemGroup {
    ...ContentfulSysId
    title
    url
    column1Label
    column1MenuItemsCollection {
      items {
        ...HeaderMenuItemFieldsOnly
      }
    }
    column2Label
    column2MenuItemsCollection {
      items {
        ...HeaderMenuItemFieldsOnly
      }
    }
    column3Label
    column3MenuItemsCollection {
      items {
        ...HeaderMenuItemFieldsOnly
      }
    }
    featuredHeaderMenuItem {
      ...FeaturedHeaderMenuItemFieldsOnly
    }
  }
  ${contentfulSysIdFragment}
`;

// =================================================================================================
// Header Menu Item Fragment
// =================================================================================================

export const headerMenuItemFragment = gql`
  fragment HeaderMenuItemFieldsOnly on HeaderMenuItem {
    ...ContentfulSysId
    title
    url
    description
    icon {
      ...ContentfulSysId
      icon
      media {
        ...AssetAll
      }
    }
    analytics {
      ...AnalyticsAll
    }
    hideCriteria {
      ...CriteriaAll
    }
  }
  ${contentfulSysIdFragment}
  ${analyticsFragments.all}
  ${criteriaFragment}
  ${assetFragment}
`;

// =================================================================================================
// Featured Header Menu Item Fragment
// =================================================================================================

export const featuredHeaderMenuItemFragment = gql`
  fragment FeaturedHeaderMenuItemFieldsOnly on FeaturedHeaderMenuItem {
    ...ContentfulSysId
    title
    url
    description
    icon {
      ...ContentfulSysId
      icon
      media {
        ...AssetAll
      }
    }
    ctaLabel
    analytics {
      ...AnalyticsAll
    }
    hideCriteria {
      ...CriteriaAll
    }
  }
  ${contentfulSysIdFragment}
  ${analyticsFragments.all}
  ${criteriaFragment}
  ${assetFragment}
`;

// =================================================================================================
// Navigator Fragment
// =================================================================================================

export const navigatorFragment = gql`
  fragment NavigatorLocal on Navigator {
    ...ContentfulSysId
    title
    logoV2 {
      ... on Image {
        ...ImageAll
      }
    }
    theme
    backgroundColor
    headerMenuItemGroupsCollection(limit: 7) {
      items {
        ...HeaderMenuItemGroupFieldsOnly
      }
      total
    }
    callsToActionCollection(limit: 10) {
      items {
        ... on CallToAction {
          ...CallToActionAll
        }
        ... on NavigationCallToAction {
          ...NavigationCta
        }
      }
    }
    url
    analytics {
      ...AnalyticsAll
    }
  }
  ${contentfulSysIdFragment}
  ${analyticsFragments.all}
  ${headerMenuItemGroupFragment}
  ${headerMenuItemFragment}
  ${featuredHeaderMenuItemFragment}
  ${ctaFragment}
  ${navigationCtaFragment}
  ${mediaFragments.all}
`;
