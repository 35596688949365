import { cx } from '@emotion/css';
import type { FC } from 'react';
import { useContext } from 'react';

import { m } from '../../../motif';
import { dataSetToAttributes } from '../../../utils';
import { PrimitivesContext } from '../../Primitives';
import { IconOrImage } from '../IconOrImage';
import {
  navigatorItemLinkCss,
  navigatorItemLinkIconCss,
  navigatorItemLinkSpacerCss,
  navigatorItemLinkTextCss,
  navigatorItemLinkTextDescriptionCss,
  navigatorItemLinkTextTitleCss,
} from './NavigatorItemLink.styled';
import type { NavigatorItemLinkProps } from './types';

export const NavigatorItemLink: FC<NavigatorItemLinkProps> = ({
  url,
  title,
  dataset,
  description,
  icon,
  media,
  showSpacer,
  onClick,
}) => {
  const { Anchor } = useContext(PrimitivesContext);

  return (
    <>
      <Anchor
        className={cx(navigatorItemLinkCss, 'sdsm-nav-link')}
        href={url}
        role="menuitem"
        onClick={onClick}
        {...dataSetToAttributes(dataset)}
      >
        {(icon || media) && (
          <span className={navigatorItemLinkIconCss}>
            <IconOrImage
              icon={icon}
              media={media}
              size={24}
              fill={m('--global-header-menu-item-icon-color')}
            />
          </span>
        )}
        <span className={navigatorItemLinkTextCss}>
          <span className={navigatorItemLinkTextTitleCss}>{title}</span>
          {!!description && (
            <span className={navigatorItemLinkTextDescriptionCss}>{description}</span>
          )}
        </span>
      </Anchor>
      {/* If there are other items in the same column with a description, add extra space so that
          all items appear the same size */}
      {!description && showSpacer ? (
        <span className={navigatorItemLinkSpacerCss}>&nbsp;</span>
      ) : null}
    </>
  );
};

NavigatorItemLink.displayName = 'NavigatorItemLink';
