import type { MotifComponent, MotifComponentProps } from '../../motif';
import { m } from '../../motif/m';

export const quoteGrayBgVars: MotifComponentProps<MotifComponent.QUOTE> = {
  '--quote-bg-color': m('--neutral-v150'),
  '--quote-fg-color': m('--neutral-v700'),
  '--quote-author-desktop-font-size': '16px',
  '--quote-author-desktop-font-weight': '500',
  '--quote-author-mobile-font-size': '16px',
  '--quote-author-mobile-font-weight': '500',
};

export const quoteWhiteBgVars: MotifComponentProps<MotifComponent.QUOTE> = {
  '--quote-bg-color': m('--neutral-v0'),
  '--quote-fg-color': m('--neutral-v700'),
  '--quote-author-desktop-font-size': '16px',
  '--quote-author-desktop-font-weight': '500',
  '--quote-author-mobile-font-size': '16px',
  '--quote-author-mobile-font-weight': '500',
};
