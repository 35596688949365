import type { MotifComponent, MotifComponentProps } from '../../motif';
import { m } from '../../motif/m';

export const summaryCardVars = {
  '--summary-card-bg-color': m('--neutral-v0'),
  '--summary-card-hover-bg-color': m('--neutral-v0'),
  '--summary-card-border-radius': m('--border-radius-s'),
  '--summary-card-description-color': m('--neutral-v700'),
  '--summary-card-fg-color': m('--neutral-v600'),
  '--summary-card-title-color': m('--neutral-v700'),
  '--summary-card-border-width': '0',
  '--summary-card-border-color': 'transparent',
  '--summary-card-hover-border-color': 'transparent',
  '--summary-card-box-shadow': m('--box-shadow-s'),
  '--summary-card-hover-box-shadow': m('--box-shadow-s'),
};

export const contrastBlackSummaryCardVars: MotifComponentProps<MotifComponent.SUMMARY_CARD> = {
  '--summary-card-bg-color': m('--neutral-v625'),
  '--summary-card-hover-bg-color': m('--neutral-v625'),
  '--summary-card-border-radius': m('--border-radius-s'),
  '--summary-card-description-color': m('--neutral-v150'),
  '--summary-card-fg-color': m('--neutral-v0'),
  '--summary-card-title-color': m('--neutral-v0'),
  '--summary-card-border-width': '0',
  '--summary-card-border-color': 'transparent',
  '--summary-card-hover-border-color': 'transparent',
  '--summary-card-box-shadow': m('--box-shadow-s'),
  '--summary-card-hover-box-shadow': m('--box-shadow-s'),
} as const;
