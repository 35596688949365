import { type CSSProperties, type RefObject, useEffect } from 'react';

import type { Animation } from './types';

let staticObserver: IntersectionObserver;

const getObserver = () => {
  if (staticObserver) return staticObserver;

  staticObserver = new IntersectionObserver(entries => {
    for (const entry of entries) {
      const target = entry.target;

      if (entry.isIntersecting && target instanceof HTMLElement) {
        target.style.animationPlayState = 'running';

        staticObserver.unobserve(target);
      }
    }

    return staticObserver;
  });

  return staticObserver;
};

const getAnimationCssProperties = (animation: Animation): CSSProperties => {
  const animationName = animation.animationName;
  const delay = animation.delay ? `${animation.delay}ms` : '';
  const direction = animation.direction ? `${animation.direction}` : 'normal';
  const duration = animation.duration ? `${animation.duration}ms` : '1000ms';
  const fillMode = 'forwards';
  const iterationCount = animation.iterationCount ?? 1;
  const playState = 'paused';
  const timingFunction = animation.timingFunction ?? '';
  const transformOrigin = animation.transformOrigin ?? '';

  return {
    animation: `${duration} ${timingFunction} ${delay} ${iterationCount} ${direction} ${fillMode} ${playState} ${animationName}`,
    transformOrigin: `${transformOrigin}`,
  };
};

/**
 * A React hook that applies an animation to a referenced HTML element.
 *
 * @example
 *
 * ```jsx
 * const animationRef = useRef(null);
 * const animationCss = useAnimations(animationRef, animation);
 *
 * return (
 *   <div ref={animationRef} style={animationCss}>
 *     Content
 *   </div>
 * );
 * ```
 *
 * @param {RefObject<HTMLElement | undefined>} ref - A React ref object pointing to the HTML element
 *   to be animated.
 * @param {Animation | undefined} animation - An object representing the animation to apply to the
 *   element.
 * @returns {string | undefined} - The CSS for the animation, or undefined if no animation is
 *   provided.
 */
export function useAnimations(
  ref: RefObject<HTMLElement | undefined>,
  animation: Animation | undefined
): CSSProperties | undefined {
  useEffect(() => {
    if (!animation) {
      return undefined;
    }

    const observer = getObserver();
    const toObserve: Array<HTMLElement> = [];
    const targetElement = ref.current;

    if (!targetElement) {
      return;
    }

    const userPrefersReducedMotion = window.matchMedia('(prefers-reduced-motion: reduce)').matches;
    if (userPrefersReducedMotion) {
      targetElement.style.animationName = '';
      targetElement.style.opacity = '1';

      return;
    }

    observer.observe(targetElement);
    toObserve.push(targetElement);

    return () => {
      observer.unobserve(targetElement);
    };
  }, [animation, ref]);

  if (!animation) {
    return;
  }

  return getAnimationCssProperties(animation);
}
