import { m } from '../../motif/m';

export const logoDarkBgVars = {
  '--logo-stroke-color': m('--neutral-v0'),
  '--logo-fill-color': m('--neutral-v700'),
};

export const logoLightBgVars = {
  '--logo-stroke-color': m('--neutral-v700'),
  '--logo-fill-color': m('--neutral-v0'),
};
