import { keyframes } from '@emotion/css';
import type { Animation, CssProperties } from '@snapchat/snap-design-system-marketing';
import type { CSSProperties } from 'react';

import type { EntranceAnimation } from '../../../../contentful-schema/src';

/**
 * Converts a semicolon-separated string of CSS styles into an object with camelCase keys.
 *
 * This function takes a string of CSS styles, processes it by splitting the string into individual
 * style declarations, and then returns an object where each key is the camelCase version of the CSS
 * property name, and the corresponding value is the style value.
 *
 * @example Const styles = "background-color: red; font-size: 16px;";
 *
 * Const result = applyStyles(styles);
 *
 * Output: { backgroundColor: "red", fontSize: "16px" }
 *
 * @param {string} styleString - A semicolon-separated string of CSS styles (e.g.,
 *   "background-color: red; font-size: 16px;").
 * @returns {CssProperties} An object with the camelCased CSS properties as keys and their values as
 *   string values.
 */
const applyStyles = (styleString: string): CssProperties => {
  return styleString
    .split(';')
    .filter(Boolean)
    .reduce((acc, style) => {
      const [property, value] = style.split(':').map(s => s.trim());
      if (property && value) {
        const camelCaseProperty = property.replace(/-([a-z])/g, (_, letter) =>
          letter.toUpperCase()
        );

        acc[camelCaseProperty] = value;
      }
      return acc;
    }, {} as CssProperties);
};

const animationKeyframes: Record<string, string> = {
  'Fade in left': keyframes`from {opacity: 0; transform: translateX(-50px);} to {opacity: 1; transform: translateX(0);}`,
  'Fade in right': keyframes`from {opacity: 0; transform: translateX(50px);} to {opacity: 1; transform: translateX(0);}`,
  'Fade in top': keyframes`from {opacity: 0; transform: translateY(-50px);} to {opacity: 1; transform: translateY(0);}`,
  'Fade in bottom': keyframes`from {opacity: 0; transform: translateY(50px);} to {opacity: 1; transform: translateY(0);}`,
};

export const useEntranceAnimation = (
  entranceAnimation: EntranceAnimation | undefined
): { animation: Animation | undefined; styles: CSSProperties | undefined } => {
  if (!entranceAnimation) {
    return { animation: undefined, styles: undefined };
  }

  let styles: CSSProperties = {};
  let animationName = '';

  if (entranceAnimation?.advancedKeyframes?.keyframes) {
    animationName = keyframes(entranceAnimation.advancedKeyframes.keyframes);

    if (entranceAnimation.advancedKeyframes.startingCss) {
      styles = applyStyles(entranceAnimation.advancedKeyframes.startingCss) as CSSProperties;
    }
  } else {
    styles.opacity = 0;
    animationName = entranceAnimation.animation
      ? animationKeyframes[entranceAnimation.animation] ?? ''
      : '';
  }

  const animation: Animation = {
    animationName,
    delay: entranceAnimation?.delay,
    direction: entranceAnimation?.direction,
    duration: entranceAnimation?.duration,
    iterationCount: entranceAnimation?.iterationCount,
    timingFunction: entranceAnimation?.advancedKeyframes?.timingFunction,
    transformOrigin: entranceAnimation?.advancedKeyframes?.transformOrigin,
  };

  return { animation, styles };
};
