import {
  accordionBlackBgVars,
  accordionGrayBgVars,
  accordionWhiteBgVars,
  accordionYellowBgVars,
} from '../components/Accordion/Accordion.palette';
import {
  constrastGrayAutoCompleteVars,
  contrastBlackAutoCompleteVars,
  defaultAutoCompleteVars,
} from '../components/AutoComplete/AutoComplete.palette';
import {
  bannerBlackBgVars,
  bannerGrayBgVars,
  bannerWhiteBgVars,
  bannerYellowBgVars,
} from '../components/Banner/Banner.palette';
import { defaultBlockVars } from '../components/Block/Block.palette';
import {
  blockNavigationBlackBgVars,
  blockNavigationGrayBgVars,
  blockNavigationWhiteBgVars,
  blockNavigationYellowBgVars,
} from '../components/BlockNavigation/BlockNavigation.palette';
import { defaultBreakVars } from '../components/Break/Break.palette';
import {
  contrastBlackButtonVars,
  contrastGrayButtonVars,
  contrastWhiteButtonVars,
  contrastYellowButtonVars,
} from '../components/Button/Button.palette';
import {
  contrastBlackCarouselVars,
  contrastGrayCarouselVars,
  contrastWhiteCarouselVars,
  contrastYellowCarouselVars,
} from '../components/CarouselV3/CarouselV3.palette';
import { contentVars } from '../components/Content/Content.palette';
import {
  definitionBlackBgVars,
  definitionGrayBgVars,
  definitionWhiteBgVars,
  definitionYellowBgVars,
} from '../components/Definition/Definition.palette';
import {
  dropdownMenuDarkVars,
  dropdownMenuLightVars,
} from '../components/DropdownMenu/DropdownMenu.palette';
import { FontFamily } from '../components/Font/fontConstants';
import {
  footerBlackBgVars,
  footerGrayBgVars,
  footerWhiteBgVars,
  footerYellowBgVars,
} from '../components/FooterV2/Footer.palette';
import { footnoteVars } from '../components/FootnoteBlock/FootnoteBlock.palette';
import {
  contrastBlackFormVars,
  contrastGrayFormVars,
  formVars,
} from '../components/Form/Form.palette';
import {
  darkThemeHeaderVars,
  lightThemeHeaderVars,
  yellowThemeHeaderVars,
} from '../components/GlobalHeader/GlobalHeader.palette';
import { heroDarkBgVars, heroLightBgVars } from '../components/Hero/Hero.palette';
import { hyperLinkVarsBg } from '../components/Hyperlink/Hyperlink.palette';
import { iconDefaultVars } from '../components/Icon/Icon.palette';
import {
  contrastBlackIconButtonVars,
  contrastWhiteIconButtonVars,
} from '../components/IconButton/IconButton.palette';
import { defaultLoadingBarVars } from '../components/LoadingBar/LoadingBar.palette';
import { logoDarkBgVars, logoLightBgVars } from '../components/Logo/Logo.palette';
import { mediaVars } from '../components/Media/Media.palette';
import { modalDarkBgVars, modalLightBgVars } from '../components/Modal/Modal.palette';
import { mosaicDarkBgVars, mosaicLightBgVars } from '../components/Mosaic/Mosaic.palette';
import {
  paginationDarkBgVars,
  paginationLightBgVars,
} from '../components/Pagination/Pagination.palette';
import {
  primitiveTableDarkBgVars,
  primitiveTableGrayBgVars,
  primitiveTableLightBgVars,
  primitiveTableYellowBgVars,
} from '../components/Primitives/PrimitiveTable/PrimitiveTable.palette';
import {
  progressBarBlackBgVars,
  progressBarGrayBgVars,
  progressBarWhiteBgVars,
  progressBarYellowBgVars,
} from '../components/ProgressBar/ProgressBar.palettes';
import { quoteGrayBgVars, quoteWhiteBgVars } from '../components/Quote/Quote.palette';
import { searchDefaultVars } from '../components/Search/Search.palette';
import {
  blackThemeSideNavVars,
  grayThemeSideNavVars,
  whiteThemeSideNavVars,
  yellowThemeSideNavVars,
} from '../components/SideNavigation/SideNavigation.palette';
import {
  sideOverlayBlackBgVars,
  sideOverlayGrayBgVars,
  sideOverlayWhiteBgVars,
  sideOverlayYellowBgVars,
} from '../components/SideOverlay/SideOverlay.palette';
import { spinnerDarkBgVars, spinnerLightBgVars } from '../components/Spinner/Spinner.palette';
import {
  subNavigationBlackBgVars,
  subNavigationGrayBgVars,
  subNavigationWhiteBgVars,
  subNavigationYellowBgVars,
} from '../components/SubNavigation/SubNavigation.palette';
import {
  contrastBlackSummaryCardVars,
  summaryCardVars,
} from '../components/SummaryCard/SummaryCard.palette';
import { darkTabVars, lightTabVars } from '../components/Tabs/Tab.palette';
import { defaultTileVars } from '../components/Tile/Tile.palette';
import {
  toggleButtonBlackVars,
  toggleButtonGrayVars,
  toggleButtonYellowVars,
} from '../components/ToggleButton/ToggleButton.palette';
import {
  togglePanelWhiteBgVars,
  togglePanelYellowBgVars,
} from '../components/TogglePanel/TogglePanel.palette';
import {
  toggleSliderBlackVars,
  toggleSliderDarkVars,
  toggleSliderWhiteVars,
  toggleSliderYellowVars,
} from '../components/ToggleSlider/ToggleSlider.palette';
import {
  chartSkeletonDarkVars,
  chartSkeletonLightVars,
} from '../components/visualizations/ChartSkeleton/ChartSkeleton.palette';
import {
  chartToggleBlackBgVars,
  chartToggleGrayBgVars,
  chartToggleWhiteBgVars,
  chartToggleYellowBgVars,
} from '../components/visualizations/ChartToggle/ChartToggle.palette';
import { BackgroundColor } from '../constants/backgroundColor';
import { Black, Blue, Gray, Green, Orange, Plain, Purple, Red, Yellow } from '../constants/colors';
import { m } from './m';
import type { motifBorderRadiusVariables, motifSizeRampVariables } from './motifConstants';
import { MotifComponent, MotifScheme } from './motifConstants';
import type { Motif } from './motifTypes';

/** Based on the original SDS grid (8 pixels). */
export const defaultMotifSizes: Record<(typeof motifSizeRampVariables)[number], string> = {
  '--spacing-xxxs': '2px',
  '--spacing-xxs': '4px',
  '--spacing-xs': '8px',
  '--spacing-s': '12px',
  '--spacing-m': '16px',
  '--spacing-l': '24px',
  '--spacing-xl': '32px',
  '--spacing-xxl': '40px',
  '--spacing-xxxl': '48px',
  '--spacing-xxxxl': '64px',
};

export const defaultBorderRadii: Record<(typeof motifBorderRadiusVariables)[number], string> = {
  '--border-radius-xs': '1px',
  '--border-radius-s': '4px',
  '--border-radius-m': '8px',
  '--border-radius-l': '16px',
  '--border-radius-xl': '24px',
};

const defaultBorderWidthVars = {
  '--border-width-none': '0px',
  '--border-width-xs': '1px',
  '--border-width-s': '2px',
  '--border-width-m': '5px',
  '--border-width-l': '9px',
};

/**
 * Component palette files (e.g. AutoComplete.palette.ts), which use Component Variables are updated
 * with Global variables (--primary, --neutral- and --semantic-). Whereas CSS proerty values are
 * updated with Raw Palette Variables (--palette-).
 *
 * See reference in PR Description: https://github.sc-corp.net/Snapchat/marketing-web/pull/3727
 */
const defaultColorPrimitiveVars = {
  '--palette-plain-black': Plain.BLACK,
  '--palette-plain-transparent': Plain.TRANSPARENT,
  '--palette-plain-white': Plain.WHITE,
  '--palette-black-v50': Black.V50,
  '--palette-black-v100': Black.V100,
  '--palette-black-v125': Black.V125,
  '--palette-black-v150': Black.V150,
  '--palette-black-v200': Black.V200,
  '--palette-yellow-v50': Yellow.V50,
  '--palette-yellow-v100': Yellow.V100,
  '--palette-yellow-v150': Yellow.V150,
  '--palette-yellow-v200': Yellow.V200,
  '--palette-gray-v50': Gray.V50,
  '--palette-gray-v100': Gray.V100,
  '--palette-gray-v150': Gray.V150,
  '--palette-gray-v200': Gray.V200,
  '--palette-gray-v250': Gray.V250,
  '--palette-gray-v300': Gray.V300,
  '--palette-orange-v50': Orange.V50,
  '--palette-orange-v100': Orange.V100,
  '--palette-orange-v150': Orange.V150,
  '--palette-orange-v200': Orange.V200,
  '--palette-red-v50': Red.V50,
  '--palette-red-v100': Red.V100,
  '--palette-red-v150': Red.V150,
  '--palette-red-v200': Red.V200,
  '--palette-green-v50': Green.V50,
  '--palette-green-v100': Green.V100,
  '--palette-green-v150': Green.V150,
  '--palette-green-v200': Green.V200,
  '--palette-purple-v50': Purple.V50,
  '--palette-purple-v100': Purple.V100,
  '--palette-purple-v150': Purple.V150,
  '--palette-purple-v200': Purple.V200,
  '--palette-blue-v50': Blue.V50,
  '--palette-blue-v100': Blue.V100,
  '--palette-blue-v150': Blue.V150,
  '--palette-blue-v200': Blue.V200,
  '--primary-v50': m('--palette-yellow-v50'),
  '--primary-v100': m('--palette-yellow-v100'),
  '--primary-v150': m('--palette-yellow-v150'),
  '--primary-v200': m('--palette-yellow-v200'),
  '--neutral-v0': m('--palette-plain-white'),
  '--neutral-v100': m('--palette-gray-v50'),
  '--neutral-v150': m('--palette-gray-v100'),
  '--neutral-v200': m('--palette-gray-v150'),
  '--neutral-v250': m('--palette-gray-v200'),
  '--neutral-v300': m('--palette-gray-v250'),
  '--neutral-v400': m('--palette-gray-v300'),
  '--neutral-v500': m('--palette-black-v50'),
  '--neutral-v600': m('--palette-black-v100'),
  '--neutral-v625': m('--palette-black-v125'),
  '--neutral-v650': m('--palette-black-v150'),
  '--neutral-v700': m('--palette-black-v200'),
  '--semantic-error-color': m('--palette-red-v200'),
  '--semantic-focus-color': '#275Dc5',
};

/**
 * The font ramp defined by designers.
 *
 * Latest update on May 2023.
 *
 * Based on
 * https://www.figma.com/file/cmEPXseMWuut238S1Pj1Mn/SDS-M-New-Components-%26-Updates?node-id=4057%3A273201&mode=dev
 *
 * DO NOT MODIFY WITHOUT DESIGN APPROVAL.
 */
export const defaultMotifFontRamp = {
  '--font-family': 'Graphik, Helvetica, Tahoma, Arial, sans-serif',
  // Regular text.
  '--text-desktop-font-size': '18px',
  '--text-desktop-font-line-height': '24px',
  '--text-desktop-font-weight': '400',
  '--text-mobile-font-size': '16px',
  '--text-mobile-font-line-height': '120%',
  '--text-mobile-font-weight': '400',
  // H1
  '--h1-desktop-font-size': '56px',
  '--h1-desktop-font-line-height': '120%',
  '--h1-desktop-font-weight': '600',
  '--h1-desktop-font-stretch': 'normal',
  '--h1-mobile-font-size': '40px',
  '--h1-mobile-font-line-height': '120%',
  '--h1-mobile-font-weight': '600',
  '--h1-mobile-font-stretch': 'normal',
  // H2
  '--h2-desktop-font-size': '48px',
  '--h2-desktop-font-line-height': '120%',
  '--h2-desktop-font-weight': '600',
  '--h2-desktop-font-stretch': 'normal',
  '--h2-mobile-font-size': '32px',
  '--h2-mobile-font-line-height': '120%',
  '--h2-mobile-font-weight': '600',
  '--h2-mobile-font-stretch': 'normal',
  // H3
  '--h3-desktop-font-size': '40px',
  '--h3-desktop-font-line-height': '120%',
  '--h3-desktop-font-weight': '600',
  '--h3-desktop-font-stretch': 'normal',
  '--h3-mobile-font-size': '28px',
  '--h3-mobile-font-line-height': '120%',
  '--h3-mobile-font-weight': '600',
  '--h3-mobile-font-stretch': 'normal',
  // H4
  '--h4-desktop-font-size': '28px',
  '--h4-desktop-font-line-height': '120%',
  '--h4-desktop-font-weight': '500',
  '--h4-desktop-font-stretch': 'normal',
  '--h4-mobile-font-size': '24px',
  '--h4-mobile-font-line-height': '120%',
  '--h4-mobile-font-weight': '500',
  '--h4-mobile-font-stretch': 'normal',
  // H5
  '--h5-desktop-font-size': '24px',
  '--h5-desktop-font-line-height': '120%',
  '--h5-desktop-font-weight': '500',
  '--h5-desktop-font-stretch': 'normal',
  '--h5-mobile-font-size': '20px',
  '--h5-mobile-font-line-height': '120%',
  '--h5-mobile-font-weight': '500',
  '--h5-mobile-font-stretch': 'normal',
  // H6
  '--h6-desktop-font-size': '18px',
  '--h6-desktop-font-line-height': '120%',
  '--h6-desktop-font-weight': '500',
  '--h6-desktop-font-stretch': 'normal',
  '--h6-mobile-font-size': '18px',
  '--h6-mobile-font-line-height': '120%',
  '--h6-mobile-font-weight': '500',
  '--h6-mobile-font-stretch': 'normal',
  // P1
  '--p1-desktop-font-size': '20px',
  '--p1-desktop-font-line-height': '160%',
  '--p1-desktop-font-weight': '400',
  '--p1-desktop-font-stretch': 'normal',
  '--p1-mobile-font-size': '18px',
  '--p1-mobile-font-line-height': '160%',
  '--p1-mobile-font-weight': '400',
  '--p1-mobile-font-stretch': 'normal',
  // P2
  '--p2-desktop-font-size': '16px',
  '--p2-desktop-font-line-height': '160%',
  '--p2-desktop-font-weight': '400',
  '--p2-desktop-font-stretch': 'normal',
  '--p2-mobile-font-size': '16px',
  '--p2-mobile-font-line-height': '160%',
  '--p2-mobile-font-weight': '400',
  '--p2-mobile-font-stretch': 'normal',
  // P3
  '--p3-desktop-font-size': '14px',
  '--p3-desktop-font-line-height': '160%',
  '--p3-desktop-font-weight': '400',
  '--p3-desktop-font-stretch': 'normal',
  '--p3-mobile-font-size': '14px',
  '--p3-mobile-font-line-height': '160%',
  '--p3-mobile-font-weight': '400',
  '--p3-mobile-font-stretch': 'normal',
  // P4
  '--p4-desktop-font-size': '12px',
  '--p4-desktop-font-line-height': '160%',
  '--p4-desktop-font-weight': '400',
  '--p4-desktop-font-stretch': 'normal',
  '--p4-mobile-font-size': '12x',
  '--p4-mobile-font-line-height': '160%',
  '--p4-mobile-font-weight': '400',
  '--p4-mobile-font-stretch': 'normal',
  // Button / Hyperlink Font (Special. Shared)
  '--action-desktop-font-size': '16px',
  '--action-desktop-font-line-height': '24px',
  '--action-desktop-font-weight': '500',
  '--action-desktop-font-letter-spacing': 'normal',
  '--action-mobile-font-size': '16px',
  '--action-mobile-font-line-height': '120%',
  '--action-mobile-font-weight': '500',
  '--action-mobile-font-letter-spacing': 'normal',
  // Eyebrow / Annotation Font (Special. Shared)
  '--annotation-desktop-font-size': '14px',
  '--annotation-desktop-font-weight': '500',
  '--annotation-desktop-font-line-height': '20px',
  '--annotation-desktop-font-letter-spacing': '-.14px',
  '--annotation-mobile-font-size': '14px',
  '--annotation-mobile-font-weight': '500',
  '--annotation-mobile-font-line-height': '20px',
  '--annotation-mobile-font-letter-spacing': '-.14px',
};

const lightBgShadowVars = {
  '--box-shadow-xs': '0px 2px 4px 0 rgba(0, 0, 0, 0.12)',
  '--box-shadow-s': '0px 4px 8px 0 rgba(0, 0, 0, 0.12)',
  '--box-shadow-m': '0px 8px 16px 0 rgba(0, 0, 0, 0.12)',
  '--box-shadow-l': '0px 12px 20px 0 rgba(0, 0, 0, 0.12)',
  '--box-shadow-xl': '0px 16px 32px 0 rgba(0, 0, 0, 0.12)',
};

const darkBgShadowVars = {
  '--box-shadow-xs': '0px 2px 4px 0 rgba(255, 255, 255, 0.12)',
  '--box-shadow-s': '0px 4px 8px 0 rgba(255, 255, 255, 0.12)',
  '--box-shadow-m': '0px 8px 16px 0 rgba(255, 255, 255, 0.12)',
  '--box-shadow-l': '0px 12px 20px 0 rgba(255, 255, 255, 0.12)',
  '--box-shadow-xl': '0px 16px 32px 0 rgba(255, 255, 255, 0.12)',
};

const noVars = {} as const;

export const defaultMotif: Motif = {
  name: 'DefaultMotif',
  fontFamily: FontFamily.GRAPHIK,
  // ===========================================================================
  // Default Color Scheme - Yellow background. Light.
  // ===========================================================================
  [MotifScheme.DEFAULT]: {
    name: 'Yellow background',
    legacyName: BackgroundColor.Yellow,
    [MotifComponent.ROOT]: {
      ...lightBgShadowVars,
      ...defaultColorPrimitiveVars,
      ...defaultBorderRadii,
      ...defaultBorderWidthVars,
      ...defaultMotifSizes,
      ...defaultMotifFontRamp, // Has to come before action-*-font-* stuff.
      '--bg-color': m('--primary-v100'),
      '--bg-image': 'none',
      '--fg-color': m('--neutral-v700'),
      '--action-default-color': m('--neutral-v600'),
      '--action-hover-color': m('--neutral-v650'),
      '--action-active-color': m('--neutral-v700'),
      '--action-desktop-font-text-decoration': 'none',
      '--action-mobile-font-text-decoration': 'none',
    } as const,
    [MotifComponent.BUTTON]: contrastYellowButtonVars,
    [MotifComponent.HEADER]: yellowThemeHeaderVars,
    [MotifComponent.BLOCK]: defaultBlockVars,
    [MotifComponent.BLOCK_BOUNDARY]: noVars,
    [MotifComponent.BLOCK_SPLIT_PANEL]: noVars,
    [MotifComponent.DETAIL_SUMMARY]: noVars,
    [MotifComponent.TABS]: darkTabVars,
    [MotifComponent.HERO]: heroLightBgVars,
    [MotifComponent.PAGE]: noVars,
    [MotifComponent.CONTENT]: contentVars,
    [MotifComponent.TOGGLE_SLIDER]: toggleSliderYellowVars,
    [MotifComponent.BREAK]: defaultBreakVars,
    [MotifComponent.QUOTE]: quoteWhiteBgVars,
    [MotifComponent.CODE]: noVars,
    [MotifComponent.FILTER_DROPDOWN_MENU]: noVars,
    [MotifComponent.FOOTER]: footerYellowBgVars,
    [MotifComponent.DROPDOWN_MENU]: dropdownMenuLightVars,
    [MotifComponent.IMAGE_BUTTON]: noVars,
    [MotifComponent.TABLE]: noVars,
    [MotifComponent.TOGGLE_BUTTON]: toggleButtonYellowVars,
    [MotifComponent.ACCORDION]: accordionYellowBgVars,
    [MotifComponent.FOOTNOTE]: footnoteVars,
    [MotifComponent.TILE]: defaultTileVars,
    [MotifComponent.MEDIA]: mediaVars,
    [MotifComponent.SUMMARY_CARD]: summaryCardVars,
    [MotifComponent.BANNER]: bannerYellowBgVars,
    [MotifComponent.HYPERLINK]: hyperLinkVarsBg,
    [MotifComponent.SEARCH]: searchDefaultVars,
    [MotifComponent.CAROUSEL]: contrastYellowCarouselVars,
    [MotifComponent.PAGINATION]: paginationLightBgVars,
    [MotifComponent.SNAPCHAT_EMBED]: noVars,
    [MotifComponent.SIDE_NAVIGATION]: yellowThemeSideNavVars,
    [MotifComponent.MODAL]: modalLightBgVars,
    [MotifComponent.MOSAIC]: mosaicLightBgVars,
    [MotifComponent.SPINNER]: spinnerLightBgVars,
    [MotifComponent.ICON_BUTTON]: contrastWhiteIconButtonVars,
    [MotifComponent.TOOLTIP]: noVars,
    [MotifComponent.ICON]: iconDefaultVars,
    [MotifComponent.SOCIAL]: noVars,
    [MotifComponent.FORM]: formVars,
    [MotifComponent.SUB_NAVIGATION]: subNavigationYellowBgVars,
    [MotifComponent.PROGRESS_BAR]: progressBarYellowBgVars,
    [MotifComponent.CHART_TOGGLE]: chartToggleYellowBgVars,
    [MotifComponent.BLOCK_NAVIGATION]: blockNavigationYellowBgVars,
    [MotifComponent.LOGO]: logoLightBgVars,
    [MotifComponent.BAR_CHART]: noVars,
    [MotifComponent.LINE_CHART]: noVars,
    [MotifComponent.GEO_MAP]: noVars,
    [MotifComponent.MULTI_VIDEO_BLOCK]: noVars,
    [MotifComponent.TOGGLE_PANEL]: togglePanelYellowBgVars,
    [MotifComponent.GALLERY]: noVars,
    [MotifComponent.PRIMITIVE_TABLE]: primitiveTableYellowBgVars,
    [MotifComponent.LOADING_BAR]: defaultLoadingBarVars,
    [MotifComponent.AUTOCOMPLETE]: defaultAutoCompleteVars,
    [MotifComponent.SPLIT_BLOCK]: noVars,
    [MotifComponent.CHART_SKELETON]: chartSkeletonLightVars,
    [MotifComponent.DEFINITION]: definitionYellowBgVars,
    [MotifComponent.SIDE_OVERLAY]: sideOverlayYellowBgVars,
    [MotifComponent.MULTI_VALUE_PROP_BLOCK]: noVars,
  } as const,
  // ===========================================================================
  // Secondary Color Scheme - Black background. Dark.
  // ===========================================================================
  [MotifScheme.SECONDARY]: {
    name: 'Black background',
    legacyName: BackgroundColor.Black,
    [MotifComponent.ROOT]: {
      '--bg-color': m('--neutral-v700'),
      '--bg-image': 'none',
      '--fg-color': m('--neutral-v0'),
      '--action-default-color': m('--neutral-v300'),
      '--action-hover-color': m('--neutral-v250'),
      '--action-active-color': m('--neutral-v0'),
      ...darkBgShadowVars,
    },
    [MotifComponent.BUTTON]: contrastBlackButtonVars,
    [MotifComponent.HEADER]: darkThemeHeaderVars,
    // Inherits BLOCK from default.
    [MotifComponent.BLOCK]: defaultBlockVars,
    [MotifComponent.TABS]: lightTabVars,
    [MotifComponent.TOGGLE_SLIDER]: toggleSliderBlackVars,
    [MotifComponent.TOGGLE_BUTTON]: toggleButtonBlackVars,
    [MotifComponent.QUOTE]: quoteGrayBgVars,
    [MotifComponent.FOOTER]: footerBlackBgVars,
    [MotifComponent.ACCORDION]: accordionBlackBgVars,
    [MotifComponent.DROPDOWN_MENU]: dropdownMenuDarkVars,
    [MotifComponent.BANNER]: bannerBlackBgVars,
    [MotifComponent.CAROUSEL]: contrastBlackCarouselVars,
    [MotifComponent.HYPERLINK]: hyperLinkVarsBg,
    [MotifComponent.PAGINATION]: paginationDarkBgVars,
    [MotifComponent.SIDE_NAVIGATION]: blackThemeSideNavVars,
    [MotifComponent.HERO]: heroDarkBgVars,
    [MotifComponent.MODAL]: modalDarkBgVars,
    [MotifComponent.SPINNER]: spinnerDarkBgVars,
    [MotifComponent.SUB_NAVIGATION]: subNavigationBlackBgVars,
    [MotifComponent.PROGRESS_BAR]: progressBarBlackBgVars,
    [MotifComponent.CHART_TOGGLE]: chartToggleBlackBgVars,
    [MotifComponent.BLOCK_NAVIGATION]: blockNavigationBlackBgVars,
    [MotifComponent.BREAK]: defaultBreakVars,
    [MotifComponent.CONTENT]: contentVars,
    [MotifComponent.MOSAIC]: mosaicDarkBgVars,
    [MotifComponent.MEDIA]: mediaVars,
    [MotifComponent.ICON]: iconDefaultVars,
    [MotifComponent.ICON_BUTTON]: contrastBlackIconButtonVars,
    [MotifComponent.FORM]: contrastBlackFormVars,
    [MotifComponent.LOGO]: logoDarkBgVars,
    [MotifComponent.PRIMITIVE_TABLE]: primitiveTableDarkBgVars,
    [MotifComponent.SUMMARY_CARD]: contrastBlackSummaryCardVars,
    [MotifComponent.AUTOCOMPLETE]: contrastBlackAutoCompleteVars,
    [MotifComponent.SPLIT_BLOCK]: noVars,
    [MotifComponent.CHART_SKELETON]: chartSkeletonDarkVars,
    [MotifComponent.DEFINITION]: definitionBlackBgVars,
    [MotifComponent.SIDE_OVERLAY]: sideOverlayBlackBgVars,
    [MotifComponent.FOOTNOTE]: footnoteVars,
    [MotifComponent.SEARCH]: searchDefaultVars,
    [MotifComponent.TILE]: defaultTileVars,
    [MotifComponent.TOGGLE_PANEL]: noVars,
  } as const,
  // ===========================================================================
  // Tertiary Color Scheme - White background. Light.
  // ===========================================================================
  [MotifScheme.TERTIARY]: {
    name: 'White background',
    legacyName: BackgroundColor.White,
    [MotifComponent.ROOT]: {
      '--bg-color': m('--neutral-v0'),
      '--bg-image': 'none',
      '--fg-color': m('--neutral-v700'),
      '--action-default-color': m('--neutral-v600'),
      '--action-hover-color': m('--neutral-v650'),
      '--action-active-color': m('--neutral-v700'),
      ...lightBgShadowVars,
    },
    [MotifComponent.BUTTON]: contrastWhiteButtonVars,
    [MotifComponent.HEADER]: lightThemeHeaderVars,
    // Inherits BLOCK from default.
    [MotifComponent.BLOCK]: defaultBlockVars,
    [MotifComponent.TABS]: darkTabVars,
    [MotifComponent.TOGGLE_SLIDER]: toggleSliderWhiteVars,
    [MotifComponent.TOGGLE_BUTTON]: toggleButtonYellowVars,
    [MotifComponent.QUOTE]: quoteGrayBgVars,
    [MotifComponent.FOOTER]: footerWhiteBgVars,
    [MotifComponent.ACCORDION]: accordionWhiteBgVars,
    [MotifComponent.DROPDOWN_MENU]: dropdownMenuLightVars,
    [MotifComponent.BANNER]: bannerWhiteBgVars,
    [MotifComponent.CAROUSEL]: contrastWhiteCarouselVars,
    [MotifComponent.HYPERLINK]: hyperLinkVarsBg,
    [MotifComponent.PAGINATION]: paginationLightBgVars,
    [MotifComponent.SIDE_NAVIGATION]: whiteThemeSideNavVars,
    [MotifComponent.SUB_NAVIGATION]: subNavigationWhiteBgVars,
    [MotifComponent.PROGRESS_BAR]: progressBarWhiteBgVars,
    [MotifComponent.CHART_TOGGLE]: chartToggleWhiteBgVars,
    [MotifComponent.BLOCK_NAVIGATION]: blockNavigationWhiteBgVars,
    [MotifComponent.BREAK]: defaultBreakVars,
    [MotifComponent.HERO]: heroLightBgVars,
    [MotifComponent.MEDIA]: mediaVars,
    [MotifComponent.CONTENT]: contentVars,
    [MotifComponent.MOSAIC]: mosaicLightBgVars,
    [MotifComponent.ICON]: iconDefaultVars,
    [MotifComponent.ICON_BUTTON]: contrastWhiteIconButtonVars,
    [MotifComponent.FORM]: contrastGrayFormVars,
    [MotifComponent.LOGO]: logoLightBgVars,
    [MotifComponent.TOGGLE_PANEL]: togglePanelWhiteBgVars,
    [MotifComponent.PRIMITIVE_TABLE]: primitiveTableLightBgVars,
    [MotifComponent.SUMMARY_CARD]: summaryCardVars,
    [MotifComponent.AUTOCOMPLETE]: defaultAutoCompleteVars,
    [MotifComponent.SPLIT_BLOCK]: noVars,
    [MotifComponent.CHART_SKELETON]: chartSkeletonLightVars,
    [MotifComponent.DEFINITION]: definitionWhiteBgVars,
    [MotifComponent.SIDE_OVERLAY]: sideOverlayWhiteBgVars,
    [MotifComponent.FOOTNOTE]: footnoteVars,
    [MotifComponent.TILE]: defaultTileVars,
    [MotifComponent.SPINNER]: spinnerLightBgVars,
    [MotifComponent.SEARCH]: searchDefaultVars,
    [MotifComponent.MODAL]: modalLightBgVars,
  } as const,
  // ===========================================================================
  // Quaternary Color Scheme - Gray background. Light.
  // ===========================================================================
  [MotifScheme.QUATERNARY]: {
    name: 'Gray background',
    legacyName: BackgroundColor.Gray,
    [MotifComponent.ROOT]: {
      '--bg-color': m('--neutral-v150'),
      '--bg-image': 'none',
      '--fg-color': m('--neutral-v700'),
      '--action-default-color': m('--neutral-v600'),
      '--action-hover-color': m('--neutral-v650'),
      '--action-active-color': m('--neutral-v700'),
      ...lightBgShadowVars,
    },
    [MotifComponent.BUTTON]: contrastGrayButtonVars,
    [MotifComponent.HEADER]: lightThemeHeaderVars,
    [MotifComponent.BLOCK]: defaultBlockVars,
    [MotifComponent.TABS]: darkTabVars,
    [MotifComponent.TOGGLE_SLIDER]: toggleSliderDarkVars,
    [MotifComponent.TOGGLE_BUTTON]: toggleButtonGrayVars,
    [MotifComponent.QUOTE]: quoteWhiteBgVars,
    [MotifComponent.FOOTER]: footerGrayBgVars,
    [MotifComponent.ACCORDION]: accordionGrayBgVars,
    [MotifComponent.DROPDOWN_MENU]: dropdownMenuLightVars,
    [MotifComponent.BANNER]: bannerGrayBgVars,
    [MotifComponent.HYPERLINK]: hyperLinkVarsBg,
    [MotifComponent.PAGINATION]: paginationLightBgVars,
    [MotifComponent.CAROUSEL]: contrastGrayCarouselVars,
    [MotifComponent.SIDE_NAVIGATION]: grayThemeSideNavVars,
    [MotifComponent.SUB_NAVIGATION]: subNavigationGrayBgVars,
    [MotifComponent.PROGRESS_BAR]: progressBarGrayBgVars,
    [MotifComponent.CHART_TOGGLE]: chartToggleGrayBgVars,
    [MotifComponent.BLOCK_NAVIGATION]: blockNavigationGrayBgVars,
    [MotifComponent.BREAK]: defaultBreakVars,
    [MotifComponent.CONTENT]: contentVars,
    [MotifComponent.HERO]: heroLightBgVars,
    [MotifComponent.MEDIA]: mediaVars,
    [MotifComponent.MOSAIC]: mosaicLightBgVars,
    [MotifComponent.ICON]: iconDefaultVars,
    [MotifComponent.ICON_BUTTON]: contrastWhiteIconButtonVars,
    [MotifComponent.FORM]: contrastGrayFormVars,
    [MotifComponent.LOGO]: logoLightBgVars,
    [MotifComponent.TOGGLE_PANEL]: togglePanelWhiteBgVars,
    [MotifComponent.PRIMITIVE_TABLE]: primitiveTableGrayBgVars,
    [MotifComponent.SUMMARY_CARD]: summaryCardVars,
    [MotifComponent.AUTOCOMPLETE]: constrastGrayAutoCompleteVars,
    [MotifComponent.SPLIT_BLOCK]: noVars,
    [MotifComponent.CHART_SKELETON]: chartSkeletonLightVars,
    [MotifComponent.DEFINITION]: definitionGrayBgVars,
    [MotifComponent.SIDE_OVERLAY]: sideOverlayGrayBgVars,
    [MotifComponent.FOOTNOTE]: footnoteVars,
    [MotifComponent.TILE]: defaultTileVars,
    [MotifComponent.SPINNER]: spinnerLightBgVars,
    [MotifComponent.SEARCH]: searchDefaultVars,
    [MotifComponent.MODAL]: modalLightBgVars,
  } as const,
} as const;
