import type { ContentfulImageApiParams } from './getImageUrl';
import type { SrcSetSettings } from './useContentfulImages';

const maxSupportedDpr = 2;

interface DprData {
  /** Used to store the always used default 1x dpr settings */
  baseDprSettings: ContentfulImageApiParams;
  /** Secondary DPR setting to support */
  additionalDpr?: {
    dpr: number;
    settings: ContentfulImageApiParams;
  };
}

export const getDprDataByHeight = (defaultHeight: number, assetHeight: number): DprData => {
  const result: DprData = {
    baseDprSettings: { height: defaultHeight },
  };

  if (assetHeight <= defaultHeight) {
    return result;
  }

  // If asset is less than default height, but not big enough to handle our max supported DPR
  // calculate optimal size
  if (defaultHeight < assetHeight && assetHeight < defaultHeight * maxSupportedDpr) {
    result.additionalDpr = {
      dpr: Number.parseFloat((assetHeight / defaultHeight).toFixed(4)),
      settings: { height: assetHeight },
    };

    return result;
  }

  result.additionalDpr = {
    dpr: maxSupportedDpr,
    settings: { height: defaultHeight * maxSupportedDpr },
  };

  return result;
};

export const getDprSrcSetSettingsByHeight = (
  defaultHeight: number,
  assetHeight: number,
  quality?: number
): SrcSetSettings => {
  const dprData = getDprDataByHeight(defaultHeight, assetHeight);

  // if there is no additional DPR data (only base setting) return a single image setting
  if (!dprData.additionalDpr) {
    return { image: { height: defaultHeight, quality } };
  }

  return {
    size: {
      sizeToUrl: [
        {
          size: '1x',
          settings: { ...dprData.baseDprSettings, quality },
        },
        {
          size: `${dprData.additionalDpr.dpr}x`,
          settings: { ...dprData.additionalDpr.settings, quality },
        },
      ],
    },
  };
};
