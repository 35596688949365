import { css } from '@emotion/css';

import { ZIndex } from '../../constants';
import { m } from '../../motif';

const baseStyleCss = css`
  border-radius: 0px 0px ${m('--border-radius-s')} ${m('--border-radius-s')};
  border-style: solid;
  border-width: ${m('--border-width-xs')};
  border-color: ${m('--dropdown-menu-border-color')};
  box-shadow: ${m('--box-shadow-l')};
  margin-top: -4px;
  max-height: 50vh;
  min-width: 170px;
  overflow: auto;
  padding: calc(${m('--dropdown-menu-padding')} / 2) 0;
  text-decoration: none;
`;

const colorStylesCss = css`
  background-color: ${m('--dropdown-menu-bg-color')};
  color: ${m('--dropdown-item-fg-color')};
`;

export const dropdownMenuCss = css`
  ${baseStyleCss}
  ${colorStylesCss}
  position: absolute;
  width: max-content;
  max-width: 100%;
  z-index: ${ZIndex.DROPDOWN};
`;

export const dropdownContainerCss = css`
  position: relative;
  display: inline-block;
`;

// TODO: Fix this. Returning CSS from a function is an anti-pattern.
export const getVerticalDirectionStyle = (renderDownSize: number): string | undefined => {
  if (renderDownSize === -1) {
    return undefined;
  }

  return css`
    bottom: ${renderDownSize}px;
    border-radius: ${m('--border-radius-s')} ${m('--border-radius-s')} 0 0;
  `;
};

export const menuLeftCss = css`
  left: 0;
`;

export const menuRightCss = css`
  right: 0;
`;
