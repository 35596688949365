import type { MotifComponent, MotifComponentProps } from '../../motif';
import { m } from '../../motif/m';

export const toggleSliderBlackVars: MotifComponentProps<MotifComponent.TOGGLE_SLIDER> = {
  '--toggle-slider-bg-color': m('--neutral-v150'),
  '--toggle-slider-active-color': m('--primary-v100'),
  '--toggle-slider-switch-color': m('--neutral-v0'),
};

export const toggleSliderDarkVars: MotifComponentProps<MotifComponent.TOGGLE_SLIDER> = {
  '--toggle-slider-bg-color': m('--neutral-v700'),
  '--toggle-slider-active-color': m('--primary-v100'),
  '--toggle-slider-switch-color': m('--neutral-v0'),
};

export const toggleSliderYellowVars: MotifComponentProps<MotifComponent.TOGGLE_SLIDER> = {
  '--toggle-slider-bg-color': m('--neutral-v250'),
  '--toggle-slider-active-color': m('--neutral-v700'),
  '--toggle-slider-switch-color': m('--neutral-v0'),
};

export const toggleSliderWhiteVars: MotifComponentProps<MotifComponent.TOGGLE_SLIDER> = {
  '--toggle-slider-bg-color': m('--neutral-v500'),
  '--toggle-slider-active-color': m('--primary-v100'),
  '--toggle-slider-switch-color': m('--neutral-v0'),
};

export const toggleSliderLightVars: MotifComponentProps<MotifComponent.TOGGLE_SLIDER> = {
  '--toggle-slider-bg-color': m('--neutral-v0'),
  '--toggle-slider-active-color': m('--primary-v100'),
  '--toggle-slider-switch-color': m('--neutral-v500'),
};
