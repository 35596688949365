import { css } from '@emotion/css';
import { h3Css, m } from '@snapchat/snap-design-system-marketing';

import { totalHeaderHeightCssVar } from '../Header/headerSizeUtils';

export const galleryWrapperCss = css`
  margin-left: auto;
  margin-right: auto;
  margin-top: ${m('--spacing-m')};
  margin-bottom: ${m('--spacing-m')};
  scroll-margin-top: var(${totalHeaderHeightCssVar});
  width: 100%;
`;

export const galleryPlaceholderCss = css`
  text-align: center;
  margin-top: ${m('--spacing-xl')};
  margin-bottom: ${m('--spacing-xl')};

  ${h3Css}
`;

export const buttonWrapperCss = css`
  padding-top: ${m('--spacing-xl')};
  display: flex;
  justify-content: center;
`;
