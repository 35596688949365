import { css } from '@emotion/css';
import { m, Spacing } from '@snapchat/snap-design-system-marketing';

import { mediaQuery } from '../../styles/mediaQueries';

export const disabledButtonCss = css`
  color: ${m('--palette-gray-v300')};
  border: 1px solid ${m('--palette-gray-v300')};
  background-color: ${m('--palette-gray-v200')};
`;

export const addToCartButtonCss = css`
  position: relative;
  margin-top: ${Spacing.MD}px;
`;

export const addToCartTooltipCss = css`
  background: ${m('--palette-black-v200')};
  border-radius: 6px;
  box-shadow: 0px 6px 12px 4px rgba(0, 0, 0, 0.1);
  color: ${m('--palette-plain-white')};
  font-size: 14px;
  font-weight: 700;
  position: absolute;
  bottom: 125%;
  left: 0;
  padding: 6px 7px;
  text-align: center;
  width: 100%;

  &:after {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: ${m('--palette-black-v200')} transparent transparent transparent;
  }

  ${mediaQuery.over768_desktop_small} {
    padding: 6px ${Spacing.XS}px;
  }
`;
