import type { MotifComponent, MotifComponentProps } from '../../motif';
import { m } from '../../motif/m';

export const footerBlackBgVars: MotifComponentProps<MotifComponent.FOOTER> = {
  '--footer-bg-color': m('--neutral-v700'),
  '--footer-fg-color': m('--fg-color'),
  '--footer-border-color': m('--neutral-v500'),
  '--footer-divider-border-color': m('--neutral-v500'),
  '--footer-bar-bg-color': m('--neutral-v700'),
  '--footer-bar-fg-color': m('--fg-color'),
  '--footer-bar-divider-border-color': m('--neutral-v500'),
};

export const footerYellowBgVars: MotifComponentProps<MotifComponent.FOOTER> = {
  '--footer-bg-color': m('--primary-v100'),
  '--footer-fg-color': m('--fg-color'),
  '--footer-border-color': m('--neutral-v500'),
  '--footer-divider-border-color': m('--neutral-v500'),
  '--footer-bar-bg-color': m('--primary-v100'),
  '--footer-bar-fg-color': m('--fg-color'),
  '--footer-bar-divider-border-color': m('--neutral-v500'),
};

export const footerWhiteBgVars: MotifComponentProps<MotifComponent.FOOTER> = {
  '--footer-bg-color': m('--neutral-v0'),
  '--footer-fg-color': m('--fg-color'),
  '--footer-border-color': m('--neutral-v300'),
  '--footer-divider-border-color': m('--neutral-v300'),
  '--footer-bar-bg-color': m('--neutral-v150'),
  '--footer-bar-fg-color': m('--fg-color'),
  '--footer-bar-divider-border-color': m('--neutral-v150'),
};

export const footerGrayBgVars: MotifComponentProps<MotifComponent.FOOTER> = {
  '--footer-bg-color': m('--neutral-v150'),
  '--footer-fg-color': m('--fg-color'),
  '--footer-border-color': m('--neutral-v300'),
  '--footer-divider-border-color': m('--neutral-v300'),
  '--footer-bar-fg-color': m('--fg-color'),
  '--footer-bar-bg-color': m('--neutral-v150'),
  '--footer-bar-divider-border-color': m('--neutral-v300'),
};
