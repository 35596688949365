/* eslint-disable typescript-sort-keys/string-enum */

export enum MotifComponent {
  ROOT = 'sdsm',
  BUTTON = 'sdsm-button',
  HEADER = 'sdsm-header',
  BLOCK = 'sdsm-block',
  BLOCK_BOUNDARY = 'sdsm-block-boundary',
  BLOCK_SPLIT_PANEL = 'sdsm-block-split-panel',
  DETAIL_SUMMARY = 'sdsm-detail-summary',
  TABS = 'sdsm-tab',
  PAGE = 'sdsm-page',
  HERO = 'sdsm-hero',
  BREAK = 'sdsm-break',
  CONTENT = 'sdsm-content',
  QUOTE = 'sdsm-quote',
  CODE = 'sdsm-code',
  FILTER_DROPDOWN_MENU = 'sdsm-filter-dropdown-menu',
  FOOTER = 'sdsm-footer',
  IMAGE_BUTTON = 'sdsm-image-button',
  DROPDOWN_MENU = 'sdsm-dropdown-menu',
  TABLE = 'sdsm-table',
  TOGGLE_SLIDER = 'sdsm-toggle-slider',
  TOGGLE_BUTTON = 'sdsm-toggle-button',
  ACCORDION = 'sdsm-accordion',
  CAROUSEL = 'sdsm-carousel',
  FOOTNOTE = 'sdsm-footnote',
  BANNER = 'sdsm-banner',
  TILE = 'sdsm-tile',
  MEDIA = 'sdsm-media',
  HYPERLINK = 'sdsm-hyperlink',
  SPINNER = 'sdsm-spinner',
  TOOLTIP = 'sdsm-tooltip',
  SEARCH = 'sdsm-search',
  SUMMARY_CARD = 'sdsm-summary-card',
  PAGINATION = 'sdsm-pagination',
  SNAPCHAT_EMBED = 'sdsm-snapchat-embed',
  SUB_NAVIGATION = 'sdsm-sub-navigation',
  SIDE_NAVIGATION = 'sdsm-side-navigation',
  ICON_BUTTON = 'sdsm-icon-button',
  MOSAIC = 'sdsm-mosaic',
  MODAL = 'sdsm-modal',
  ICON = 'sdsm-icon',
  FORM = 'sdsm-form',
  PROGRESS_BAR = 'sdsm-progress-bar',
  CHART_TOGGLE = 'sdsm-chart-toggle',
  BLOCK_NAVIGATION = 'sdsm-block-navigation',
  LOGO = 'sdsm-logo',
  BAR_CHART = 'sdsm-bar-chart',
  LINE_CHART = 'sdsm-line-chat',
  GEO_MAP = 'sdsm-geo-map',
  MULTI_VIDEO_BLOCK = 'sdsm-multi-video-block',
  SOCIAL = 'sdsm-social',
  TOGGLE_PANEL = 'sdsm-toggle-panel',
  GALLERY = 'sdsm-gallery',
  PRIMITIVE_TABLE = 'sdsm-primitive-table',
  LOADING_BAR = 'sdsm-loading-bar',
  AUTOCOMPLETE = 'sdsm-autocomplete',
  SPLIT_BLOCK = 'sdsm-split-block',
  CHART_SKELETON = 'sdsm-skeleton-chart',
  DEFINITION = 'sdsm-definition',
  SIDE_OVERLAY = 'sdsm-side-overlay',
  MULTI_VALUE_PROP_BLOCK = 'sdsm-multi-value-prop-block',
}

export const motifSizeRampVariables = [
  '--spacing-xxxs',
  '--spacing-xxs',
  '--spacing-xs',
  '--spacing-s',
  '--spacing-m',
  '--spacing-l',
  '--spacing-xl',
  '--spacing-xxl',
  '--spacing-xxxl',
  '--spacing-xxxxl',
] as const;

/** Color primitives within the system */

export const motifColorPrimitiveVariables = [
  '--palette-plain-black',
  '--palette-plain-transparent',
  '--palette-plain-white',
  '--palette-black-v50',
  '--palette-black-v100',
  '--palette-black-v125',
  '--palette-black-v150',
  '--palette-black-v200',
  '--palette-yellow-v50',
  '--palette-yellow-v100',
  '--palette-yellow-v150',
  '--palette-yellow-v200',
  '--palette-gray-v50',
  '--palette-gray-v100',
  '--palette-gray-v150',
  '--palette-gray-v200',
  '--palette-gray-v250',
  '--palette-gray-v300',
  '--palette-orange-v50',
  '--palette-orange-v100',
  '--palette-orange-v150',
  '--palette-orange-v200',
  '--palette-red-v50',
  '--palette-red-v100',
  '--palette-red-v150',
  '--palette-red-v200',
  '--palette-green-v50',
  '--palette-green-v100',
  '--palette-green-v150',
  '--palette-green-v200',
  '--palette-purple-v50',
  '--palette-purple-v100',
  '--palette-purple-v150',
  '--palette-purple-v200',
  '--palette-blue-v50',
  '--palette-blue-v100',
  '--palette-blue-v150',
  '--palette-blue-v200',
  '--primary-v50',
  '--primary-v100',
  '--primary-v150',
  '--primary-v200',
  '--neutral-v0',
  '--neutral-v100',
  '--neutral-v150',
  '--neutral-v200',
  '--neutral-v250',
  '--neutral-v300',
  '--neutral-v400',
  '--neutral-v500',
  '--neutral-v600',
  '--neutral-v625',
  '--neutral-v650',
  '--neutral-v700',
  '--semantic-error-color',
  '--semantic-focus-color',
] as const;

export const motifFontRampVariables = [
  '--font-family',
  // Regular text.
  '--text-desktop-font-size',
  '--text-desktop-font-line-height',
  '--text-desktop-font-weight',
  '--text-mobile-font-size',
  '--text-mobile-font-line-height',
  '--text-mobile-font-weight',
  // H1
  '--h1-desktop-font-size',
  '--h1-desktop-font-line-height',
  '--h1-desktop-font-weight',
  '--h1-desktop-font-stretch',
  '--h1-mobile-font-size',
  '--h1-mobile-font-line-height',
  '--h1-mobile-font-weight',
  '--h1-mobile-font-stretch',
  // H2
  '--h2-desktop-font-size',
  '--h2-desktop-font-line-height',
  '--h2-desktop-font-weight',
  '--h2-desktop-font-stretch',
  '--h2-mobile-font-size',
  '--h2-mobile-font-line-height',
  '--h2-mobile-font-weight',
  '--h2-mobile-font-stretch',
  // H3
  '--h3-desktop-font-size',
  '--h3-desktop-font-line-height',
  '--h3-desktop-font-weight',
  '--h3-desktop-font-stretch',
  '--h3-mobile-font-size',
  '--h3-mobile-font-line-height',
  '--h3-mobile-font-weight',
  '--h3-mobile-font-stretch',
  // H4
  '--h4-desktop-font-size',
  '--h4-desktop-font-line-height',
  '--h4-desktop-font-weight',
  '--h4-desktop-font-stretch',
  '--h4-mobile-font-size',
  '--h4-mobile-font-line-height',
  '--h4-mobile-font-weight',
  '--h4-mobile-font-stretch',
  // H5
  '--h5-desktop-font-size',
  '--h5-desktop-font-line-height',
  '--h5-desktop-font-weight',
  '--h5-desktop-font-stretch',
  '--h5-mobile-font-size',
  '--h5-mobile-font-line-height',
  '--h5-mobile-font-weight',
  '--h5-mobile-font-stretch',
  // H6
  '--h6-desktop-font-size',
  '--h6-desktop-font-line-height',
  '--h6-desktop-font-weight',
  '--h6-desktop-font-stretch',
  '--h6-mobile-font-size',
  '--h6-mobile-font-line-height',
  '--h6-mobile-font-weight',
  '--h6-mobile-font-stretch',
  // P1 - Larger paragraph text.
  '--p1-desktop-font-size',
  '--p1-desktop-font-line-height',
  '--p1-desktop-font-weight',
  '--p1-desktop-font-stretch',
  '--p1-mobile-font-size',
  '--p1-mobile-font-line-height',
  '--p1-mobile-font-weight',
  '--p1-mobile-font-stretch',
  // P2 - Regular paragraph text.
  '--p2-desktop-font-size',
  '--p2-desktop-font-line-height',
  '--p2-desktop-font-weight',
  '--p2-desktop-font-stretch',
  '--p2-mobile-font-size',
  '--p2-mobile-font-line-height',
  '--p2-mobile-font-weight',
  '--p2-mobile-font-stretch',
  // P3 - Small paragraph text.
  '--p3-desktop-font-size',
  '--p3-desktop-font-line-height',
  '--p3-desktop-font-weight',
  '--p3-desktop-font-stretch',
  '--p3-mobile-font-size',
  '--p3-mobile-font-line-height',
  '--p3-mobile-font-weight',
  '--p3-mobile-font-stretch',
  // P4 - Smallest paragraph text.
  '--p4-desktop-font-size',
  '--p4-desktop-font-line-height',
  '--p4-desktop-font-weight',
  '--p4-desktop-font-stretch',
  '--p4-mobile-font-size',
  '--p4-mobile-font-line-height',
  '--p4-mobile-font-weight',
  '--p4-mobile-font-stretch',
  // Special Case #1 - Button text. Reused outside of buttons.
  '--action-desktop-font-size',
  '--action-desktop-font-line-height',
  '--action-desktop-font-weight',
  '--action-desktop-font-text-decoration',
  '--action-desktop-font-letter-spacing',
  '--action-mobile-font-size',
  '--action-mobile-font-line-height',
  '--action-mobile-font-weight',
  '--action-mobile-font-text-decoration',
  '--action-mobile-font-letter-spacing',
  // Special Case #2 - Eyebrow text. Reused in multiple components.
  '--annotation-desktop-font-size',
  '--annotation-desktop-font-line-height',
  '--annotation-desktop-font-weight',
  '--annotation-desktop-font-letter-spacing',
  '--annotation-mobile-font-size',
  '--annotation-mobile-font-line-height',
  '--annotation-mobile-font-weight',
  '--annotation-mobile-font-letter-spacing',
] as const;

export const motifBorderRadiusVariables = [
  '--border-radius-xs',
  '--border-radius-s',
  '--border-radius-m',
  '--border-radius-l',
  '--border-radius-xl',
] as const;

export const motifColorVariables = [
  // Text / Background.
  '--fg-color',
  '--bg-color',
  // Used for gradients
  '--bg-image',
  // Hyperlinks, Buttons etc.
  '--action-default-color',
  '--action-hover-color',
  '--action-active-color',
] as const;

/**
 * Shadows used within the system.
 *
 * NOTE: For now there's only one, but we should add more.
 */
export const motifShadowVariables = [
  '--box-shadow-xs',
  '--box-shadow-s',
  '--box-shadow-m',
  '--box-shadow-l',
  '--box-shadow-xl',
] as const;

/** Border widths within the system. */

export const motifBorderWidthVariables = [
  '--border-width-none',
  '--border-width-xs',
  '--border-width-s',
  '--border-width-m',
  '--border-width-l',
] as const;

const noVars = [] as const;

/**
 * A complete list of all SDS-M Motif variables.
 *
 * This is meant to be as flat as possible and serves as the source of truth for typechecking.
 *
 * Guide for making variable names:
 *
 * - The format for these is: <component?>-<surface?>-<state?>-<device?>-<property>
 * - The component is required for anything except ROOT
 * - Component should include the type, i.e. `button-primary`.
 * - Surface can be any part of a component, i.e. title | border | item
 * - State is the variant i.e. hover | active | disabled
 * - Device is mobile | desktop.
 */
export const motifVariables = {
  [MotifComponent.ROOT]: [
    ...motifColorPrimitiveVariables,
    ...motifColorVariables,
    ...motifShadowVariables,
    ...motifBorderRadiusVariables,
    ...motifBorderWidthVariables,
    ...motifSizeRampVariables,
    ...motifFontRampVariables,
  ] as const,
  [MotifComponent.BUTTON]: [
    '--button-primary-bg-color',
    '--button-primary-fg-color',
    '--button-primary-hover-bg-color',
    '--button-primary-border-color',
    '--button-primary-hover-border-color',
    '--button-border-radius',
    '--button-border-width',
    '--button-secondary-bg-color',
    '--button-secondary-fg-color',
    '--button-secondary-hover-bg-color',
    '--button-secondary-border-color',
    '--button-secondary-hover-border-color',
    '--button-hover-shadow',
    '--button-active-shadow',
    '--button-flat-fg-color',
    '--button-desktop-font-size',
    '--button-desktop-font-letter-spacing',
    '--button-desktop-font-line-height',
    '--button-desktop-font-weight',
    '--button-mobile-font-size',
    '--button-mobile-font-letter-spacing',
    '--button-mobile-font-line-height',
    '--button-mobile-font-weight',
    '--button-regular-padding',
    '--button-compact-padding',
  ] as const,
  [MotifComponent.HEADER]: [
    '--global-header-bg-color',
    '--global-header-nav-screen-fg-color',
    '--global-header-nav-screen-bg-color',
    '--global-header-nav-screen-global-links-bg-color',
    '--global-header-fg-color',
    '--global-header-item-color',
    '--global-header-item-hover-color',
    '--global-header-item-active-color',
    '--global-header-navigator-item-color',
    '--global-header-navigator-item-hover-color',
    '--global-header-navigator-item-active-color',
    '--global-header-menu-bg-color',
    '--global-header-menu-border-color',
    '--global-header-menu-col-label-color',
    '--global-header-menu-col-divider-color',
    '--global-header-menu-item-fg-color',
    '--global-header-menu-item-icon-color',
    '--global-header-menu-item-bg-hover-color',
    '--global-header-menu-featured-item-bg-color',
    '--global-header-menu-featured-item-bg-hover-color',
    '--global-header-menu-featured-item-fg-color',
    '--global-header-menu-featured-item-icon-color',
  ] as const,
  [MotifComponent.BLOCK]: [
    '--block-title-color',
    '--block-title-desktop-font-size',
    '--block-title-desktop-font-stretch',
    '--block-title-desktop-font-line-height',
    '--block-title-mobile-font-size',
    '--block-title-mobile-font-line-height',
    '--block-subtitle-color',
    '--block-eyebrow-color',
    '--block-header-desktop-padding',
    '--block-header-mobile-padding',
    '--block-boundary-desktop-padding',
    '--block-boundary-mobile-padding',
  ] as const,
  [MotifComponent.BLOCK_BOUNDARY]: noVars,
  [MotifComponent.BLOCK_SPLIT_PANEL]: noVars,
  [MotifComponent.DETAIL_SUMMARY]: noVars,
  [MotifComponent.TABS]: [
    '--tabs-underline-color',
    '--tabs-item-color',
    '--tabs-item-hover-color',
    '--tabs-item-active-color',
  ] as const,
  [MotifComponent.CAROUSEL]: [
    '--carousel-active-dot-color',
    '--carousel-inactive-dot-color',
    '--carousel-card-bg-color',
    '--carousel-card-hover-bg-color',
    '--carousel-card-border-color',
    '--carousel-card-border-width',
    '--carousel-card-border-radius',
    '--carousel-card-hover-border-color',
    '--carousel-card-fg-color',
    '--carousel-card-landscape-square-desktop-width',
    '--carousel-card-landscape-square-mobile-width',
    '--carousel-card-landscape-square-small-mobile-width',
    '--carousel-card-portrait-desktop-width',
    '--carousel-card-portrait-mobile-width',
    '--carousel-card-desktop-grid-gap',
    '--carousel-card-mobile-grid-gap',
    '--carousel-card-box-shadow',
    '--carousel-card-hover-box-shadow',
  ] as const,
  [MotifComponent.PAGE]: noVars,
  [MotifComponent.HERO]: [
    '--hero-subtitle-color',
    '--hero-text-color-curtain-active',
    '--hero-title-color',
    '--hero-scroll-button-curtain-active-bg-color',
    '--hero-scroll-button-curtain-active-hover-bg-color',
    '--hero-scroll-button-curtain-active-fg-color',
    '--hero-scroll-button-curtain-active-hover-fg-color',
    '--hero-scroll-button-bg-color',
    '--hero-scroll-button-hover-bg-color',
    '--hero-scroll-button-fg-color',
    '--hero-scroll-button-hover-fg-color',
  ] as const,
  [MotifComponent.CONTENT]: [
    '--content-desktop-grid-gap',
    '--content-mobile-grid-gap',
    '--content-subtitle-color',
    '--content-title-color',
    '--content-backdrop-filter',
    '--content-border-width',
    '--content-border-color',
    '--content-box-shadow',
    '--content-mobile-no-bg-padding',
    '--content-desktop-no-bg-padding',
  ] as const,
  [MotifComponent.CODE]: noVars,
  [MotifComponent.BREAK]: [
    '--break-total-desktop-height',
    '--break-half-desktop-height',
    '--break-total-mobile-height',
    '--break-half-mobile-height',
  ] as const,
  [MotifComponent.QUOTE]: [
    '--quote-fg-color',
    '--quote-bg-color',
    '--quote-author-desktop-font-weight',
    '--quote-author-desktop-font-size',
    '--quote-author-mobile-font-weight',
    '--quote-author-mobile-font-size',
  ] as const,
  [MotifComponent.FILTER_DROPDOWN_MENU]: noVars,
  [MotifComponent.FOOTER]: [
    '--footer-bg-color',
    '--footer-fg-color',
    '--footer-border-color',
    '--footer-divider-border-color',
    '--footer-bar-bg-color',
    '--footer-bar-fg-color',
    '--footer-bar-divider-border-color',
  ] as const,
  [MotifComponent.IMAGE_BUTTON]: noVars,
  [MotifComponent.DROPDOWN_MENU]: [
    '--dropdown-menu-padding',
    '--dropdown-menu-bg-color',
    '--dropdown-menu-border-color',
    '--dropdown-item-fg-color',
    '--dropdown-item-fg-hover-color',
    '--dropdown-item-fg-active-color',
    '--dropdown-item-bg-hover-color',
    '--dropdown-item-bg-active-color',
    '--dropdown-skeleton-animation-color',
    '--dropdown-skeleton-animation-default-color',
    '--dropdown-button-border-radius',
    '--dropdown-button-border-width',
    '--dropdown-button-border-color',
    '--dropdown-button-hover-border-color',
  ] as const,
  [MotifComponent.TABLE]: noVars,
  [MotifComponent.ACCORDION]: [
    '--accordion-divider-border-color',
    '--accordion-header-color',
    '--accordion-header-padding',
    '--accordion-header-desktop-font-size',
    '--accordion-header-mobile-font-size',
    '--accordion-header-desktop-font-line-height',
    '--accordion-header-mobile-font-line-height',
    '--accordion-header-desktop-font-weight',
    '--accordion-header-mobile-font-weight',
  ] as const,
  [MotifComponent.FOOTNOTE]: [
    '--footnote-fg-color',
    '--footnote-bg-color',
    '--footnote-border-color',
    '--footnote-hover-icon-bg-color',
    '--footnote-title-color',
  ] as const,
  [MotifComponent.TOGGLE_BUTTON]: [
    '--toggle-button-active-color',
    '--toggle-button-color',
    '--toggle-button-hover-color',
  ],
  [MotifComponent.BANNER]: [
    '--banner-bg-color',
    '--banner-fg-color',
    '--banner-font-size',
    '--banner-font-line-height',
  ] as const,
  [MotifComponent.SPINNER]: ['--spinner-fg-color'] as const,
  [MotifComponent.SEARCH]: [
    '--search-container-max-width',
    '--search-no-results-bg-color',
    '--search-no-results-fg-color',
    '--search-results-list-gap',
    '--search-subtitle-color',
  ] as const,
  [MotifComponent.TOOLTIP]: noVars,
  [MotifComponent.TOGGLE_SLIDER]: [
    '--toggle-slider-bg-color',
    '--toggle-slider-switch-color',
    '--toggle-slider-active-color',
  ] as const,
  [MotifComponent.HYPERLINK]: [
    '--hyperlink-color',
    '--hyperlink-hover-color',
    '--hyperlink-desktop-font-size',
    '--hyperlink-desktop-font-line-height',
    '--hyperlink-desktop-font-weight',
    '--hyperlink-desktop-font-text-decoration',
    '--hyperlink-desktop-font-letter-spacing',
    '--hyperlink-mobile-font-size',
    '--hyperlink-mobile-font-line-height',
    '--hyperlink-mobile-font-weight',
    '--hyperlink-mobile-font-text-decoration',
    '--hyperlink-mobile-font-letter-spacing',
  ] as const,
  [MotifComponent.PAGINATION]: [
    '--pagination-text-active-color',
    '--pagination-text-color',
    '--pagination-text-hover-color',
  ] as const,
  [MotifComponent.TILE]: ['--tile-bg-color', '--tile-fg-color'] as const,
  [MotifComponent.SUMMARY_CARD]: [
    '--summary-card-title-color',
    '--summary-card-description-color',
    '--summary-card-bg-color',
    '--summary-card-hover-bg-color',
    '--summary-card-fg-color',
    '--summary-card-border-radius',
    '--summary-card-border-width',
    '--summary-card-border-color',
    '--summary-card-hover-border-color',
    '--summary-card-box-shadow',
    '--summary-card-hover-box-shadow',
  ] as const,
  [MotifComponent.SNAPCHAT_EMBED]: noVars,
  [MotifComponent.SIDE_NAVIGATION]: [
    '--side-navigation-desktop-bar-bg-color',
    '--side-navigation-desktop-box-shadow',
    '--side-navigation-mobile-bar-bg-color',
    '--side-navigation-mobile-bar-fg-color',
    '--side-navigation-mobile-bg-color',
    '--side-navigation-mobile-fg-active-color',
    '--side-navigation-mobile-fg-color',
    '--side-navigation-mobile-toggler-icon-color',
  ] as const,
  [MotifComponent.ICON_BUTTON]: [
    '--icon-button-fg-color',
    '--icon-button-bg-color',
    '--icon-button-hover-bg-color',
    '--icon-button-hover-border-color',
    '--icon-button-disabled-bg-color',
    '--icon-button-border-color',
    '--icon-button-disabled-border-color',
    '--icon-button-disabled-fg-color',
    '--icon-button-border-width',
  ] as const,
  [MotifComponent.MODAL]: [
    '--modal-bg-color',
    '--modal-close-bg-color',
    '--modal-close-fg-color',
  ] as const,
  [MotifComponent.MOSAIC]: [
    '--mosaic-grid-gap',
    '--mosaic-title-color',
    '--mosaic-border-radius',
    '--mosaic-highlight-color',
    '--mosaic-duration-color',
  ] as const,
  [MotifComponent.MEDIA]: [
    '--media-border-radius',
    '--media-border-width',
    '--media-border-color',
  ] as const,
  [MotifComponent.ICON]: ['--icon-color'] as const,
  [MotifComponent.SOCIAL]: noVars,
  [MotifComponent.FORM]: [
    '--form-description-fg-color',
    '--form-grid-gap',
    '--form-input-placeholder-color',
    '--form-input-fg-color',
    '--form-input-error-color',
    '--form-input-bg-color',
    '--form-input-border-color',
    '--form-input-hover-border-color',
    '--form-input-active-border-color',
    '--form-input-border-width',
    '--form-input-border-radius',
    '--form-input-desktop-font-size',
    '--form-input-desktop-font-line-height',
    '--form-input-desktop-font-weight',
    '--form-input-mobile-font-size',
    '--form-input-mobile-font-line-height',
    '--form-input-mobile-font-weight',
    '--form-input-box-shadow',
    '--form-input-hover-box-shadow',
    '--form-input-active-box-shadow',
    '--form-input-padding',
    '--form-input-mobile-font-stretch',
    '--form-input-desktop-font-stretch',
  ] as const,
  [MotifComponent.SUB_NAVIGATION]: [
    '--sub-navigation-item-color',
    '--sub-navigation-item-hover-color',
    '--sub-navigation-item-active-color',
    '--sub-navigation-item-hover-decoration-color',
    '--sub-navigation-item-active-decoration-color',
  ] as const,
  [MotifComponent.PROGRESS_BAR]: [
    '--progress-bar-bg-color',
    '--progress-bar-progress-color',
  ] as const,
  [MotifComponent.CHART_TOGGLE]: [
    '--chart-toggle-buttons-bg-color',
    '--chart-toggle-buttons-color',
    '--chart-toggle-buttons-border-color',
    '--chart-toggle-buttons-active-bg-color',
    '--chart-toggle-buttons-active-color',
    '--chart-toggle-buttons-active-border-color',
  ] as const,
  [MotifComponent.BLOCK_NAVIGATION]: [
    '--block-navigation-buttons-bg-color',
    '--block-navigation-buttons-color',
    '--block-navigation-buttons-border-color',
    '--block-navigation-buttons-active-color',
    '--block-navigation-buttons-active-border-color',
    '--block-navigation-buttons-active-bg-color',
  ] as const,
  [MotifComponent.LOGO]: ['--logo-stroke-color', '--logo-fill-color'] as const,
  [MotifComponent.BAR_CHART]: noVars,
  [MotifComponent.LINE_CHART]: noVars,
  [MotifComponent.GEO_MAP]: noVars,
  [MotifComponent.MULTI_VIDEO_BLOCK]: noVars,
  [MotifComponent.TOGGLE_PANEL]: ['--toggle-panel-bg-color', '--toggle-panel-color'] as const,
  [MotifComponent.GALLERY]: noVars,
  [MotifComponent.PRIMITIVE_TABLE]: [
    '--table-bg-color',
    '--table-border-color',
    '--table-cell-body-bg-color',
    '--table-cell-desktop-padding',
    '--table-cell-fg-color',
    '--table-cell-header-bg-color',
    '--table-cell-min-width',
    '--table-cell-mobile-padding',
    '--table-desktop-row-gap',
    '--table-first-column-max-width',
    '--table-header-fg-color',
    '--table-mobile-row-gap',
  ] as const,
  [MotifComponent.CHART_SKELETON]: [
    '--chart-skeleton-bg-color',
    '--chart-skeleton-mask-color',
    '--chart-skeleton-dark-animation-color',
    '--chart-skeleton-light-animation-color',
  ],
  [MotifComponent.LOADING_BAR]: ['--loading-bar-left-color', '--loading-bar-right-color'] as const,
  [MotifComponent.AUTOCOMPLETE]: [
    '--autocomplete-button-fg-color',
    '--autocomplete-button-fg-hover-color',
    '--autocomplete-border-color',
    '--autocomplete-bg-color',
    '--autocomplete-border-width',
    '--autocomplete-hover-border-color',
    '--autocomplete-hover-box-shadow',
    '--autocomplete-active-border-color',
    '--autocomplete-active-box-shadow',
    '--autocomplete-fg-color',
    '--autocomplete-mobile-font-size',
    '--autocomplete-mobile-font-weight',
    '--autocomplete-desktop-font-size',
    '--autocomplete-desktop-font-weight',
    '--autocomplete-placeholder-color',
  ] as const,
  [MotifComponent.SPLIT_BLOCK]: noVars,
  [MotifComponent.DEFINITION]: [
    '--definition-active-bg-color',
    '--definition-active-border-color',
    '--definition-bg-color',
    '--definition-border-color',
    '--definition-hover-bg-color',
    '--definition-hover-border-color',
  ] as const,
  [MotifComponent.SIDE_OVERLAY]: [
    '--side-overlay-bg-color',
    '--side-overlay-border-color',
    '--side-overlay-fg-color',
  ] as const,
  [MotifComponent.MULTI_VALUE_PROP_BLOCK]: noVars,
} as const;

/** Motif Schemes and their CSS classnames that are used to designate blocks. */
export enum MotifScheme {
  DEFAULT = 'sdsm-default',
  SECONDARY = 'sdsm-secondary', // 2nd
  TERTIARY = 'sdsm-tertiary', // 3rd
  QUATERNARY = 'sdsm-quaternary', // 4th
  QUINARY = 'sdsm-quinary', // 5th
  // Note if you need to add more, the following are: senary, septenary, octonary, nonary & denary
}

export const allMotifSchemes = [
  MotifScheme.DEFAULT,
  MotifScheme.SECONDARY,
  MotifScheme.TERTIARY,
  MotifScheme.QUATERNARY,
  MotifScheme.QUINARY,
];

export const nonDefaultMotifSchemes = [
  MotifScheme.SECONDARY,
  MotifScheme.TERTIARY,
  MotifScheme.QUATERNARY,
  MotifScheme.QUINARY,
] as const;
