import { cx } from '@emotion/css';
import isEmpty from 'lodash-es/isEmpty';
import isNil from 'lodash-es/isNil';
import type { CSSProperties, ReactElement, ReactNode } from 'react';
import { forwardRef } from 'react';

import type { Device } from '../../constants';
import { Alignment, BackgroundColor, mobileMaxWidth, VerticalAlignment } from '../../constants';
import { useIsMobile } from '../../hooks';
import { MotifComponent, useMotifStyles } from '../../motif';
import type { BaseComponentProps, Buttons, ImageSources } from '../../types';
import {
  alignmentCss,
  dataSetToAttributes,
  getBackgroundClassName,
  mobileAlignmentCss,
  useWindowSize,
} from '../../utils';
import { BlockBoundary } from '../BlockBoundary';
import type { CarouselV3Props } from '../CarouselV3';
import { IconButton } from '../IconButton';
import { IconButtonSize } from '../IconButton/IconButton.types';
import type { MediaProps } from '../Media';
import { Picture } from '../Picture';
import { Social } from '../Social';
import { Video } from '../Video';
import { HeroSize } from './Hero.constants';
import {
  alignItemsEndCss,
  alignItemsStartCss,
  backgroundCss,
  bodyCss,
  boundaryCss,
  callsToActionWrapperCss,
  compactBoundaryCss,
  curtainCss,
  cutoffWithMediaPx,
  eyebrowCss,
  fitWindowCss,
  footerCss,
  headerCss,
  heroCss,
  heroCurtainOpacityCssVar,
  heroMediaCss,
  heroScrollButtonCss,
  heroScrollButtonWrapCss,
  heroSocialContainerCss,
  heroSplitCss,
  heroTitleContainerCss,
  reverseSplitCss,
  splitItemCss,
  splitMediaCss,
  splitMediaItemCss,
  subTitleCss,
  textContentCss,
  titleCss,
} from './Hero.styled';
import { HeroHeader } from './HeroHeader';
import { HeroMedia } from './HeroMedia';

export type ForegroundMediaProps = MediaProps | CarouselV3Props;

export type HeroBreakTemplate = 'Straight' | 'Default';

export const BackgroundMediaLayout = {
  FullScreen: 'Full screen',
  Start: '50% start',
  End: '50% end',
} as const;

export type BackgroundMediaLayout =
  (typeof BackgroundMediaLayout)[keyof typeof BackgroundMediaLayout];

export interface HeroProps extends BaseComponentProps {
  wrapMedia?: Device;
  body?: ReactNode;
  callsToAction?: Buttons;
  foregroundMedia?: ReactElement<ForegroundMediaProps>;
  backgroundVideoSource?: string;
  backgroundPosterSource?: string;
  mobileBackgroundVideoSource?: string;
  curtainOpacityPercentage?: number;
  fitWindow?: boolean;
  backgroundColor?: BackgroundColor;
  eyebrow?: ReactNode;
  title: ReactNode;
  header?: ReactNode | Date;
  textAlign?: Alignment;
  textAlignMobile?: Alignment;
  verticalTextAlign?: VerticalAlignment;
  bgImgSrcs?: ImageSources;
  headerImgSrcs?: ImageSources;
  headerImgAltText?: string;
  /** Hero foreground media sources. */
  imgSrcs?: ImageSources;
  imgAltText?: string;
  shareable?: boolean;
  footer?: ReactNode;
  showMediaMobile?: boolean;
  anchorId?: string;
  eyebrowDataset?: DOMStringMap;
  titleDataset?: DOMStringMap;
  bodyDataset?: DOMStringMap;
  headerDataset?: DOMStringMap;
  postChildren?: ReactNode;
  size?: HeroSize;
  subTitle?: ReactNode;
  subTitleDataset?: DOMStringMap;
  showScrollButton?: boolean;
  onScrollDownButtonClick?: () => void;
  backgroundMediaLayout?: BackgroundMediaLayout;
}

// biome-ignore lint/complexity/noExcessiveCognitiveComplexity: TODO: Refactor to reduce complexity.
export const Hero = forwardRef<HTMLElement, HeroProps>((props, ref) => {
  useMotifStyles(MotifComponent.HERO);

  const {
    backgroundVideoSource,
    backgroundPosterSource,
    mobileBackgroundVideoSource,
    body,
    callsToAction,
    fitWindow = false,
    foregroundMedia,
    curtainOpacityPercentage: defaultCurtainOpacityPercentage,
    headerImgSrcs,
    headerImgAltText,
    eyebrow,
    title,
    backgroundColor = BackgroundColor.Black,
    header,
    bgImgSrcs,
    imgSrcs,
    // Note that we do not set a default for horizontal text align. This
    // is _intentional_. Reasoning: when we have a media, left-align text.
    // When we don't center align text. Therefore, use undefined to mean
    // "default behavior"
    textAlign,
    textAlignMobile,
    verticalTextAlign = VerticalAlignment.Middle,
    shareable = false,
    className,
    footer,
    anchorId,
    eyebrowDataset,
    titleDataset,
    bodyDataset,
    headerDataset,
    postChildren,
    showMediaMobile,
    wrapMedia,
    size,
    subTitle,
    subTitleDataset,
    showScrollButton,
    onScrollDownButtonClick,
    backgroundMediaLayout = BackgroundMediaLayout.FullScreen,
  } = props;

  const hasMedia = !!(foregroundMedia || imgSrcs);

  const { width } = useWindowSize();

  const isMobile = useIsMobile();

  // There's a resolution where the media does not look good and squishes
  // the text too hard. So in this case we have to hide this.
  // NOTE: We aren't doing this in CSS to not affect the grid layout
  // when the media isn't shown.
  const hideMedia = !!width && width > mobileMaxWidth && width < cutoffWithMediaPx;
  const hasBackgroundMedia = !isEmpty(bgImgSrcs) || !isEmpty(backgroundVideoSource);

  const isCompact = size === HeroSize.Compact;

  let curtainOpacityPercentage = defaultCurtainOpacityPercentage;

  if (isNil(curtainOpacityPercentage)) {
    curtainOpacityPercentage = hasBackgroundMedia ? 75 : 0;
  }

  const isSplitBlock =
    backgroundMediaLayout === BackgroundMediaLayout.Start ||
    backgroundMediaLayout === BackgroundMediaLayout.End;

  let backgroundContent: ReactNode;

  if (bgImgSrcs) {
    backgroundContent = (
      <Picture
        imgSrcs={bgImgSrcs}
        className={cx({
          [splitItemCss]: isSplitBlock,
          [splitMediaItemCss]: isSplitBlock,
        })}
        imgClassName={cx(backgroundCss, {
          [splitMediaCss]: isSplitBlock,
        })}
        fetchPriority="high"
      />
    );
  } else if (backgroundVideoSource) {
    backgroundContent = (
      <div
        className={cx({
          [splitItemCss]: isSplitBlock,
          [splitMediaItemCss]: isSplitBlock,
        })}
      >
        <Video
          mobileSource={mobileBackgroundVideoSource}
          className={cx(backgroundCss, {
            [splitMediaCss]: isSplitBlock,
          })}
          source={backgroundVideoSource}
          posterSource={backgroundPosterSource}
          isBackgroundVideo
        />
      </div>
    );
  }

  const alignMobileCss = mobileAlignmentCss[textAlignMobile ?? Alignment.Start];
  const defaultTextAlign = hasMedia ? Alignment.Start : Alignment.Center;
  const contentAlignmentCss = alignmentCss[textAlign ?? defaultTextAlign];
  const verticalAlignCss = cx({
    [alignItemsStartCss]: verticalTextAlign === VerticalAlignment.Top,
    [alignItemsEndCss]: verticalTextAlign === VerticalAlignment.Bottom,
  });

  const showCurtain = !!curtainOpacityPercentage && !isSplitBlock;

  return (
    <section
      ref={ref}
      className={cx(
        MotifComponent.HERO,
        getBackgroundClassName(backgroundColor),
        heroCss,
        {
          [fitWindowCss]: fitWindow,
          [heroSplitCss]: isSplitBlock,
          [reverseSplitCss]: backgroundMediaLayout === BackgroundMediaLayout.End,
        },
        className
      )}
      id={anchorId}
      data-curtain={showCurtain}
    >
      {backgroundContent}
      {showCurtain && (
        <div
          data-testid="sdsm-hero-curtain"
          className={cx(backgroundCss, curtainCss)}
          style={
            {
              [heroCurtainOpacityCssVar]: curtainOpacityPercentage / 100,
            } as CSSProperties
          }
        />
      )}
      <BlockBoundary
        data-test-id="sdsm-hero-block-content"
        className={cx(
          boundaryCss,
          verticalAlignCss,
          { [fitWindowCss]: fitWindow },
          {
            [compactBoundaryCss]: isCompact,
            [splitItemCss]: isSplitBlock,
          }
        )}
      >
        <div
          data-test-id="sdsm-hero-text"
          className={cx(alignMobileCss, contentAlignmentCss, textContentCss)}
        >
          {(header || headerImgSrcs) && (
            <HeroHeader
              content={header}
              iconSrcs={headerImgSrcs}
              iconAltText={headerImgAltText}
              textAlignMobile={textAlignMobile}
              headerDataset={headerDataset}
              className={headerCss}
            />
          )}
          {eyebrow && (
            <div className={eyebrowCss} {...dataSetToAttributes(eyebrowDataset)}>
              {eyebrow}
            </div>
          )}
          {title && (
            <div className={heroTitleContainerCss}>
              <h1
                className={cx(titleCss, contentAlignmentCss, alignMobileCss)}
                {...dataSetToAttributes(titleDataset)}
              >
                {title}
              </h1>
            </div>
          )}
          {subTitle && (
            <div className={subTitleCss} {...dataSetToAttributes(subTitleDataset)}>
              {subTitle}
            </div>
          )}
          {body && (
            <div
              className={cx(alignMobileCss, contentAlignmentCss, bodyCss)}
              {...dataSetToAttributes(bodyDataset)}
            >
              {body}
            </div>
          )}
          {callsToAction && (
            <div className={cx(alignMobileCss, callsToActionWrapperCss)}>{callsToAction}</div>
          )}
          {footer && <footer className={footerCss}>{footer}</footer>}
        </div>
        {hasMedia && !hideMedia && !isSplitBlock && (
          <HeroMedia
            className={heroMediaCss}
            foregroundMedia={foregroundMedia}
            imgSrcs={imgSrcs}
            wrapMedia={wrapMedia}
            showMediaMobile={showMediaMobile}
            size={size}
          />
        )}
        {fitWindow && showScrollButton && !isMobile && (
          <div className={heroScrollButtonWrapCss}>
            <IconButton
              size={IconButtonSize.SMALL}
              iconName="chevron-down"
              className={heroScrollButtonCss}
              onClick={onScrollDownButtonClick}
            />
          </div>
        )}
      </BlockBoundary>
      {shareable && <Social className={heroSocialContainerCss} title={title?.toString() ?? ''} />}
      {postChildren}
    </section>
  );
});

Hero.displayName = 'Hero';
