/* eslint-disable @typescript-eslint/no-unused-vars */
import { cx } from '@emotion/css';
import type { CSSProperties, FC, ReactNode } from 'react';
import { useContext, useRef } from 'react';

import type { BackgroundColor } from '../../constants';
import { Alignment } from '../../constants';
import { MotifComponent, p1Css, useMotifStyles } from '../../motif';
import type { BaseComponentProps, Buttons } from '../../types';
import {
  BlockBackgroundColorContext,
  dataSetToAttributes,
  getBackgroundClassName,
} from '../../utils';
import { hideWhenDesktopCss, hideWhenMobileCss } from '../../utils/styles';
import { useAnimations } from '../Animations';
import type { Animation } from '../Animations/types';
import {
  bodyContainerCss,
  bodyContainerStretchCss,
  bodyMarginTopCss,
  bodyStyleCss,
  contentBaseCss,
  contentLayoutCss,
  contentLayoutDirectionCss,
  contentScrollMarginCss,
  contentWrapperNoMobilePaddingStyle,
  contentWrapperStyle,
  ctaContainerCss,
  ctaInnerCss,
  ctaMarginTopCss,
  displayNoneCss,
  headerCss,
  listIconStyleCss,
  listIconUrlVariable,
  mediaOrderCss,
  subtitleBaseCss,
  textAlignmentBaseCss,
  textAlignmentDesktopStyle,
  textAlignmentMobileStyle,
  titleCss,
  transparentBackgroundCss,
} from './Content.styled';
import { DisplayMode, Layout, MediaDirection } from './types';

export interface ContentProps extends BaseComponentProps {
  body?: ReactNode;
  bodyAlignment?: Alignment;
  bodyAlignmentMobile?: Alignment;
  callsToAction?: Buttons;
  displayOn?: DisplayMode;
  layout?: Layout;
  mediaDirection?: MediaDirection;
  subtitle?: ReactNode;
  title?: ReactNode;
  titleAlignment?: Alignment;
  titleAlignmentMobile?: Alignment;
  backgroundColor?: BackgroundColor;
  anchorId?: string;
  animation?: Animation;
  isRTL?: boolean;
  bodyDataset?: DOMStringMap;
  titleDataset?: DOMStringMap;
  subtitleDataset?: DOMStringMap;
  /** @deprecated. Use `asset` instead. */
  media?: ReactNode;
  asset?: ReactNode;
  listIconUrl?: string;
}

/**
 * Classnames added to primary HTML elements.
 *
 * Allows consumers to customize styles for various use cases.
 */
export enum ContentElementClass {
  /** Classname added to container HTML element that renders the `body` prop. */
  BODY = 'sdsm-content-body',

  /** Classname added to container HTML element that renders the `callsToAction` prop. */
  CALLS_TO_ACTION = 'sdsm-content-cta',

  /** Classname added to container HTML element that renders the `subtitle` prop. */
  SUBTITLE = 'sdsm-content-subtitle',

  /** Classname added to container HTML element that renders the `title` prop. */
  TITLE = 'sdsm-content-title',
}

// TODO: when we fully deprecate old Content, update the props
/** Newer content component that is cleaner and has removed lots of rarely used functionality */
export const Content: FC<ContentProps> = ({
  className,
  body,
  bodyAlignment,
  bodyAlignmentMobile,
  callsToAction,
  displayOn = DisplayMode.ALL,
  layout = Layout.NORMAL,
  mediaDirection = MediaDirection.TOP,
  subtitle,
  title,
  titleAlignment,
  titleAlignmentMobile,
  backgroundColor: contentBackgroundColor,
  anchorId,
  animation,
  isRTL,
  bodyDataset,
  titleDataset,
  subtitleDataset,
  asset,
  style,
  listIconUrl,
}) => {
  useMotifStyles(MotifComponent.CONTENT);
  const contentRef = useRef(null);
  const animationCssProps = useAnimations(contentRef, animation);

  const dir = isRTL ? 'rtl' : undefined;

  const blockBackgroundColor = useContext(BlockBackgroundColorContext);
  const backgroundColor = contentBackgroundColor || blockBackgroundColor;
  const isSameBackgroundColorAsBlock = blockBackgroundColor === backgroundColor;

  // helper variables
  const hasHeader = !!(title || subtitle);
  const hasCallsToAction = !!(
    (callsToAction && !Array.isArray(callsToAction)) ||
    (Array.isArray(callsToAction) && callsToAction.length)
  );
  const hasBody = body || hasCallsToAction;
  const hasContent = !!(title || subtitle || hasBody);
  const hasMediaAndContent = !!asset && hasContent;

  // Styles for the main <section> container
  const bgClass = contentBackgroundColor ? getBackgroundClassName(backgroundColor) : undefined;

  // Remove padding if the block BackgroundColor is the same as the content BackgroundColor
  const contentBoxStyle =
    backgroundColor === blockBackgroundColor
      ? contentWrapperNoMobilePaddingStyle[layout]
      : contentWrapperStyle[layout];

  const sectionClassName = cx(
    MotifComponent.CONTENT,
    // style for background color class name
    bgClass,
    // base css that is always applied
    contentBaseCss,
    // special padding css based on background color of content and its containing block
    contentBoxStyle,
    {
      // style to hide/show on certain devices based on DisplayMode
      [hideWhenMobileCss]: displayOn === DisplayMode.DESKTOP_ONLY,
      [hideWhenDesktopCss]: displayOn === DisplayMode.MOBILE_ONLY,
      [displayNoneCss]: displayOn === DisplayMode.NEITHER,
      // if the content has media and text, use proper grid layout
      [contentLayoutCss[layout] || '']: hasMediaAndContent,
      // if the content has media and text, set proper ordering on grid items for proper direction
      [contentLayoutDirectionCss[layout]?.[mediaDirection] || '']: hasMediaAndContent,
      // We use this to override the background color and set it to transparent when bg colors are the same
      // or when content bg color isn't set.
      [transparentBackgroundCss]: !contentBackgroundColor || isSameBackgroundColorAsBlock,
    },
    className
  );

  return (
    <section
      id={anchorId}
      style={animationCssProps ? { ...style, ...animationCssProps } : style}
      data-test-id={MotifComponent.CONTENT}
      className={cx(sectionClassName, contentScrollMarginCss)}
      ref={contentRef}
      dir={dir}
    >
      {!!asset && (
        <div data-test-id="sdsm-content-media-container" className={mediaOrderCss[mediaDirection]}>
          <div data-test-id="sdsm-content-media" data-animation-id="content-media">
            {asset}
          </div>
        </div>
      )}
      {hasContent && (
        <div
          data-test-id="sdsm-content-header-and-body"
          className={cx(bodyContainerCss, {
            [bodyContainerStretchCss]:
              mediaDirection === MediaDirection.TOP || mediaDirection === MediaDirection.BOTTOM,
          })}
        >
          {hasHeader && (
            <ContentHeader
              title={title}
              titleAlignment={titleAlignment}
              titleAlignmentMobile={titleAlignmentMobile}
              titleDataset={titleDataset}
              subtitle={subtitle}
              subtitleDataset={subtitleDataset}
            />
          )}
          {hasBody && (
            <ContentBody
              body={body}
              bodyAlignment={bodyAlignment}
              bodyAlignmentMobile={bodyAlignmentMobile}
              bodyDataset={bodyDataset}
              callsToAction={callsToAction}
              listIconUrl={listIconUrl}
              hasHeader={hasHeader}
              hasCallsToAction={hasCallsToAction}
            />
          )}
        </div>
      )}
    </section>
  );
};

type ContentHeaderProps = Pick<
  ContentProps,
  | 'title'
  | 'titleAlignment'
  | 'titleAlignmentMobile'
  | 'titleDataset'
  | 'subtitle'
  | 'subtitleDataset'
>;

/** Helper component - renders the title and subtitle for the content. */
const ContentHeader: FC<ContentHeaderProps> = ({
  title,
  titleDataset,
  subtitle,
  subtitleDataset,
  titleAlignment = Alignment.Start,
  titleAlignmentMobile = Alignment.Start,
}) => {
  const titleAlignmentClassName = cx(
    textAlignmentBaseCss,
    textAlignmentDesktopStyle[titleAlignment],
    textAlignmentMobileStyle[titleAlignmentMobile]
  );

  return (
    <div data-test-id="sdsm-content-header" className={cx(headerCss, titleAlignmentClassName)}>
      {title && (
        <h3
          className={cx(titleCss, titleAlignmentClassName, ContentElementClass.TITLE)}
          data-test-id={ContentElementClass.TITLE}
          data-animation-id="content-title"
          {...dataSetToAttributes(titleDataset)}
        >
          {title}
        </h3>
      )}
      {subtitle && (
        <div
          className={cx(p1Css, subtitleBaseCss, ContentElementClass.SUBTITLE)}
          data-test-id={ContentElementClass.SUBTITLE}
          data-animation-id="content-subtitle"
          {...dataSetToAttributes(subtitleDataset)}
        >
          {subtitle}
        </div>
      )}
    </div>
  );
};

interface ContentBodyProps
  extends Pick<
    ContentProps,
    | 'body'
    | 'bodyAlignment'
    | 'bodyAlignmentMobile'
    | 'bodyDataset'
    | 'callsToAction'
    | 'listIconUrl'
  > {
  hasHeader?: boolean;
  hasCallsToAction?: boolean;
}

/** Helper component - renders the Body and CTAs foor the content. */
const ContentBody: FC<ContentBodyProps> = ({
  body,
  bodyDataset,
  callsToAction,
  listIconUrl,
  hasHeader,
  hasCallsToAction,
  bodyAlignment = Alignment.Start,
  bodyAlignmentMobile = Alignment.Start,
}) => {
  const bodyAlignmentClassName = cx(
    textAlignmentBaseCss,
    textAlignmentDesktopStyle[bodyAlignment],
    textAlignmentMobileStyle[bodyAlignmentMobile]
  );

  // Set the list icon url as a CSS variable to allow for a dynamic list-style-image property
  const columnCountVariableStyles = listIconUrl
    ? ({
        [listIconUrlVariable]: `url("${listIconUrl}")`,
      } as CSSProperties)
    : undefined;

  return (
    <div
      data-test-id={ContentElementClass.BODY}
      style={columnCountVariableStyles}
      className={ContentElementClass.BODY}
    >
      {body && (
        <div
          className={cx(bodyStyleCss, bodyAlignmentClassName, {
            [bodyMarginTopCss]: hasHeader,
            [listIconStyleCss]: !!listIconUrl,
          })}
          data-test-id="sdsm-content-body-content"
          data-animation-id="content-body"
          {...dataSetToAttributes(bodyDataset)}
        >
          {body}
        </div>
      )}

      {hasCallsToAction && (
        <div
          data-test-id={ContentElementClass.CALLS_TO_ACTION}
          className={cx(ctaContainerCss, ContentElementClass.CALLS_TO_ACTION)}
        >
          <div
            className={cx(ctaInnerCss, bodyAlignmentClassName, {
              [ctaMarginTopCss]: !!body || hasHeader,
            })}
            data-animation-id="content-cta"
          >
            {callsToAction}
          </div>
        </div>
      )}
    </div>
  );
};
