import type { MotifComponent, MotifComponentProps } from '../../motif';
import { m } from '../../motif/m';

export const togglePanelWhiteBgVars: MotifComponentProps<MotifComponent.TOGGLE_PANEL> = {
  '--toggle-panel-bg-color': m('--neutral-v700'),
  '--toggle-panel-color': m('--neutral-v700'),
};

export const togglePanelYellowBgVars: MotifComponentProps<MotifComponent.TOGGLE_PANEL> = {
  '--toggle-panel-bg-color': m('--neutral-v0'),
  '--toggle-panel-color': m('--neutral-v700'),
};
