import type { MotifComponent, MotifComponentProps } from '../../motif';
import { m } from '../../motif/m';

export const darkThemeHeaderVars: MotifComponentProps<MotifComponent.HEADER> = {
  '--global-header-bg-color': m('--neutral-v700'),
  '--global-header-fg-color': m('--neutral-v300'),
  '--global-header-nav-screen-bg-color': m('--neutral-v700'),
  '--global-header-nav-screen-fg-color': m('--neutral-v300'),
  '--global-header-nav-screen-global-links-bg-color': m('--neutral-v650'),
  '--global-header-item-color': m('--action-default-color'),
  '--global-header-item-hover-color': m('--action-hover-color'),
  '--global-header-item-active-color': m('--action-active-color'),
  '--global-header-navigator-item-color': m('--neutral-v250'),
  '--global-header-navigator-item-hover-color': m('--neutral-v200'),
  '--global-header-navigator-item-active-color': m('--neutral-v0'),
  '--global-header-menu-bg-color': m('--neutral-v650'),
  '--global-header-menu-border-color': m('--neutral-v625'),
  '--global-header-menu-col-label-color': m('--neutral-v0'),
  '--global-header-menu-col-divider-color': m('--neutral-v600'),
  '--global-header-menu-item-fg-color': m('--neutral-v300'),
  '--global-header-menu-item-icon-color': m('--primary-v150'),
  '--global-header-menu-item-bg-hover-color': m('--neutral-v625'),
  '--global-header-menu-featured-item-bg-color': m('--neutral-v600'),
  '--global-header-menu-featured-item-bg-hover-color': m('--neutral-v625'),
  '--global-header-menu-featured-item-fg-color': m('--neutral-v300'),
  '--global-header-menu-featured-item-icon-color': m('--primary-v150'),
};

export const transparentDarkThemeHeaderVars: MotifComponentProps<MotifComponent.HEADER> = {
  ...darkThemeHeaderVars,
  '--global-header-bg-color': m('--palette-plain-transparent'),
  '--global-header-nav-screen-bg-color': m('--neutral-v700'),
  '--global-header-nav-screen-global-links-bg-color': m('--neutral-v650'),
  '--global-header-navigator-item-color': m('--neutral-v250'),
  '--global-header-navigator-item-hover-color': m('--neutral-v200'),
  '--global-header-navigator-item-active-color': m('--neutral-v0'),
};

export const lightThemeHeaderVars: MotifComponentProps<MotifComponent.HEADER> = {
  '--global-header-bg-color': m('--neutral-v0'),
  '--global-header-fg-color': m('--neutral-v600'),
  '--global-header-nav-screen-bg-color': m('--neutral-v0'),
  '--global-header-nav-screen-fg-color': m('--neutral-v600'),
  '--global-header-nav-screen-global-links-bg-color': m('--neutral-v150'),
  '--global-header-item-color': m('--action-default-color'),
  '--global-header-item-hover-color': m('--action-hover-color'),
  '--global-header-item-active-color': m('--action-active-color'),
  '--global-header-navigator-item-color': m('--neutral-v600'),
  '--global-header-navigator-item-hover-color': m('--neutral-v700'),
  '--global-header-navigator-item-active-color': m('--neutral-v700'),
  '--global-header-menu-bg-color': m('--neutral-v0'),
  '--global-header-menu-border-color': m('--neutral-v150'),
  '--global-header-menu-col-label-color': m('--neutral-v700'),
  '--global-header-menu-col-divider-color': m('--neutral-v150'),
  '--global-header-menu-item-fg-color': m('--neutral-v650'),
  '--global-header-menu-item-icon-color': m('--neutral-v500'),
  '--global-header-menu-item-bg-hover-color': m('--neutral-v200'),
  '--global-header-menu-featured-item-bg-color': m('--neutral-v150'),
  '--global-header-menu-featured-item-bg-hover-color': m('--neutral-v200'),
  '--global-header-menu-featured-item-fg-color': m('--neutral-v650'),
  '--global-header-menu-featured-item-icon-color': m('--neutral-v500'),
};

export const transparentLightThemeHeaderVars: MotifComponentProps<MotifComponent.HEADER> = {
  ...lightThemeHeaderVars,
  '--global-header-bg-color': m('--palette-plain-transparent'),
  '--global-header-nav-screen-bg-color': m('--neutral-v0'),
  '--global-header-nav-screen-global-links-bg-color': m('--neutral-v150'),
  '--global-header-navigator-item-color': m('--neutral-v600'),
  '--global-header-navigator-item-hover-color': m('--neutral-v700'),
  '--global-header-navigator-item-active-color': m('--neutral-v700'),
};

export const yellowThemeHeaderVars: MotifComponentProps<MotifComponent.HEADER> = {
  ...lightThemeHeaderVars,
  '--global-header-bg-color': m('--primary-v100'),
  '--global-header-nav-screen-bg-color': m('--neutral-v0'),
  '--global-header-navigator-item-color': m('--neutral-v600'),
  '--global-header-navigator-item-hover-color': m('--neutral-v700'),
  '--global-header-navigator-item-active-color': m('--neutral-v700'),
};
