import type { MotifComponent, MotifComponentProps } from '../../motif';
import { m } from '../../motif/m';

export const searchDefaultVars: MotifComponentProps<MotifComponent.SEARCH> = {
  '--search-container-max-width': '896px',
  '--search-no-results-bg-color': m('--neutral-v150'),
  '--search-no-results-fg-color': m('--neutral-v650'),
  '--search-results-list-gap': '36px',
  '--search-subtitle-color': m('--neutral-v500'),
};
