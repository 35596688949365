import { css } from '@emotion/css';

import { m } from '../../motif';

export const baseToggleButtonCss = css`
  padding: ${m('--button-compact-padding')};
  padding-right: 0px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
`;

export const toggleButtonWithTextCss = css`
  ${baseToggleButtonCss}
  padding-bottom: 0;
  flex-direction: column;
  align-items: center;
  border-radius: ${m('--border-radius-m')};
  font-size: small;
  color: ${m('--toggle-button-color')};

  &:hover {
    color: ${m('--toggle-button-hover-color')};
    text-decoration: underline;
  }
`;

export const activeToggleCss = css`
  color: ${m('--toggle-button-active-color')};
`;

export const activeToggleWithTextCss = css`
  ${activeToggleCss}
  background-color: ${m('--toggle-button-color')};
`;

export const toggleButtonTextCss = css`
  margin-top: ${m('--spacing-xxs')};
  color: ${m('--toggle-button-active-color')};
  text-decoration: none;
`;

export const svgCss = css`
  fill: ${m('--toggle-button-color')};
  &:hover {
    fill: ${m('--toggle-button-hover-color')};
  }
`;

export const svgActiveCss = css`
  fill: ${m('--toggle-button-active-color')};
`;

export const svgTextCss = css`
  fill: ${m('--toggle-button-active-color')};
`;

export const tooltipCss = css`
  color: ${m('--palette-black-v200')};
`;
