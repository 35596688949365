import { m } from '../../motif/m';
import type { MotifComponent } from '../../motif/motifConstants';
import type { MotifComponentProps } from '../../motif/motifTypes';

// NOTE: Style inspiration taken from:
// https://www.figma.com/file/XZO1oDmIMJMWZ1aof1TWFD/SDS-Marketing---Buttons?type=design&node-id=0-1&mode=design&t=SvVssh7qqdov17ce-0
// There have been many updates since then. I.e. no more "primary" color. These are always either
// black or white.

const commonVars = {
  '--icon-button-border-width': '2px',
};

export const contrastBlackIconButtonVars: MotifComponentProps<MotifComponent.ICON_BUTTON> = {
  ...commonVars,
  '--icon-button-fg-color': m('--neutral-v600'),
  '--icon-button-bg-color': m('--neutral-v0'),
  '--icon-button-border-color': m('--neutral-v600'),
  '--icon-button-hover-bg-color': m('--neutral-v0'),
  '--icon-button-hover-border-color': m('--primary-v150'),
  '--icon-button-disabled-bg-color': m('--neutral-v600'),
  '--icon-button-disabled-fg-color': m('--neutral-v700'),
  '--icon-button-disabled-border-color': m('--neutral-v650'),
};

export const contrastWhiteIconButtonVars: MotifComponentProps<MotifComponent.ICON_BUTTON> = {
  ...commonVars,
  '--icon-button-fg-color': m('--neutral-v0'),
  '--icon-button-bg-color': m('--neutral-v650'),
  '--icon-button-border-color': m('--neutral-v0'),
  '--icon-button-hover-bg-color': m('--neutral-v650'),
  '--icon-button-hover-border-color': m('--primary-v150'),
  '--icon-button-disabled-bg-color': m('--neutral-v150'),
  '--icon-button-disabled-fg-color': m('--neutral-v400'),
  '--icon-button-disabled-border-color': m('--neutral-v300'),
};
