import { Site } from './sites';

export const stagingDomains: Record<Site, string> = {
  [Site.A523]: 'https://snap-523-staging-dot-entapps-web-dev.gae.sc-corp.net',
  [Site.ARCADIA]: 'https://arcadia-staging-dot-entapps-web-dev.gae.sc-corp.net',
  [Site.AR]: 'https://snap-ar-staging-dot-entapps-web-dev.gae.sc-corp.net',
  [Site.CAREERS]: 'https://careers-staging-dot-entapps-web-dev.gae.sc-corp.net',
  [Site.CHEERIOS]: 'https://cheerios-staging-dot-entapps-web-dev.gae.sc-corp.net',
  [Site.CITIZEN]: 'https://citizen-staging-dot-entapps-web-dev.gae.sc-corp.net',
  [Site.CREATORS]: 'https://creators-staging-dot-entapps-web-dev.gae.sc-corp.net',
  [Site.DIVERSITY]: 'https://diversity-staging-dot-entapps-web-dev.gae.sc-corp.net',
  [Site.ENG_BLOG]: 'https://eng-blog-staging-dot-entapps-web-dev.gae.sc-corp.net',
  [Site.EXPERIENCE]: 'https://experience-staging-dot-entapps-web-dev.gae.sc-corp.net',
  [Site.FOR_BUSINESS]: 'https://for-business-staging-dot-entapps-web-dev.gae.sc-corp.net',
  [Site.NEWSROOM]: 'https://newsroom-staging-dot-entapps-web-dev.gae.sc-corp.net',
  [Site.PARENTS]: 'https://parents-staging-dot-entapps-web-dev.gae.sc-corp.net',
  [Site.SANDBOX]: 'https://sandbox-staging-dot-entapps-web-dev.gae.sc-corp.net',
  [Site.TRUST]: 'https://trust-staging-dot-entapps-web-dev.gae.sc-corp.net',
  [Site.AVALON]: 'https://avalon-staging-dot-entapps-web-dev.gae.sc-corp.net',
  [Site.EDUCATORS]: 'https://educators-staging-dot-entapps-web-dev.gae.sc-corp.net',
  [Site.SNAP]: 'https://snap-staging-dot-entapps-web-dev.gae.sc-corp.net',
};
