import { m } from '../../motif/m';

export const paginationDarkBgVars = {
  '--pagination-text-active-color': m('--neutral-v0'),
  '--pagination-text-color': m('--neutral-v300'),
  '--pagination-text-hover-color': m('--neutral-v200'),
};

export const paginationLightBgVars = {
  '--pagination-text-active-color': m('--neutral-v700'),
  '--pagination-text-color': m('--neutral-v600'),
  '--pagination-text-hover-color': m('--neutral-v300'),
};
