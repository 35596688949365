import type { MotifComponent, MotifComponentProps } from '../../motif';
import { m } from '../../motif/m';

export const modalLightBgVars: MotifComponentProps<MotifComponent.MODAL> = {
  '--modal-bg-color': 'rgba(255, 255, 255, .7)',
  '--modal-close-bg-color': m('--neutral-v700'),
  '--modal-close-fg-color': m('--neutral-v0'),
} as const;

export const modalDarkBgVars: MotifComponentProps<MotifComponent.MODAL> = {
  '--modal-bg-color': 'rgba(0, 0, 0, .7)',
  '--modal-close-bg-color': m('--neutral-v0'),
  '--modal-close-fg-color': m('--neutral-v700'),
} as const;
