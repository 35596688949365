import type { MotifComponent, MotifComponentProps } from '../../motif';
import { m } from '../../motif/m';

const dropdownDefaultVars = {
  '--dropdown-menu-padding': m('--spacing-m'),
  '--dropdown-item-fg-color': m('--action-default-color'),
  '--dropdown-item-fg-hover-color': m('--action-hover-color'),
  '--dropdown-item-fg-active-color': m('--action-active-color'),
  '--dropdown-button-border-radius': m('--border-radius-m'),
  '--dropdown-button-border-width': '0',
  '--dropdown-button-border-color': 'transparent',
  '--dropdown-button-hover-border-color': 'transparent',
};

// Should eventually conver hover to underline
export const dropdownMenuLightVars: MotifComponentProps<MotifComponent.DROPDOWN_MENU> = {
  '--dropdown-menu-bg-color': m('--neutral-v0'),
  '--dropdown-item-bg-hover-color': m('--neutral-v150'),
  '--dropdown-item-bg-active-color': m('--neutral-v100'),
  '--dropdown-menu-border-color': m('--neutral-v200'),

  '--dropdown-skeleton-animation-color': m('--neutral-v200'),
  '--dropdown-skeleton-animation-default-color': m('--neutral-v0'),
  ...dropdownDefaultVars,
};

export const dropdownMenuDarkVars: MotifComponentProps<MotifComponent.DROPDOWN_MENU> = {
  '--dropdown-menu-bg-color': m('--neutral-v650'),
  '--dropdown-item-bg-hover-color': m('--neutral-v500'),
  '--dropdown-item-bg-active-color': m('--neutral-v600'),
  '--dropdown-menu-border-color': m('--neutral-v0'),

  '--dropdown-skeleton-animation-color': m('--neutral-v500'),
  '--dropdown-skeleton-animation-default-color': m('--neutral-v400'),
  ...dropdownDefaultVars,
};
