import type {
  BaseMarketingWebEvent,
  MarketingWebClientIdEvent,
  MarketingWebSnapPixelEvent,
} from '@snapchat/blizzard-schema';
import { type LoggedEvent, LoggingEventType } from '@snapchat/logging';
import type { BlizzardBaseEvent } from '@snapchat/logging-browser';
import kebabCase from 'lodash-es/kebabCase';

import { SubscribedEventType } from '../../eventListenerTypes';
import type { LoggingContext, LoggingCustomEvents } from '../../loggingTypes';

/**
 * Event Formatting function for primary MarketingWeb Blizzard Table
 *
 * Table: `sc-analytics.prod_analytics_marketing_web`
 *
 * This is the primary MarketingWeb Blizzard table. Events logged here may trigger events in
 * secondary tables - refer to `formatSnapPixelEvent()` and `formatWebClientIdEvent()` functions for
 * details.
 */
const formatMarketingWebEvent = (
  event: LoggedEvent<LoggingCustomEvents>,
  context: Partial<LoggingContext>
): BlizzardBaseEvent | undefined => {
  const url = context.url ?? new URL(window.location.href);
  const data: BaseMarketingWebEvent = {
    host: url.host,
    path: url.pathname,
    query: JSON.stringify(Object.fromEntries(url.searchParams.entries())),
    page_locale: context.locale,
  };

  // if experiment data exists, we add all three fields
  if (context.variantId) {
    data.experiment_category = 'Page'; // Always page.
    data.experiment_group = context.variantId;
    data.experiment_id = context.experimentId ?? 'None';
  }

  switch (event.type) {
    case LoggingEventType.CUSTOM: {
      switch (event.subscribedEventType) {
        case SubscribedEventType.PAGE_LOAD:

        // falls through
        case SubscribedEventType.FIRST_PAGE_LOAD: {
          return {
            event_name: 'MARKETING_WEB_PAGE_VIEW',
            ...data,
            is_landing_page: event.subscribedEventType === SubscribedEventType.FIRST_PAGE_LOAD,
          };
        }

        case SubscribedEventType.EXPERIMENT_IMPRESSION: {
          // We add this case so typescript is happy, but theoretically this should never fire.
          // If it DOES somehow fire, it will fire the exact same log as SubscribedEventType.INTERNAL and
          // how the fields are set up in useExperiment
          return {
            event_name: 'MARKETING_WEB_INTERNAL_EVENT',
            ...data,
            type: 'useExperiment',
            category: 'Experiment',
            label: `${event.experimentId?.replace(/[ _]/, '-')}:${kebabCase(event.variantId)}`,
          };
        }
      }
      break;
    }

    case LoggingEventType.USER_ACTION: {
      return {
        event_name: 'MARKETING_WEB_USER_INTERACTION',
        ...data,
        type: event.action,
        category: event.component,
        label: event.label,
      };
    }

    case LoggingEventType.INFO: {
      return {
        event_name: 'MARKETING_WEB_INTERNAL_EVENT',
        ...data,
        type: event.action,
        category: event.component,
        label: event.label,
      };
    }

    case LoggingEventType.VALUE: {
      return {
        event_name: 'MARKETING_WEB_VALUE_EVENT',
        ...data,
        metric: event.value,
        type: event.variable,
        category: event.component,
        label: event.label,
      };
    }

    case LoggingEventType.TIMING: {
      return {
        event_name: 'MARKETING_WEB_TIMING_EVENT',
        ...data,
        duration: event.valueMs,
        type: event.variable,
        category: event.component,
        label: event.label,
      };
    }
  }

  return undefined;
};

/**
 * Formatting for Snap Pixel Blizzard events (table:
 * `sc-analytics.prod_analytics_marketing_web_pixel_id`)
 *
 * Returns undefined if it is not relevant for the triggering event. Table is used to correlate
 * entries in the `sc-analytics.prod_analytics_marketing_web` to Snap pixel identifiers. This is
 * separated to its own table as snap pixel data requires a shorter data retention policy than the
 * primary table.
 *
 * @param triggeringEvent Event generated by `formatMarketingWebEvent` function
 * @param context Logging context, used to access pixelId value
 */
const formatSnapPixelEvent = (
  triggeringEvent: BlizzardBaseEvent,
  context: Partial<LoggingContext>
): MarketingWebSnapPixelEvent | undefined => {
  // Do not create event if pixelId is not defined
  if (!context.pixelId) return;

  return {
    event_name: 'MARKETING_WEB_SNAP_PIXEL_EVENT',
    pixel_id: context.pixelId,
    triggering_event_name: triggeringEvent.event_name,
  };
};

/**
 * Formatting for sc-wcid Blizzard events (table: `sc-analytics:prod_analytics_marketing_web_wcid`)
 *
 * Returns undefined if it is not relevant for the triggering event. Table is used to correlate
 * entries in the `sc-analytics.prod_analytics_marketing_web` to sc-wcid identifiers. This is
 * separated to its own table as the `sc-wcid` cookie values require a shorter data retention policy
 * than the primary table.
 *
 * @param triggeringEvent Event generated by `formatMarketingWebEvent` function
 * @param context Logging context, used to access webClientId value
 */
const formatWebClientIdEvent = (
  triggeringEvent: BlizzardBaseEvent,
  context: Partial<LoggingContext>
): MarketingWebClientIdEvent | undefined => {
  // Do not create event if webClientId is not defined
  if (!context.webClientId) return;

  return {
    event_name: 'MARKETING_WEB_CLIENT_ID_EVENT',
    web_client_id: context.webClientId,
    triggering_event_name: triggeringEvent.event_name,
  };
};

/**
 * Cache of events that have already been recorded.
 *
 * This is used for deduplicating webClientId and pixelId events.
 */
const eventLog = new Set<string>();

export function clearEventLogForTest(): void {
  eventLog.clear();
}

/**
 * Wrapper function around individual MarketingWebEvent formatting functions.
 *
 * Returns an array containing formatted events.
 */
export const formatMarketingWebEvents = (
  event: LoggedEvent<LoggingCustomEvents>,
  context: Partial<LoggingContext>
): BlizzardBaseEvent[] => {
  const output: BlizzardBaseEvent[] = [];

  const primaryEvent = formatMarketingWebEvent(event, context);

  // If not a valid input event for logging to MWP tables, return empty array
  if (!primaryEvent) return output;

  output.push(primaryEvent);

  // Generate related event if appropriate and add to output array
  const pixelEvent = formatSnapPixelEvent(primaryEvent, context);
  if (pixelEvent) {
    // NOTE: The `triggering_event_name` field isn't used by the DS team, so we do
    // not use it as a cache key. Doug pointed us this search:
    // https://livegrep.sc-corp.net/search/?q=triggering_event_name%20max_matches%3A999&fold_case=auto&regex=false&context=true&repo%5B%5D=Snapchat%2Fflowrida
    const eventKey = [pixelEvent.event_name, pixelEvent.pixel_id].join(':');

    if (!eventLog.has(eventKey)) {
      output.push(pixelEvent);
      eventLog.add(eventKey);
    }
  }

  // Generate related event if appropriate and add to output array
  const webClientIdEvent = formatWebClientIdEvent(primaryEvent, context);
  if (webClientIdEvent) {
    // NOTE: triggering_event_name isn't part of the key for the same reason as above.
    const eventKey = [webClientIdEvent.event_name, webClientIdEvent.web_client_id].join(':');

    if (!eventLog.has(eventKey)) {
      output.push(webClientIdEvent);
      eventLog.add(eventKey);
    }
  }

  return output;
};
