import type { MotifComponent, MotifComponentProps } from '../../motif';
import { m } from '../../motif/m';

export const toggleButtonBlackVars: MotifComponentProps<MotifComponent.TOGGLE_BUTTON> = {
  '--toggle-button-active-color': m('--neutral-v600'),
  '--toggle-button-color': m('--neutral-v300'),
  '--toggle-button-hover-color': m('--neutral-v200'),
};

export const toggleButtonYellowVars: MotifComponentProps<MotifComponent.TOGGLE_BUTTON> = {
  '--toggle-button-active-color': m('--neutral-v600'),
  '--toggle-button-color': m('--neutral-v200'),
  '--toggle-button-hover-color': m('--neutral-v300'),
};

export const toggleButtonGrayVars: MotifComponentProps<MotifComponent.TOGGLE_BUTTON> = {
  '--toggle-button-active-color': m('--neutral-v600'),
  '--toggle-button-color': m('--neutral-v0'),
  '--toggle-button-hover-color': m('--neutral-v300'),
};
