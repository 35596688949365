import type { MotifComponent, MotifComponentProps } from '../../motif';
import { m } from '../../motif/m';

const commonVars = {
  '--hero-subtitle-color': m('--fg-color'),
  // To avoid loss of contrast, the text color should be white when the curtain is active.
  '--hero-text-color-curtain-active': m('--neutral-v0'),
  '--hero-title-color': m('--fg-color'),
  '--hero-scroll-button-curtain-active-bg-color': m('--neutral-v0'),
  '--hero-scroll-button-curtain-active-hover-bg-color': m('--neutral-v250'),
  '--hero-scroll-button-curtain-active-fg-color': m('--neutral-v700'),
  '--hero-scroll-button-curtain-active-hover-fg-color': m('--neutral-v700'),
};

export const heroLightBgVars: MotifComponentProps<MotifComponent.HERO> = {
  ...commonVars,
  '--hero-scroll-button-bg-color': m('--neutral-v700'),
  '--hero-scroll-button-hover-bg-color': m('--neutral-v600'),
  '--hero-scroll-button-fg-color': m('--neutral-v0'),
  '--hero-scroll-button-hover-fg-color': m('--neutral-v0'),
};

export const heroDarkBgVars: MotifComponentProps<MotifComponent.HERO> = {
  ...commonVars,
  '--hero-scroll-button-bg-color': m('--neutral-v0'),
  '--hero-scroll-button-hover-bg-color': m('--neutral-v250'),
  '--hero-scroll-button-fg-color': m('--neutral-v700'),
  '--hero-scroll-button-hover-fg-color': m('--neutral-v700'),
};
