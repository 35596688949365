import { css } from '@emotion/css';

import {
  mobileMediaQuery,
  noHorizontalScrollbarsCss,
  nonMobileMediaQuery,
  ZIndex,
} from '../../constants';
import { m, MotifComponent } from '../../motif';

export const tabsPanelsCss = css`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;

  & > div {
    margin: auto;
  }

  ${mobileMediaQuery} {
    margin-top: ${m('--spacing-m')};
  }
`;

export const tabsButtonsCss = css`
  display: flex;
  justify-content: center;
  padding-top: ${m('--spacing-xxxs')};
  padding-bottom: ${m('--spacing-xxxl')};
  flex-wrap: wrap;
  & > .${MotifComponent.BUTTON} {
    margin-bottom: ${m('--spacing-m')};
  }
  & > .${MotifComponent.BUTTON}:not(:last-child) {
    margin-right: ${m('--spacing-m')};
  }
  ${mobileMediaQuery} {
    justify-content: start;
    margin-right: -${m('--spacing-xl')};
    margin-left: -${m('--spacing-xl')};
    overflow-x: auto;
    flex-wrap: nowrap;
    ${noHorizontalScrollbarsCss}
  }
`;

export const arrowButtonContainerCss = css`
  display: flex;
  z-index: ${ZIndex.HERO_CURTAIN};
  background-color: ${m('--bg-color')};
  min-width: 80px;
  justify-content: center;
  align-items: center;
  padding: 0 0 0 0;
  margin: 0 0 0 0;
  flex-direction: row;
  [dir='rtl'] & {
    flex-direction: row-reverse;
  }
  ${mobileMediaQuery} {
    display: none;
  }
`;

export const arrowCss = css`
  cursor: pointer;
  z-index: ${ZIndex.CAROUSEL_ARROW};
  background-color: ${m('--bg-color')};
  border: none;

  :disabled {
    opacity: 50%;
  }
  ${mobileMediaQuery} {
    display: none;
  }
`;

export const leftGradientCss = css`
  background: linear-gradient(to left, transparent 10%, ${m('--bg-color')} 100%);
  content: '';
  display: block;
  height: calc(${m('--spacing-xxxxl')} + 1px);
  left: 0;
  pointer-events: none;
  position: absolute;
  width: 80px;
  z-index: ${ZIndex.CAROUSEL_SIDE_GRADIENT};

  [dir='rtl'] & {
    /*
    The height is 1px less on the left-side (RTL) on desktop so that this gradient does not cover the bottom border line.
    This is because there are arrow buttons on desktop so the gradient is not the farthest element to the right.
    */
    height: ${m('--spacing-xxxxl')};
    left: ${m('--spacing-xxxxl')};
  }

  ${mobileMediaQuery} {
    height: calc(${m('--spacing-xxxxl')} + 1px);
    left: 0;

    [dir='rtl'] & {
      height: calc(${m('--spacing-xxxxl')} + 1px);
      left: 0;
    }
  }
`;

export const rightGradientCss = css`
  background: linear-gradient(to right, transparent 10%, ${m('--bg-color')} 100%);
  content: '';
  display: block;
  /*
  The height is 1px less on the right-side on desktop so that this gradient does not cover the bottom border line.
  This is because there are arrow buttons on desktop so the gradient is not the farthest element to the right.
  */
  height: ${m('--spacing-xxxxl')};
  pointer-events: none;
  position: absolute;
  right: ${m('--spacing-xxxxl')};
  width: 80px;
  z-index: ${ZIndex.CAROUSEL_SIDE_GRADIENT};

  [dir='rtl'] & {
    height: calc(${m('--spacing-xxxxl')} + 1px);
    right: 0;
  }

  ${mobileMediaQuery} {
    height: calc(${m('--spacing-xxxxl')} + 1px);
    right: 0;

    [dir='rtl'] & {
      height: calc(${m('--spacing-xxxxl')} + 1px);
      right: 0;
    }
  }
`;

export const tabsContainerCss = css`
  border-bottom: 1px ${m('--tabs-underline-color')} solid;
  display: flex;
  margin: 0 ${m('--spacing-xxxl')} 0 ${m('--spacing-xxxl')};
  max-width: 100%;
  padding-top: ${m('--spacing-xl')};
  position: relative;

  ${mobileMediaQuery} {
    /*
    The border is applied to a different element on mobile due to different visual requirements:
    - Desktop: continue border under arrow buttons
    - Mobile: border stops at end of last item
    */
    border-bottom: none;
    margin: 0 ${m('--spacing-xs')} 0 ${m('--spacing-xs')};
    padding: 0;
  }
`;

/**
 * Stretch the tabs container to the screen edge for mobile screens when the number of items
 * overflows the container
 */
export const tabsContainerOverflowMobileCss = css`
  ${mobileMediaQuery} {
    margin-inline-end: calc(50% - 50vw);
    position: relative;
    max-width: unset;
  }
`;

export const tabItemContainerCss = css`
  display: flex;
  overflow: hidden;
  position: relative;
  scroll-behavior: smooth;
  width: 100%;
  z-index: ${ZIndex.BUTTON};

  ${mobileMediaQuery} {
    overflow-x: auto;
    padding-inline-start: 0;

    &::-webkit-scrollbar {
      display: none;
    }
  }
`;

export const tabItemContainterOverflowMobileCss = css`
  padding-inline-end: ${m('--spacing-xl')};
`;

export const tabItemListCss = css`
  align-items: center;
  display: flex;
  flex: 1;
  gap: ${m('--spacing-xl')};
  list-style: none;
  margin: 0;
  padding: 0;

  ${mobileMediaQuery} {
    border-bottom: 1px ${m('--tabs-underline-color')} solid;
    justify-content: start;
  }
`;

export const tabItemListCenterCss = css`
  justify-content: center;
`;

export const tabItemListStartCss = css`
  justify-content: flex-start;
`;

const itemLinkUnderlineCss = css`
  &::after {
    bottom: 0px;
    content: '';
    position: absolute;
    transition: all 0.15s ease;
    visibility: hidden;
    width: 100%;
    text-align: left;
    margin-left: 0;
    height: 0px;
    border-top: 5px solid;
    border-radius: ${m('--border-radius-s')};
    margin-bottom: -5px;
    color: ${m('--tabs-item-color')};
  }

  &.active::after {
    visibility: visible;
    color: ${m('--tabs-item-active-color')};
  }

  &:hover::after {
    transform: translateY(-4px);
    visibility: visible;
    color: ${m('--tabs-item-hover-color')};
  }
`;

const itemLinkUnderlineActiveCss = css`
  &::after {
    transform: translateY(-4px);
    visibility: visible;
    color: ${m('--tabs-item-active-color')};
  }
  &:hover::after {
    color: ${m('--tabs-item-hover-color')};
  }
`;

export const itemLinkCss = css`
  align-items: center;
  cursor: pointer;
  color: ${m('--tabs-item-color')};
  display: flex;

  :hover {
    color: ${m('--tabs-item-hover-color')};
  }

  position: relative;
  text-decoration: none;
  justify-content: center;
  white-space: nowrap;
  user-select: none;

  ${mobileMediaQuery} {
    font-size: ${m('--action-mobile-font-size')};
    font-weight: ${m('--action-mobile-font-weight')};
  }

  ${nonMobileMediaQuery} {
    font-size: ${m('--action-desktop-font-size')};
    font-weight: ${m('--action-desktop-font-weight')};
  }

  line-height: ${m('--spacing-xxxxl')};

  ${itemLinkUnderlineCss}
`;

export const itemLinkActiveCss = css`
  color: ${m('--tabs-item-active-color')};
  ${itemLinkUnderlineActiveCss}
`;

export const tabBreakCss = css`
  z-index: ${ZIndex.NAVIGATOR_BUTTON};
  width: 100%;
  ${mobileMediaQuery} {
    width: calc(100% - ${m('--spacing-xl')}px);
  }
  height: 16px;
  position: relative;
  margin-top: -${m('--spacing-xs')};
  background-color: ${m('--bg-color')};
`;
