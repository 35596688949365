import { css } from '@emotion/css';

import {
  largeDesktopMediaQuery,
  largeDesktopPageContainerQuery,
  mobilePageContainerQuery,
  nonLargeDesktopPageContainerQuery,
  nonMobileMediaQuery,
  nonMobilePageContainerQuery,
} from '../../constants';
import { m } from '../../motif';

export const galleryV2Css = css`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: ${m('--spacing-m')};
  margin-bottom: ${m('--spacing-m')};
  margin-inline: auto;

  /*
  We are keeping the media queries in addition to the container queries in case:
  - this component is ever rendered without a wrapping SDS-M Page component
  - the browser does not support container queries (smart TVs, etc.)
  */
  ${largeDesktopMediaQuery} {
    gap: ${m('--spacing-l')};
    margin-bottom: ${m('--spacing-l')};
  }

  ${largeDesktopPageContainerQuery} {
    gap: ${m('--spacing-m')};
  }

  ${nonLargeDesktopPageContainerQuery} {
    gap: ${m('--spacing-l')};
    margin-bottom: ${m('--spacing-l')};
  }

  > * {
    width: 100%;

    /*
    We are keeping the media queries in addition to the container queries in case:
    - this component is ever rendered without a wrapping SDS-M Page component
    - the browser does not support container queries (smart TVs, etc.)
    */
    ${nonMobileMediaQuery} {
      /* 
      Width calculation assumes 2 cards per line
      {50% of parent width} - { gap size / 2 }
      */
      width: calc(50% - ${m('--spacing-m')} / 2);
    }

    ${largeDesktopMediaQuery} {
      /* 
      Width calculation assumes 2 cards per line
      {50% of parent width} - { gap size / 2 }
      */
      width: calc(50% - ${m('--spacing-l')} / 2);
    }

    ${mobilePageContainerQuery} {
      width: 100%;
    }

    ${nonMobilePageContainerQuery} {
      /* 
      Width calculation assumes 2 cards per line
      {50% of parent width} - { gap size / 2 }
      */
      width: calc(50% - ${m('--spacing-m')} / 2);
    }

    ${nonLargeDesktopPageContainerQuery} {
      /* 
      Width calculation assumes 2 cards per line
      {50% of parent width} - { gap size / 2 }
      */
      width: calc(50% - ${m('--spacing-l')} / 2);
    }
  }
`;
