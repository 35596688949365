import { gql } from '@apollo/client';
import type {
  Alignment,
  AnimatedAccordionMediaDirection,
} from '@snapchat/snap-design-system-marketing';

import type { ContentfulTypedSysProps } from '../../types/contentful';
import type { RichText } from '../../types/RichText';
import { contentfulSysIdFragment } from '../../utils/contentful/contentfulQuery';
import { type ImageDataProps, fragments as imageFragments } from '../Image';

export const fragments = {
  all: gql`
    fragment AnimatedAccordionAll on AnimatedAccordion {
      ...ContentfulSysId
      accordionContentsCollection {
        items {
          ...ContentfulSysId
          image {
            ...ImageAll
          }
          title
          body {
            json
          }
        }
      }
      mediaDirection
      autoPlaySpeed
      textAlignmentMobile
    }
    ${imageFragments.all}
    ${contentfulSysIdFragment}
  `,
};

export const query = gql`
  query AnimatedAccordionQuery($preview: Boolean!, $locale: String!, $id: String!) {
    animatedAccordion(preview: $preview, locale: $locale, id: $id) {
      ...AnimatedAccordionAll
    }
  }
  ${fragments.all}
`;

export interface AnimatedAccordionContentData {
  image?: ImageDataProps;
  title?: string;
  body?: RichText;
}
export interface AnimatedAccordionData extends ContentfulTypedSysProps<'AnimatedAccordion'> {
  animatedAccordion?: {
    accordionContentsCollection?: {
      items?: AnimatedAccordionContentData[];
    };
    mediaDirection?: AnimatedAccordionMediaDirection;
    textAlignmentMobile?: Alignment;
    autoPlaySpeed?: number;
  };
}
