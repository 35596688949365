import { gql } from '@apollo/client';
import type { Banner } from '@snapchat/mw-contentful-schema';
import type { BackgroundColor } from '@snapchat/snap-design-system-marketing';

import type { FooterView, HeaderView } from '../../context/PageLayoutContext';
import type { Category } from '../../types/Category';
import type { ContentfulTypedSysProps } from '../../types/contentful';
import type { Items } from '../../types/Items';
import { contentfulSysIdFragment } from '../../utils/contentful/contentfulQuery';
import type { BlockDataProps, BlockShallowDataProps } from '../Block';
import { blockAllFragment, blockFragmentShallow, blockQueryLimit } from '../Block';
import { blockTabsFragment } from '../BlockTabs';
import type { BlockTabsDataProps } from '../BlockTabs/types';
import type { BreakDataProps } from '../Break';
import { breakFragment } from '../Break';
import { ctaFragment } from '../CallToAction';
import type { FootnoteDataProps } from '../Footnote';
import { fragments as footnoteFragments } from '../Footnote';
import type { HeroDataProps } from '../Hero';
import { fragments as heroFragments } from '../Hero';
import type { ImageDataProps } from '../Image/types';
import type { LatestPostBlockDataProps } from '../LatestPostBlock';
import { fragments as assetsFragments } from '../Media/query';
import type { MediaDataProps } from '../Media/types';
import type { MetasDataProps } from '../Metas';
import { fragments as metaFragments } from '../Metas';
import { multiValuePropBlockFragment } from '../MultiValuePropBlock/query';
import type { MultiValuePropBlockProps } from '../MultiValuePropBlock/types';
import type { MultiVideoDataProps } from '../MultiVideoBlock';
import type { ScrollAnimatedSectionDataProps } from '../ScrollAnimatedSection/query';
import { scrollAnimatedSectionFragment } from '../ScrollAnimatedSection/query';
import type { SideNavigationDataProps } from '../SideNavigation';
import { type SplitBlockDataProps, splitBlockFragment } from '../SplitBlock/SplitBlock.query';
import type { SubNavigationDataProps } from '../SubNavigation';
import { subNavigationFragment } from '../SubNavigation';

// =================================================================================================
// Page Shallow Fragment
// =================================================================================================

/**
 * This shallow fragment does load deep ids for the blocks and content so that they can be loaded in
 * the next load cycle. This allows for executing all of the children queries in parallel.
 */
export const pageFragmentShallow = gql`
  fragment PageShallow on Page {
    ...ContentfulSysId
    blocksCollection(limit: ${blockQueryLimit}) {
      items {
        # For non-standard blocks, just preload their ids.
        ...ContentfulSysId
        # For blocks, alse preload the content ids.
        ... on Block {
          ...BlockShallow
        }
      }
    }
  }
  ${blockFragmentShallow}
  ${contentfulSysIdFragment}
`;

export interface PageShallowDataProps extends ContentfulTypedSysProps<'Page'> {
  blocksCollection: Items<BlockShallowDataProps>;
}

// =================================================================================================
// Banner Fragment
// =================================================================================================
export const bannerFragment = gql`
  fragment BannerAll on Banner {
    ...ContentfulSysId
    content {
      json
      links {
        entries {
          inline {
            ... on CallToAction {
              ...CallToActionAll
            }
          }
        }
      }
    }
    backgroundColor
  }
  ${contentfulSysIdFragment}
  ${ctaFragment}
`;

// =================================================================================================
// Page All Fragment
// =================================================================================================

export const fragments = {
  all: gql`
    fragment PageAll on Page {
      ...ContentfulSysId
      title
      metas {
        ...MetaAll
      }
      banner {
        ...BannerAll
        # In case there's a MobileCta inside banner, we want to retrieve its ID.
        ...ContentfulSysId
      }
      backgroundColor
      backgroundMediaV2 {
        __typename
        ... on Video {
          ...VideoAll
        }
        ... on Image {
          ...ImageAll
        }
      }
      backgroundMediaStyle
      blocksCollection(limit: ${blockQueryLimit}) {
        items {
          ...ContentfulSysId
          ... on Block {
            ...BlockAll
          }
          ... on BlockHero {
            ...BlockHeroAll
          }
          ... on LatestPosts {
            backgroundColor
          }
          ... on Break {
            ...BreakAll
          }
          ... on SubNavigation {
            ...SubNavigationAll
          }
          ... on ScrollAnimatedSection {
            ...ScrollAnimatedSectionAll
          }
          ... on SplitBlock {
            ...SplitBlockAll
          }
          ... on MultiValuePropBlock {
            ...MultiValuePropBlockAll
          }
          ... on BlockTabs {
            ...BlockTabsAll
          }
        }
      }
      mobileCta {
        ...CallToActionAll
      }
      footnotesCollection(limit: 50) {
        items {
          ... on FootNotes {
            ...FootNotesAll
          }
        }
      }
      collapseFootnotes
      footerView
      headerView
      scrollSnap
      localizedIn
    }
    ${bannerFragment}
    ${blockAllFragment}
    ${scrollAnimatedSectionFragment}
    ${assetsFragments.all}
    ${ctaFragment}
    ${heroFragments.all}
    ${footnoteFragments.all}
    ${metaFragments.all}
    ${contentfulSysIdFragment}
    ${subNavigationFragment}
    ${breakFragment}
    ${splitBlockFragment}
    ${multiValuePropBlockFragment}
    ${blockTabsFragment}
  `,
};

export type PageDataBlock =
  | BlockDataProps
  | HeroDataProps
  | LatestPostBlockDataProps
  | MultiVideoDataProps
  | SubNavigationDataProps
  | BreakDataProps
  | ScrollAnimatedSectionDataProps
  | SplitBlockDataProps
  | MultiValuePropBlockProps
  | BlockTabsDataProps
  | ContentfulTypedSysProps<'Unknown'>; // Placeholder __typename as custom components could be any string

export type PageDataBlockWithBackgroundColor = Extract<
  PageDataBlock,
  | BlockDataProps
  | HeroDataProps
  | LatestPostBlockDataProps
  | SubNavigationDataProps
  | SplitBlockDataProps
  | MultiValuePropBlockProps
  | BlockTabsDataProps
>;

export interface PageDataProps extends ContentfulTypedSysProps<'Page'> {
  title: string;
  metas?: MetasDataProps;
  banner?: Banner;
  blocksCollection?: Items<PageDataBlock>;
  footnotesCollection?: Items<FootnoteDataProps>;
  collapseFootnotes?: boolean;
  sideNavigation?: SideNavigationDataProps;
  tileImage?: ImageDataProps;
  backgroundColor?: BackgroundColor;
  category?: Category;
  backgroundMediaV2?: MediaDataProps;
  footerView?: FooterView;
  headerView?: HeaderView;
  localizedIn?: string[];
}

// =================================================================================================
// Page Navigation Fragment
// =================================================================================================

export const pageNavigationFragment = gql`
  fragment PageNavigationShallow on Page {
    ...ContentfulSysId
    sideNavigation {
      ...ContentfulSysId
    }
  }
  ${contentfulSysIdFragment}
`;

// =================================================================================================
// Page Query
// =================================================================================================

export const pageAllQuery = gql`
  query PageQuery($preview: Boolean!, $locale: String!, $id: String!) {
    page(preview: $preview, locale: $locale, id: $id) {
      ...PageAll
    }
  }
  ${fragments.all}
`;

export interface PageAllDataProps {
  page: PageDataProps;
}

// =================================================================================================
// Page SiteMap Fragment
// =================================================================================================
/** Page Query optimized for generating site map data. */
export const pageSitemapFragment = gql`
  fragment SiteMapPage on Page {
    sys {
      id
    }
    metas {
      noIndex
    }
    localizedIn
  }
`;

/** Page content model optimized for generating site map data. */
export interface PageSitemapData {
  __typename: 'Page';
  sys: {
    id: string;
  };
  localizedIn?: string[];
  metas?: {
    noIndex?: boolean;
  };
}

/** Allows querying multiple entries by sys id */
export const pageBlocksCollectionQuery = gql`
  query PageBlocksCollectionQuery(
    $preview: Boolean!
    $locale: String!
    $ids: [String]!
    $limit: Int = 10
  ) {
    entryCollection(
      preview: $preview
      locale: $locale
      where: { sys: { id_in: $ids } }
      limit: $limit
    ) {
      items {
        ...ContentfulSysId
        ... on Block {
          ...BlockAll
        }
        ... on BlockHero {
          ...BlockHeroAll
        }
      }
    }
  }
  ${contentfulSysIdFragment}
  ${blockAllFragment}
  ${heroFragments.all}
`;
