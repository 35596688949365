import { m } from '../../motif/m';
import type { MotifComponent } from '../../motif/motifConstants';
import type { MotifComponentProps } from '../../motif/motifTypes';

export const spinnerDarkBgVars: MotifComponentProps<MotifComponent.SPINNER> = {
  '--spinner-fg-color': m('--neutral-v0'),
};

export const spinnerLightBgVars: MotifComponentProps<MotifComponent.SPINNER> = {
  '--spinner-fg-color': m('--neutral-v700'),
};
