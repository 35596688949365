import type { MotifComponent, MotifComponentProps } from '../../motif';
import { m } from '../../motif/m';

export const defaultAutoCompleteVars: MotifComponentProps<MotifComponent.AUTOCOMPLETE> = {
  '--autocomplete-active-border-color': m('--semantic-focus-color'),
  '--autocomplete-active-box-shadow': m('--box-shadow-s'),
  '--autocomplete-button-fg-color': m('--semantic-focus-color'),
  '--autocomplete-button-fg-hover-color': m('--neutral-v650'),
  '--autocomplete-bg-color': m('--neutral-v0'),
  '--autocomplete-border-color': m('--neutral-v250'),
  '--autocomplete-border-width': '2px',
  '--autocomplete-desktop-font-size': m('--text-desktop-font-size'),
  '--autocomplete-desktop-font-weight': m('--text-desktop-font-weight'),
  '--autocomplete-fg-color': m('--neutral-v700'),
  '--autocomplete-hover-border-color': m('--neutral-v400'),
  '--autocomplete-hover-box-shadow': m('--box-shadow-xs'),
  '--autocomplete-mobile-font-size': m('--text-mobile-font-size'),
  '--autocomplete-mobile-font-weight': m('--text-mobile-font-weight'),
  '--autocomplete-placeholder-color': m('--neutral-v400'),
} as const;

export const contrastBlackAutoCompleteVars: MotifComponentProps<MotifComponent.AUTOCOMPLETE> = {
  ...defaultAutoCompleteVars,
  '--autocomplete-button-fg-color': m('--neutral-v0'),
  '--autocomplete-button-fg-hover-color': m('--neutral-v0'),
  '--autocomplete-bg-color': m('--neutral-v625'),
  '--autocomplete-border-color': m('--neutral-v500'),
  '--autocomplete-fg-color': m('--neutral-v0'),
  '--autocomplete-placeholder-color': m('--neutral-v300'),
} as const;

export const constrastGrayAutoCompleteVars: MotifComponentProps<MotifComponent.AUTOCOMPLETE> = {
  ...defaultAutoCompleteVars,
  '--autocomplete-border-color': m('--neutral-v300'),
};
